.hover-action {
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  // display: block;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    margin: auto;
    transition: 0.2s ease-in-out;
    opacity: 0;
    z-index: 1;
  }

  &::before {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }
  &::after {
    color: #fff;
    content: "Read More";
    font-size: 22px;
    font-weight: bold;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
  }

  &:hover {
    transform: scale(1.03);
    opacity: 1;
    &::before,
    &::after {
      opacity: 1;
    }
  }
}

.hover-scale {
  &:hover {
    transform: scale(1.03);
    transition-duration: 0.2s;
  }
}
