#newsDetailPage main {
  background-color: $c_palegray;
  padding-top: 10.7rem;

  .c-breadcrumb {
    margin-bottom: 0;
  }
}

.p-news {
  &-detail {
    &__note {
      font-size: 1.4rem;
    }

    &__inner {
      padding: 1rem 0 10rem;
      border-bottom: 1px solid $c_lightgray;
      max-width: 128rem;
      width: 93%;
      margin: auto;
    }

    &__to {
      font-weight: bold;
      margin-bottom: 4rem;
    }

    &-content {
      background: #fff;
      padding: 9rem;
      box-sizing: border-box;

      @include mq-down() {
        padding: 2rem;
      }

      .p-column-title__main {
        white-space: pre-wrap;
      }

      h2 {
        font-size: 3rem;
        line-height: 1.5;
        margin-bottom: 3rem;
        font-weight: bold;

        @include mq-down() {
          font-size: 2rem;
        }

        &:not(:first-child) {
          margin-top: 4rem;
        }
        span {
          display: block;
          font-size: 2rem;
        }
      }
      h3 {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.5;
      }
      > ul {
        list-style: disc inside;

        li {
          padding-left: 3.5rem;
          padding-top: 2px;
          padding-bottom: 2px;
          text-indent: -2.5rem;

          &::marker {
            color: $c_darkgreen;
          }
        }
      }
      strong {
        font-weight: bold;
      }

      & p {
        + p {
          margin-top: 1.5rem;
        }
      }

      a {
        color: #000;
        text-decoration: underline;
        transition: 0.3s;

        &:hover {
          text-decoration: none;
        }
      }
      .button-contact {
        a {
          color: #fff;
          text-decoration: none;
        }
      }

      &__date {
        color: $c_darkgray;
        font-size: 14px;
        text-align: right;
        margin-top: 4rem;
        margin-bottom: 6rem;
      }

      .c-table {
        width: 100%;
      }
    }

    &__other {
      width: 93%;
      max-width: 128rem;
      margin: auto;
      padding-top: 6rem;
      padding-bottom: 10rem;
      border-bottom: 1px solid $c_lightgray;

      h2 {
        margin-bottom: 3.5rem;
      }

      ul {
        width: 100%;
        margin: 0 auto;

        &:first-of-type {
          margin-bottom: 5.5rem;
        }
      }
    }

    .button-wrap {
      margin-top: 5rem;
    }

    .c-tag__white {
      border-bottom: 1px solid $c_palegray;
      padding-bottom: 2rem;
    }

    .p-news-detail-content__date {
      margin-top: 2rem;
    }

    .l-table {
      min-width: 768px;
      width: 100%;

      @include mq-down() {
        width: max-content;
      }
    }
  }
}
