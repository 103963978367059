%flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
%pagination_btn {
  width: 4rem;
  height: 4rem;
  border: 1px solid $c_lightgray;
  box-sizing: border-box;
}

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    max-width: calc(100% - 11rem);

    li {
      @extend %flex_center;
      @extend %pagination_btn;

      &.current {
        background-color: $c_lightgray;
      }

      a {
        width: 100%;
        height: 100%;
        @extend %flex_center;

        &:hover {
          background-color: $c_lightgray;
        }
      }
    }
  }

  &__prev,
  &__next {
    @extend %flex_center;
    @extend %pagination_btn;

    a {
      width: 100%;
      height: 100%;
      @extend %flex_center;

      &:hover {
        background-color: $c_lightgray;
      }
    }
  }

  &__prev {
    img {
      transform: rotate(180deg);
    }
  }
}