// * {
//   box-sizing: border-box;
// }
html {
  font-size: 10px;
}

body {
  font-family:  $basic_font;
  color: $c_block;
  word-break: break-all;

  // 仮
  @include mq-down(l) {
    color: $c_block;
  }
  @include mq-down() {
    padding: 0;
  }
  // 仮
  @include mq-down(s) {
    color: $c_block;
  }
  @include mq-up(m) {color: $c_block;}
  @include mq-up(l) {color: $c_block;}
}

a {
  &:hover {
    opacity: 0.8;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    color:$c_block;
}

img {
  max-width: 100%;
  height: auto;
}

.overflowHidden {
  @include mq-down() {
    overflow: hidden;
  }
}