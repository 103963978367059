@mixin font10 {
	font-size: clamp(10px, .521vw,10px);
}
@mixin font12 {
	font-size: clamp(10px, .625vw,12px);
}
@mixin font13 {
	font-size: clamp(10px, .677vw,13px);
}
@mixin font14 {
	font-size: clamp(12px, .729vw,14px);
}
@mixin font15 {
	font-size: clamp(13px, .78vw,15px);
}
@mixin font16 {
	font-size: clamp(14px, .8333vw,16px);
}
@mixin font18 {
	font-size: clamp(16px, .9375vw,18px);
}
@mixin font20 {
	font-size: clamp(18px, 1.046vw,20px);
}
@mixin font22 {
	font-size: clamp(18px, 1.146vw,22px);
}
@mixin font24 {
	font-size: clamp(18px, 1.25vw,24px);
}
@mixin font26 {
	font-size: clamp(18px, 1.354vw,26px);
}
@mixin font28 {
	font-size: clamp(18px, 1.458vw,28px);
}
@mixin font30 {
	font-size: clamp(24px, 1.562vw,30px);
}
@mixin font38 {
	font-size: clamp(26px, 1.978vw,38px);
}
@mixin font40 {
	font-size: clamp(28px, 2.083vw,40px);
}
@mixin font48 {
	font-size: clamp(32px, 2.5vw,48px);
}
