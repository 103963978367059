@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@keyframes fadezoom {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.05, 1.05);
  }
}
@keyframes fadezoomSP {
  0% {
    transform: scale(0.5, 0.5) translateY(-50%) translateX(-50%);
  }
  100% {
    transform: scale(0.53, 0.53) translateY(-50%) translateX(-50%);
  }
}
/* =======================================

	Browser Reset CSS

========================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* for PC tablet */
@media only screen and (min-width: 768px) {
  body {
    font-family: "Noto Sans JP", 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, arial, clean, verdana, "ＭＳ Ｐゴシック", Osaka, sans-serif;
    font-size: 16px;
    line-height: 1.65em;
    color: #333333;
    min-width: 1140px;
  }
}
/* for mobile */
@media only screen and (max-width: 767px) {
  body {
    font-family: "Noto Sans JP", 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, arial, clean, verdana, "ＭＳ Ｐゴシック", Osaka, sans-serif;
    font-size: 16px;
    line-height: 1.65em;
    color: #333333;
    -webkit-text-size-adjust: 100%;
  }
}
ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* リンク指定
----------------------------------------------- */
/* リンク指定
----------------------------------------------- */
a:link,
a:visited {
  color: #000;
  text-decoration: none;
}

a:hover,
a:active {
  color: #000;
  text-decoration: none;
  filter: alpha(opacity=70);
  opacity: 0.7;
  transition-property: all;
  transition: 0.3s linear;
}

img a {
  border: none;
}

a:hover img {
  filter: alpha(opacity=70);
  opacity: 0.7;
  transition-property: all;
  transition: 0.3s linear;
}

/*.line2.0 {
	line-height: 3.0em;
}*/
strong {
  font-weight: bold;
  vertical-align: baseline;
}

sup {
  font-size: 0.83em;
  vertical-align: super;
}

sub {
  font-size: 0.83em;
  vertical-align: sub;
}

body {
  min-width: unset;
}

html {
  font-size: 10px;
}

body {
  font-family: "Noto Sans JP", 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, arial, clean, verdana, "ＭＳ Ｐゴシック", Osaka, sans-serif;
  color: #333;
  word-break: break-all;
}
@media screen and (max-width: 1280px) {
  body {
    color: #333;
  }
}
@media screen and (max-width: 767px) {
  body {
    padding: 0;
  }
}
@media screen and (max-width: 480px) {
  body {
    color: #333;
  }
}
@media screen and (min-width: 768px) {
  body {
    color: #333;
  }
}
@media screen and (min-width: 1281px) {
  body {
    color: #333;
  }
}

a:hover {
  opacity: 0.8;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #333;
}

img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .overflowHidden {
    overflow: hidden;
  }
}

.l-column02 {
  column-count: 2 !important;
}
@media screen and (max-width: 767px) {
  .l-column02 {
    column-count: 1 !important;
  }
}

.inner {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  padding: 10rem 0;
}
@media screen and (max-width: 767px) {
  .inner {
    padding: 7rem 0;
  }
}

.l-mv__underlayer {
  height: 20rem;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv__underlayer {
    margin-top: 6.2rem;
  }
}
.l-mv__underlayer h1 {
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}
@media screen and (max-width: 1280px) {
  .l-mv__underlayer h1 {
    font-size: clamp(28px, 2.083vw, 40px);
  }
}
.l-mv__underlayer__front-img {
  position: absolute;
  left: 65%;
  bottom: 0;
  z-index: 0;
}
.l-mv__underlayer__back-img {
  position: absolute;
  z-index: -1;
}
.l-mv__underlayer__back-img img {
  max-width: unset;
  width: auto;
  height: 100%;
}
.l-mv__underlayer.l-mv__img {
  height: 30rem;
  background: unset;
  overflow: hidden;
}
.l-mv__underlayer.l-mv__img h1 {
  color: #fff;
}
.l-mv__product-detail {
  height: 30rem;
  overflow: hidden;
  position: relative;
  background-color: #ccc;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail {
    height: auto;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
.l-mv__product-detail__main {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.l-mv__product-detail__title {
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  width: 93%;
  margin: auto;
}
.l-mv__product-detail__title h1 {
  color: #fff;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 4rem;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__title h1 {
    font-size: clamp(26px, 1.978vw, 38px);
  }
}
.l-mv__product-detail__title h1 span {
  font-size: 3rem;
}
.l-mv__product-detail__title p {
  color: #fff;
}
.l-mv__product-detail__catch {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.l-mv__product-detail__text {
  margin-top: 1rem;
}
.l-mv__product-detail__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.l-mv__product-detail__bg img {
  max-width: none;
  height: 100%;
}
.l-mv__product-detail__flex {
  height: 45rem;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex {
    height: auto;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
.l-mv__product-detail__flex::before {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent transparent #000;
    border-width: 5rem 0 0 100vw;
    position: absolute;
    right: 0;
    bottom: 30%;
    z-index: 1;
  }
}
.l-mv__product-detail__flex::after {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex::after {
    content: "";
    display: block;
    width: 100vw;
    height: 30%;
    background: #000;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}
.l-mv__product-detail__flex.l-mv__low {
  height: 30rem;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex.l-mv__low {
    height: auto;
  }
}
.l-mv__product-detail__flex.l-mv__low .l-mv__product-detail__flex__bg img {
  max-width: unset;
  width: auto;
  height: 30rem;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex.l-mv__low .l-mv__product-detail__flex__bg img {
    height: 100%;
  }
}
.l-mv__product-detail__flex.l-mv__low .l-mv__product-detail__flex__bg::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 30rem 11rem;
  border-color: transparent transparent #000 transparent;
  position: absolute;
  top: 0;
  right: 35%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex.l-mv__low .l-mv__product-detail__flex__bg::before {
    display: none;
  }
}
.l-mv__product-detail__flex__main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  max-width: 128rem;
  width: 93%;
  height: max-content;
  margin: auto;
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__main {
    gap: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__main {
    flex-direction: column;
    gap: 3rem;
    position: relative;
  }
}
.l-mv__product-detail__flex__title {
  width: 55%;
}
.l-mv__product-detail__flex__title.adapter {
  width: 60%;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__title {
    width: auto;
  }
}
.l-mv__product-detail__flex__title h1, .l-mv__product-detail__flex__title p {
  color: #fff;
}
.l-mv__product-detail__flex__title h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__title h1 {
    font-size: clamp(28px, 2.083vw, 40px);
  }
}
.l-mv__product-detail__flex__title h1 span {
  font-size: 3rem;
  vertical-align: bottom;
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__title h1 span {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.l-mv__product-detail__flex__catch {
  font-size: 2rem;
}
.l-mv__product-detail__flex__btns {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.l-mv__product-detail__flex__btns .button-product {
  width: 100%;
}
.l-mv__product-detail__flex__btns .button-product a {
  width: 575px;
  justify-content: center;
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__btns .button-product a {
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__btns .button-quotation,
.l-mv__product-detail__flex__btns .button-contact {
    width: calc((100% - 15px) / 2);
  }
}
@media screen and (max-width: 1280px) {
  .l-mv__product-detail__flex__btns .button-quotation a,
.l-mv__product-detail__flex__btns .button-contact a {
    width: 100%;
  }
}
.l-mv__product-detail__flex__img {
  width: 45%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__img {
    width: auto;
  }
}
.l-mv__product-detail__flex__img.adapter {
  width: 40%;
}
.l-mv__product-detail__flex__img img {
  margin: 0 auto;
}
.l-mv__product-detail__flex__bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__bg {
    top: auto;
    bottom: 0;
    height: 100%;
  }
}
.l-mv__product-detail__flex__bg img {
  max-width: unset;
  width: auto;
  height: 45rem;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__bg img {
    height: 100%;
  }
}
.l-mv__product-detail__flex__bg::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 45rem 11rem;
  border-color: transparent transparent #000 transparent;
  position: absolute;
  top: 0;
  right: 35%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__bg::before {
    top: auto;
    left: 0;
    right: auto;
    bottom: 40vw;
    border-width: 0 100vw 10vw 0;
  }
}
.l-mv__product-detail__flex__bg::after {
  content: "";
  display: block;
  width: 35%;
  height: 100%;
  background-color: #000;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .l-mv__product-detail__flex__bg::after {
    display: none;
  }
}
.l-mv__underlayer-m-l {
  height: 450px;
  background: #f8f8f8;
  margin-top: 8rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv__underlayer-m-l {
    margin-top: 6.2rem;
  }
}
.l-mv__underlayer-m-l h1 {
  font-size: 4rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: #fff;
}
@media screen and (max-width: 1280px) {
  .l-mv__underlayer-m-l h1 {
    font-size: clamp(28px, 2.083vw, 40px);
  }
}
.l-mv__underlayer-m-l__catch {
  font-size: clamp(18px, 1.046vw, 20px);
  font-weight: bold;
  color: #FFF;
  line-height: 1.75;
  margin-bottom: 3rem;
}
.l-mv__underlayer-m-l__front-img {
  position: absolute;
  left: 65%;
  bottom: 0;
  z-index: 0;
}
.l-mv__underlayer-m-l__back-img {
  position: absolute;
  z-index: -1;
  top: 0;
  text-align: center;
  width: 100%;
}
.l-mv__underlayer-m-l__back-img img {
  display: block;
  margin: auto;
  max-width: unset;
  width: auto;
  height: 100%;
}
.l-mv__underlayer-m-l__btns {
  width: 584px;
}
@media screen and (max-width: 767px) {
  .l-mv__underlayer-m-l__btns {
    width: 100%;
  }
}
.l-mv__underlayer-m-l__btns .button-contact a {
  width: 100%;
}
.l-mv__underlayer-m-l.l-mv__img {
  height: 450px;
  background: unset;
  overflow: hidden;
}
.l-mv__underlayer-m-l.l-mv__img h1 {
  color: #fff;
}
.l-mv__underlayer-s-l {
  height: 30rem;
  background: #f8f8f8;
  margin-top: 8rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv__underlayer-s-l {
    margin-top: 6.2rem;
    height: auto !important;
    min-height: 27rem;
    padding-bottom: 3rem;
  }
}
.l-mv__underlayer-s-l h1 {
  font-size: 4rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  color: #fff;
}
.l-mv__underlayer-s-l h1 span.sub {
  display: block;
  font-size: 18px !important;
}
@media screen and (max-width: 1280px) {
  .l-mv__underlayer-s-l h1 {
    font-size: clamp(28px, 2.083vw, 40px);
  }
}
.l-mv__underlayer-s-l h1.pt-110 {
  padding-top: 110px;
}
.l-mv__underlayer-s-l__catch {
  font-size: clamp(18px, 1.046vw, 20px);
  font-weight: bold;
  color: #FFF;
  line-height: 1.75;
  margin-bottom: 3rem;
  font-feature-settings: "palt";
}
.l-mv__underlayer-s-l__back-img {
  position: absolute;
  z-index: -1;
  top: 0;
  text-align: center;
  right: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .l-mv__underlayer-s-l__back-img {
    width: auto;
    height: 100%;
  }
}
.l-mv__underlayer-s-l__back-img img {
  display: block;
  margin: auto;
  max-width: unset;
  width: auto;
  height: 100%;
}
.l-mv__underlayer-s-l.l-mv__img {
  height: 30rem;
  background: unset;
  overflow: hidden;
}
.l-mv__underlayer-s-l.l-mv__img h1 {
  color: #fff;
}

.l-mv__inner {
  width: 93%;
  max-width: 128rem;
  margin: auto;
}

.l-overflow__scroll--x {
  overflow-x: auto;
}
.l-overflow__scroll--x > * {
  width: max-content;
}
.l-overflow__scroll--y {
  overflow-y: auto;
}
.l-overflow__scroll--y > * {
  width: max-content;
}
.l-overflow__scroll {
  overflow: auto;
}
.l-overflow__scroll > * {
  width: max-content;
}
.l-overflow__hidden--x {
  overflow-x: hidden;
}
.l-overflow__hidden--y {
  overflow-y: hidden;
}
.l-overflow__hidden {
  overflow: hidden;
}

.l-table {
  width: 128rem;
  box-sizing: border-box;
  border-collapse: collapse;
}
.l-table.border-top {
  border-top: 1px solid #ccc;
}
.l-table colgroup .w700 {
  width: 70rem;
}
.l-table colgroup .w600 {
  width: 60rem;
}
.l-table colgroup .w500 {
  width: 50rem;
}
.l-table colgroup .w400 {
  width: 40rem;
}
.l-table colgroup .w300 {
  width: 30rem;
}
.l-table colgroup .w200 {
  width: 20rem;
}
.l-table colgroup .w150 {
  width: 15rem;
}
.l-table colgroup .w100 {
  width: 10rem;
}
.l-table.u-fixed {
  table-layout: fixed;
}
.l-table th {
  border-bottom: 1px solid #ccc;
}
.l-table td {
  border-bottom: 1px solid #ccc;
}
.l-table__th-top thead {
  background-color: #999;
}
.l-table__th-top thead th {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 1rem 2rem;
}
.l-table__th-top thead th:not(:last-child), .l-table__th-top thead th.border-right {
  border-right: 1px solid #fff;
}
.l-table__th-top thead th.bg_palegray {
  background-color: #f8f8f8;
}
.l-table__th-top thead th.bg_palegray.border-right {
  border-right: 1px solid #ccc;
}
.l-table__th-top thead th {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 1rem 2rem;
}
.l-table__th-top thead th:not(:last-child), .l-table__th-top thead th.border-right {
  border-right: 1px solid #fff;
}
.l-table__th-top thead td {
  background-color: #f8f8f8;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  padding: 1rem 2rem;
}
.l-table__th-top thead td.border-right {
  border-right: 1px solid #ccc;
}
.l-table__th-top thead.align-left th {
  text-align: left;
}
.l-table__th-top tbody tr {
  border-bottom: 1px solid #ccc;
}
.l-table__th-top tbody th, .l-table__th-top tbody td {
  padding: 2rem;
  vertical-align: middle;
}
.l-table__th-top tbody th.border-right, .l-table__th-top tbody td.border-right {
  border-right: 1px solid #ccc;
}
.l-table__th-top tbody th {
  text-align: left;
  font-weight: bold;
  background-color: #f8f8f8;
}
.l-table__th-top tbody.u-table-bycolor01 :is(th, td):nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-top tbody.u-table-bycolor01 :is(th, td):nth-child(odd):not(:last-child) {
  border-right: 1px solid #fff;
}
.l-table__th-top tbody.u-table-bycolor01 :is(th, td):nth-child(even) {
  background-color: #fff;
}
.l-table__th-top tbody.u-table-bycolor02 :is(th, td):nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-top tbody.u-table-bycolor02 :is(th, td):nth-child(odd) {
  background-color: #fff;
}
.l-table__th-top tbody.u-table-bycolor03 tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-top tbody.u-table-bycolor03 tr:nth-child(even) {
  background-color: #fff;
}
.l-table__th-top tbody.u-table-bycolor03 th {
  background-color: unset;
}
.l-table__th-top tbody.u-table-bycolor04 tr:nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-top tbody.u-table-bycolor04 tr:nth-child(odd) {
  background-color: #fff;
}
.l-table__th-top tbody.u-table-bycolor04 th {
  background-color: unset;
}
.l-table__th-side th, .l-table__th-side td {
  padding: 2rem;
  vertical-align: middle;
}
.l-table__th-side th {
  text-align: left;
  font-weight: bold;
  background-color: #f8f8f8;
}
.l-table__th-side th.border-right {
  border-right: 1px solid #ccc;
}
.l-table__th-side.u-table-bycolor01 :is(th, td):nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-side.u-table-bycolor02 :is(th, td):nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-side.u-table-bycolor02 :is(th, td):nth-child(odd) {
  background-color: #fff;
}
.l-table__th-side.u-table-bycolor03 tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-side.u-table-bycolor03 tr:nth-child(even) {
  background-color: #fff;
}
.l-table__th-side.u-table-bycolor03 th {
  background-color: unset;
}
.l-table__th-side.u-table-bycolor04 tr:nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-side.u-table-bycolor04 tr:nth-child(odd) {
  background-color: #fff;
}
.l-table__th-side.u-table-bycolor04 th {
  background-color: unset;
}
.l-table__th-top-side thead {
  background-color: #999;
}
.l-table__th-top-side thead th {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  padding: 1rem 2rem;
}
.l-table__th-top-side thead th:first-child {
  border-bottom: 1px solid #ccc;
}
.l-table__th-top-side thead th:last-child {
  border-right: 1px solid #999;
}
.l-table__th-top-side thead th:not(:last-child), .l-table__th-top-side thead th.border-right {
  border-right: 1px solid #fff;
}
.l-table__th-top-side tbody th, .l-table__th-top-side tbody td {
  padding: 2rem;
  vertical-align: middle;
}
.l-table__th-top-side tbody th.border-right, .l-table__th-top-side tbody td.border-right {
  border-right: 1px solid #ccc;
}
.l-table__th-top-side tbody th {
  text-align: left;
  font-weight: bold;
  background-color: #f8f8f8;
}
.l-table__th-top-side tbody.u-table-bycolor01 :is(th, td):nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-top-side tbody.u-table-bycolor01 :is(th, td):nth-child(odd):not(:last-child) {
  border-right: 1px solid #fff;
}
.l-table__th-top-side tbody.u-table-bycolor01 :is(th, td):nth-child(even) {
  background-color: #fff;
}
.l-table__th-top-side tbody.u-table-bycolor02 :is(th, td):nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-top-side tbody.u-table-bycolor02 :is(th, td):nth-child(odd) {
  background-color: #fff;
}
.l-table__th-top-side tbody.u-table-bycolor03 tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.l-table__th-top-side tbody.u-table-bycolor03 tr:nth-child(even) {
  background-color: #fff;
}
.l-table__th-top-side tbody.u-table-bycolor03 th {
  background-color: unset;
}
.l-table__th-top-side tbody.u-table-bycolor04 tr:nth-child(even) {
  background-color: #f8f8f8;
}
.l-table__th-top-side tbody.u-table-bycolor04 tr:nth-child(odd) {
  background-color: #fff;
}
.l-table__th-top-side tbody.u-table-bycolor04 th {
  background-color: unset;
}
.l-table.no-scroll {
  max-width: 128rem;
  width: 100%;
}

.l-table-mini {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}
.l-table-mini thead th {
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  background-color: #999;
  height: 3rem;
  padding: 0 1.5rem;
}
.l-table-mini thead th:first-child {
  width: 3rem;
}
.l-table-mini thead th:not(:last-child) {
  border-right: 1px solid #fcfcfc;
}
.l-table-mini tbody tr > * {
  height: 4.2rem;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
.l-table-mini tbody tr > *:first-child {
  text-align: center;
  background-color: #fcfcfc;
}
.l-table-mini tbody tr > *:not(:first-child) {
  padding: 0 1.5rem;
}

.l-table-mini2 {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}
.l-table-mini2 thead th {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  background-color: #999;
  padding: 0.5rem 1.5rem;
}
.l-table-mini2 thead th:not(:last-child) {
  border-right: 1px solid #fcfcfc;
}
.l-table-mini2 tbody tr > * {
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
.l-table-mini2 tbody th, .l-table-mini2 tbody td {
  padding: 2.5rem;
}
.l-table-mini2 tbody th {
  width: 30%;
  background-color: #ccc;
  font-weight: bold;
  vertical-align: middle;
  border-bottom: 1px solid #fff;
}
.l-table-mini2 tbody th.bg_palegray {
  background-color: #f8f8f8;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.l-table-mini2 tbody td {
  background-color: #FFF;
}

.l-table-scroll {
  overflow-x: auto;
}

.c-adobe {
  background: #f8f8f8;
  display: flex;
  gap: 2.5rem;
  padding: 1.5rem 6.5rem;
}
.c-adobe--img, .c-adobe--text {
  margin-bottom: 0 !important;
}

.c-arrow__top {
  display: inline-block;
  width: 0;
  height: 0;
  border-bottom: 36px solid #00c05b;
  border-right: 64px solid transparent;
  border-left: 64px solid transparent;
}
.c-arrow__bottom {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 36px solid #00c05b;
  border-right: 64px solid transparent;
  border-left: 64px solid transparent;
}
.c-arrow__right {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 36px solid #00c05b;
  border-bottom: 64px solid transparent;
  border-top: 64px solid transparent;
}

.c-bnr-about-company {
  border-bottom: 1px solid #ccc;
  padding-bottom: 104px;
  position: relative;
}
.c-bnr-about-company__container {
  width: 100rem;
  margin-top: 3rem;
  margin-right: auto;
  margin-left: auto;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0px 0px 11.31px 1.69px rgba(51, 51, 51, 0.18);
  font-family: "Noto Sans CJK JP";
  display: block;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__container {
    width: 90%;
  }
}
.c-bnr-about-company__inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__inner {
    width: 100%;
    display: block;
  }
}
.c-bnr-about-company__img {
  background-color: #f8f8f8;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__img {
    width: 100%;
    box-sizing: border-box;
    padding: 4rem 0;
  }
}
.c-bnr-about-company__contents {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.7rem;
  padding: 4rem 0 0 4rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__contents {
    width: 100%;
    padding: 5% 0 0 5%;
  }
}
.c-bnr-about-company__txt-01 {
  font-size: 1.6rem;
  color: #008c41;
  font-weight: bold;
  margin-top: 4.8rem;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__txt-01 {
    margin-top: 2rem;
  }
}
.c-bnr-about-company__txt-02 {
  font-size: 3rem;
  line-height: 1.5;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__txt-02 {
    font-size: 2rem;
  }
}
.c-bnr-about-company__txt-03 {
  color: #000000;
  font-size: 1.6rem;
  line-height: 1.75;
}
.c-bnr-about-company__link {
  font-size: 1.6rem;
  color: #ffffff !important;
  background-color: #008c41;
  text-decoration: none;
  font-weight: bold;
  margin-left: auto;
  display: block;
  margin-top: 1rem;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  padding: 1rem 2.4rem 1rem 4.4rem;
  float: right;
}
@media screen and (max-width: 767px) {
  .c-bnr-about-company__txt {
    padding: 0 2rem;
  }
}

.c-bnr-import-net {
  position: relative;
  width: 100%;
  margin-bottom: 6rem;
  padding: 2rem 0;
  overflow: hidden;
  border-left: 4px solid #008c41;
  background-color: #f8f8f8;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net {
    border-top: 4px solid #008c41;
    border-left: none;
    padding: 0 0 2rem 0;
  }
}
.c-bnr-import-net__container {
  box-sizing: border-box;
  display: flex;
  z-index: 1;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 5%;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__container {
    flex-direction: column;
    gap: 2rem;
    margin: 0;
    width: 100%;
  }
}
.c-bnr-import-net__container:before {
  content: "";
  background: #cccccc;
  width: 770px;
  height: 600px;
  position: absolute;
  left: -450px;
  top: -150px;
  z-index: -2;
  border-radius: 2000px;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__container:before {
    display: none;
  }
}
.c-bnr-import-net__img-wrapper, .c-bnr-import-net__txt-wrapper {
  width: 50%;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__img-wrapper {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 5% 10%;
    background: #CCC;
    border-radius: 0 !important;
  }
}
.c-bnr-import-net__txt-wrapper {
  padding: 0 0 0 3rem;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__txt-wrapper {
    width: 100%;
    padding: 0 2rem;
    text-align: center;
    box-sizing: border-box;
  }
}
.c-bnr-import-net__txt-01, .c-bnr-import-net__txt-02 {
  margin-top: 0;
}
.c-bnr-import-net__txt-01 {
  font-size: 3rem;
  font-weight: bold;
  padding-top: 2rem;
  line-height: 1.5;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__txt-01 {
    font-size: 2.2rem;
  }
}
.c-bnr-import-net__txt-02 {
  font-size: 1.6rem;
  padding-top: 2rem;
  line-height: 1.75;
  display: block;
}
.c-bnr-import-net__link {
  display: block;
  font-size: 1.6rem;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem 0;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  border-radius: 30px;
  z-index: 50;
  background: #000;
}
@media screen and (max-width: 767px) {
  .c-bnr-import-net__link {
    width: 90%;
  }
}
.c-bnr-import-net__link:hover {
  color: #FFF;
}

.wave-gray {
  background: #ccc;
}

.c-box__item--wide {
  width: 100%;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  display: flex;
}
@media screen and (max-width: 767px) {
  .c-box__item--wide {
    flex-direction: column;
  }
}
.c-box__item--wide figure {
  width: 49%;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .c-box__item--wide figure {
    width: 100%;
  }
}
.c-box__item--wide__info {
  width: 51%;
  padding: 4rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-box__item--wide__info {
    width: 100%;
    padding: 2rem;
  }
}
.c-box__item--wide__info p {
  margin: 2rem 0;
}
.c-box__item--wide .c-textlink__download {
  text-align: right;
  display: block;
  margin-top: 3rem;
}
.c-box__item--name {
  font-size: 2rem;
  font-weight: bold;
  color: #008c41;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1280px) {
  .c-box__item--name {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.c-box__item--time {
  font-size: 12px;
  color: #ccc;
  padding-left: 2rem;
  margin-bottom: 2rem;
  position: relative;
}
.c-box__item--time::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url("../../assets/img/ico_update.png");
  position: absolute;
  left: 0;
  bottom: 5px;
}
.c-box__item--product {
  padding: 2rem 3rem;
  border: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .c-box__item--product {
    padding: 2rem;
  }
}
.c-box__item--product.u-flex {
  display: flex;
  gap: 2%;
}
.c-box__item--product.u-flex .c-list-ul {
  width: 49%;
}
.c-box__item--half {
  width: calc((100% - 4rem) / 2);
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 767px) {
  .c-box__item--half {
    width: calc((100% - 3rem) / 2);
  }
}
.c-box__item--half__info {
  padding: 4rem;
}
@media screen and (max-width: 1280px) {
  .c-box__item--half__info {
    padding: 2rem;
  }
}
.c-box__item--half .c-textlink__download {
  text-align: right;
  display: block;
  margin-top: 3rem;
}
.c-box__item--article {
  width: 100%;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
}
@media screen and (max-width: 767px) {
  .c-box__item--article {
    flex-direction: column;
  }
}
.c-box__item--article figure {
  width: 16rem;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
}
@media screen and (max-width: 767px) {
  .c-box__item--article figure {
    width: 100%;
  }
}
.c-box__item--article figure img {
  object-fit: cover;
  width: 16rem;
  height: 16.2rem;
}
.c-box__item--article__info {
  width: calc(100% - 16rem);
  padding: 2rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-box__item--article__info {
    width: 100%;
    padding: 2rem;
  }
}
.c-box__item--article__info p {
  margin: 2rem 0;
}
.c-box__item--article .c-textlink__download {
  text-align: right;
  display: block;
  margin-top: 3rem;
}

.c-cardbox.c-cardbox-img .c-cardbox__item a {
  display: flex;
  flex-direction: row;
}
.c-cardbox.c-cardbox-img .c-cardbox__item--img {
  width: 13rem;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .c-cardbox.c-cardbox-img .c-cardbox__item--img {
    width: 10rem;
  }
}
.c-cardbox.c-cardbox-img .c-cardbox__item--text {
  padding: 2rem;
  padding-right: 4rem !important;
}
@media screen and (max-width: 767px) {
  .c-cardbox.c-cardbox-img .c-cardbox__item--text {
    padding-right: 3rem !important;
  }
}
.c-cardbox.u-flex__col1 .c-list-ul.u-col02,
.c-cardbox.u-flex__col1 .c-list-ol.u-col02 {
  column-count: 2;
  column-gap: 1rem;
}
@media screen and (max-width: 767px) {
  .c-cardbox.u-flex__col1 .c-list-ul.u-col02,
.c-cardbox.u-flex__col1 .c-list-ol.u-col02 {
    column-count: 1;
  }
}
.c-cardbox.u-flex__col1 a {
  display: flex;
}
@media screen and (max-width: 767px) {
  .c-cardbox.u-flex__col1 a {
    flex-direction: column;
  }
}
.c-cardbox.u-flex__col1 figure {
  width: 62rem;
}
@media screen and (max-width: 1280px) {
  .c-cardbox.u-flex__col1 figure {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .c-cardbox.u-flex__col1 figure {
    width: 100%;
  }
}
.c-cardbox.u-flex__col1 .c-cardbox__item--text {
  width: calc(100% - 62rem);
}
@media screen and (max-width: 1280px) {
  .c-cardbox.u-flex__col1 .c-cardbox__item--text {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .c-cardbox.u-flex__col1 .c-cardbox__item--text {
    width: 100%;
  }
}
.c-cardbox.u-flex__col1 .c-cardbox__item--text *:has(+ .align-right) {
  flex-grow: 1;
}
.c-cardbox.u-flex__col2 .c-list-ul,
.c-cardbox.u-flex__col2 .c-list-ol {
  column-count: 2;
  column-gap: 1rem;
}
@media screen and (max-width: 767px) {
  .c-cardbox.u-flex__col2 .c-list-ul,
.c-cardbox.u-flex__col2 .c-list-ol {
    column-count: 1;
  }
}
.c-cardbox.u-flex__col2 .c-list-ul.u-col01,
.c-cardbox.u-flex__col2 .c-list-ol.u-col01 {
  column-count: 1;
}
.c-cardbox__item {
  display: flex;
  flex-direction: column;
}
.c-cardbox__item.u-text-only .c-link__within .c-cardbox__item--text {
  position: relative;
  padding-right: 6rem;
}
.c-cardbox__item.u-text-only .c-link__within .c-cardbox__item--text::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2rem;
  width: 15px;
  height: 15px;
  transform: translateY(calc(-50% + 3px));
  background: url(../../assets/img/ico_arrow_green.png) no-repeat;
}
@media screen and (max-width: 767px) {
  .c-cardbox__item.u-text-only .c-link__within .c-cardbox__item--text::after {
    right: 1rem;
  }
}
.c-cardbox__item.u-text-only .c-link__external .c-cardbox__item--text {
  position: relative;
  padding-right: 6rem;
}
.c-cardbox__item.u-text-only .c-link__external .c-cardbox__item--text::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2rem;
  width: 19px;
  height: 19px;
  transform: translateY(calc(-50% + 3px));
  background: url(../../assets/img/ico_link_green.png) no-repeat;
}
.c-cardbox__item.u-text-only .c-cardbox__item--text {
  justify-content: center;
  gap: 15px;
}
.c-cardbox__item.u-text-only .c-cardbox__item--text .c-tag__white--red {
  position: static;
}
.c-cardbox__item.u-text-only .c-cardbox__item--title {
  color: #333;
  margin-bottom: 0;
}
.c-cardbox__item a {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}
.c-cardbox__item figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-cardbox__item figure img {
  width: 100%;
}
.c-cardbox__item--text {
  padding: 4rem 3rem;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .c-cardbox__item--text {
    padding: 3rem 2rem;
  }
}
.c-cardbox__item--text p:not([class]) {
  flex-grow: 1;
  margin-bottom: 2rem;
}
.c-cardbox__item--text .c-list-ul,
.c-cardbox__item--text .c-list-ol {
  flex-grow: 1;
}
.c-cardbox__item--text .c-list-ul li figure,
.c-cardbox__item--text .c-list-ol li figure {
  margin-bottom: 6.5rem;
}
.c-cardbox__item--title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  .c-cardbox__item--title {
    font-size: 16px;
  }
}
.c-cardbox__item--list {
  margin-bottom: 4.5rem;
  flex-grow: 1;
}
.c-cardbox__item--list .c-list-ul,
.c-cardbox__item--list .c-list-ol {
  border: 1px solid #ccc;
  padding: 2rem;
  margin-bottom: 0;
}
.c-cardbox.no-link .c-cardbox__item--title {
  text-align: center;
}
.c-cardbox.no-link .c-cardbox__item--text {
  padding: 3rem 0 0;
}
.c-cardbox.is-numbering .c-cardbox__item {
  position: relative;
}
.c-cardbox.is-numbering .c-cardbox__item::before {
  content: attr(data-num);
  color: #fff;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 5rem;
  height: 5rem;
  background-color: #008c41;
  border-radius: 50%;
}
.c-cardbox.is-numbering.u-text-only .c-cardbox__item--text {
  background-color: #f8f8f8;
}

.c-cardbox-simple a {
  display: flex;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 767px) {
  .c-cardbox-simple a {
    flex-direction: column;
  }
}
.c-cardbox-simple a figure {
  width: 40rem;
}
@media screen and (max-width: 767px) {
  .c-cardbox-simple a figure {
    width: 100%;
    text-align: center;
  }
}
.c-cardbox-simple a figure img {
  width: 100%;
}
.c-cardbox-simple__text {
  padding: 4rem;
  width: calc(100% - 40rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .c-cardbox-simple__text {
    width: 100%;
    padding: 3rem;
  }
}
.c-cardbox-simple__text--title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.c-cardbox-simple__text--link {
  text-align: right;
}
.c-cardbox-simple__text p:not([class]) {
  flex-grow: 1;
}

.c-imgbox__col1 {
  width: 93%;
  max-width: 128rem;
  margin: auto;
}
.c-imgbox__col1 a {
  display: flex;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 767px) {
  .c-imgbox__col1 a {
    flex-direction: column;
  }
}
.c-imgbox__col1 figure {
  width: 62rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .c-imgbox__col1 figure {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .c-imgbox__col1 figure {
    width: 100%;
  }
}
.c-imgbox__col1 figure img {
  width: 100%;
}
.c-imgbox__col1--text {
  width: calc(100% - 62rem);
  padding: 4rem;
  box-sizing: border-box;
}
@media screen and (max-width: 1280px) {
  .c-imgbox__col1--text {
    width: 52%;
  }
}
@media screen and (max-width: 767px) {
  .c-imgbox__col1--text {
    width: 100%;
  }
}
.c-imgbox__col1--title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 3rem;
}
.c-imgbox__col1--list {
  margin-bottom: 4.5rem;
  padding: 2rem 3rem;
  border: 1px solid #ccc;
}
.c-imgbox__col3bg--white .c-imgbox__col3bg__text {
  background: #fff;
}
.c-imgbox__col3bg--gray .c-imgbox__col3bg__text {
  background: #f8f8f8;
}
.c-imgbox__col3bg__item {
  width: calc((100% - 8rem) / 3);
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .c-imgbox__col3bg__item {
    width: 100%;
  }
}
.c-imgbox__col3bg__item:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  opacity: 1;
  transition: all 0.5s;
}
.c-imgbox__col3bg__item a {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.c-imgbox__col3bg__item figure img {
  width: 100%;
}
.c-imgbox__col3bg__text {
  padding: 3rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .c-imgbox__col3bg__text {
    padding: 2rem;
  }
}
.c-imgbox__col3bg__text p:not([class*=title]), .c-imgbox__col3bg__text ul:not([class*=tag]) {
  flex-grow: 1;
}
.c-imgbox__col3bg__title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.75;
  margin-bottom: 3rem;
}
.c-imgbox__col3bg__tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 6rem;
}
.c-imgbox__col3bg__tag li {
  padding: 5px;
  font-size: 14px;
  line-height: 1;
}
.c-imgbox__col3bg__tag .u-bg__darkgray {
  color: #fff;
}
.c-imgbox__col3bg__tag .u-bg__white {
  color: #999;
  background: #fff;
  border: 1px solid #999;
}
.c-imgbox__simple__shadow .c-imgbox__simple--item {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.c-imgbox__simple--item a {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5em;
  width: 100%;
}
.c-imgbox__simple--item figure {
  margin-bottom: 3rem;
}
.c-imgbox__simple--item figure img {
  width: 100%;
}
.c-imgbox__simple--title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.c-imgbox__class figure {
  margin-bottom: 3rem;
}
.c-imgbox__class figure img {
  width: 100%;
}
.c-imgbox__class--title {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  color: #008c41;
  margin-bottom: 2rem;
}
.c-imgbox__class .c-list-ul,
.c-imgbox__class .c-list-ol {
  width: 100%;
  text-align: left;
}
.c-imgbox__class .c-list-ul li,
.c-imgbox__class .c-list-ol li {
  padding-left: 2rem;
  text-indent: -2rem;
}

.c-iconbox__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.c-iconbox__item a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5rem 2rem 4rem;
  box-sizing: border-box;
}
.c-iconbox__item--title {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1280px) {
  .c-iconbox__item--title {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}

.c-linebox {
  background: #f8f8f8;
  border-top: 3px solid #00c05b;
  padding: 3rem;
}
.c-linebox a {
  margin: -3rem;
  padding: 3rem;
  box-shadow: none;
  justify-content: start;
}
.c-linebox-img {
  border-top: 3px solid #00c05b;
}

.c-content-box {
  background: #f8f8f8;
  padding: 4rem;
}
.c-content-box ul {
  column-count: 4;
}
.c-content-box ul.u-col01 {
  column-count: 1;
}

.c-label-box__item--label {
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-label-box__item--content {
  padding-top: 3rem;
  text-align: left;
}

.c-detail-box__item--text {
  padding: 3rem;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
}
.c-detail-box__item--title {
  font-size: 2rem;
  font-weight: bold;
  color: #008c41;
  margin-bottom: 3rem;
}
.c-detail-box__item--title span {
  font-size: 1.6rem;
  font-weight: normal;
  color: #333;
}
.c-detail-box__item--content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.c-detail-box__item--content .title {
  font-size: 1.8rem;
  font-weight: bold;
}
.c-detail-box__item--content p:not([class]), .c-detail-box__item--content > ul {
  margin-bottom: 1rem;
}
.c-detail-box__item--content figure {
  margin: 1.5rem 0;
}

.c-stepbox {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .c-stepbox {
    flex-direction: column;
  }
}
.c-stepbox__item {
  width: calc((100% - 4rem) / 2);
  padding: 4rem;
  background: #f8f8f8;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-stepbox__item {
    width: 100%;
  }
}
.c-stepbox__item:first-child {
  position: relative;
}
.c-stepbox__item:first-child::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 36px solid #00c05b;
  border-top: 64px solid transparent;
  border-bottom: 64px solid transparent;
  position: absolute;
  top: 50%;
  right: -36px;
  transform: translatey(-50%);
}
@media screen and (max-width: 767px) {
  .c-stepbox__item:first-child::before {
    border-top: 36px solid #00c05b;
    border-left: 64px solid transparent;
    border-right: 64px solid transparent;
    top: auto;
    bottom: -102px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.c-headerbox__item--header {
  background-color: #999;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-headerbox__item--header p {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.c-headerbox__item--body {
  padding: 4rem;
  background-color: #f8f8f8;
}
.c-headerbox__item--title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  margin: 3rem 0;
}

.u-flex__col3.c-cardbox.is-numbering > li:nth-child(n+4) {
  margin-top: 5rem;
}

.c-arrow-box__top {
  background: #f8f8f8;
  padding: 2rem;
  position: relative;
}
.c-arrow-box__top::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3rem 4rem 0 4rem;
  border-color: #f8f8f8 transparent transparent;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}
.c-arrow-box__bottom {
  padding-top: 5.5rem;
  text-align: left;
}

.c-breadcrumb {
  margin-bottom: 9rem;
}
@media screen and (max-width: 767px) {
  .c-breadcrumb {
    margin-bottom: 5rem;
  }
}
#columnDetailPage .c-breadcrumb {
  margin-bottom: 0;
}
#columnDetailPage .c-breadcrumb__inner {
  max-width: 128rem;
}
.c-breadcrumb__inner {
  max-width: 128rem;
  width: 93%;
  margin: 1rem auto auto;
}
.c-breadcrumb__inner::-webkit-scrollbar {
  display: none;
}
.c-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0 2rem;
  margin: 0 0 0 auto;
}
.c-breadcrumb ol li {
  position: relative;
  font-size: 14px;
}
.c-breadcrumb ol li:not(:first-of-type)::before {
  content: "";
  display: block;
  width: 4px;
  height: 8px;
  background: url("../../assets/img/ico_arrow_gray.png");
  position: absolute;
  top: 1rem;
  left: -1rem;
}

.button-wrap {
  margin-top: 40px;
}
.button-wrap a {
  margin: 0 auto;
}
.button-wrap__right a {
  margin-right: 0;
  margin-left: auto;
}
.button-wrap__left a {
  margin-right: auto;
  margin-left: 0;
}
.button-wrap__col2 {
  display: flex;
  gap: 4rem;
}
.button-wrap__col2--center {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.button-wrap__vertical {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.button-default a {
  width: 280px;
  background: #fff;
  border: 1px solid #333;
  color: #333;
  padding-left: 30px;
  font-size: 16px;
  display: block;
  position: relative;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .button-default a {
    width: 100%;
    height: 43px;
    font-size: 14px;
    padding-left: 1.5rem;
  }
}
.button-default a::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  right: -60px;
  z-index: -1;
  border-right: 60px solid transparent;
  border-bottom: 60px solid #333;
  transform: translateX(-100%);
  transition: transform ease 0.3s;
}
.button-default a::after {
  content: "";
  width: 6px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 14px;
  background-image: url(../img/ico_arrow_blk.png);
}
.button-default a:hover {
  color: #fff;
}
.button-default a:hover::before {
  transform: translateX(0);
}
.button-default a:hover::after {
  content: none;
  transition-property: all;
  transition-duration: 0.5s;
  transition-delay: 1s;
  transition-property: content;
  transition: transform ease 0.3s;
}
.button-default.u-long a {
  width: 57.5rem;
}
@media screen and (max-width: 767px) {
  .button-default.u-long a {
    width: 100%;
  }
}
.button-default.u-centering a {
  justify-content: center;
  padding-left: 0;
}

.button-external a {
  width: 280px;
  background: #fff;
  border: 1px solid #333;
  color: #333;
  padding-left: 30px;
  font-size: 16px;
  display: block;
  position: relative;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .button-external a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-external a::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  right: -60px;
  z-index: -1;
  border-right: 60px solid transparent;
  border-bottom: 60px solid #333;
  transform: translateX(-100%);
  transition: transform ease 0.3s;
}
.button-external a::after {
  content: "";
  width: 13px;
  height: 13px;
  display: inline-block;
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 14px;
  background-image: url(../img/ico_link_normal.png);
}
.button-external a:hover {
  color: #fff;
}
.button-external a:hover::before {
  transform: translateX(0);
}
.button-external a:hover::after {
  content: none;
  transition-property: all;
  transition-duration: 0.5s;
  transition-delay: 1s;
  transition-property: content;
  transition: transform ease 0.3s;
}
.button-external.u-centering a {
  justify-content: center;
  padding-left: 0;
}

.button-contact a {
  width: 280px;
  height: 50px;
  background: #00c05b;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .button-contact a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-contact a::before {
  content: "";
  display: block;
  width: 17px;
  height: 11px;
  background-image: url(../img/ico_floating_02.png);
}
.button-contact__big a {
  background: #00c05b;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  text-align-last: left;
  width: 100%;
  max-width: 40rem;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.button-quotation a {
  width: 280px;
  height: 50px;
  background: #00c05b;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .button-quotation a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-quotation a::before {
  content: "";
  display: block;
  width: 13px;
  height: 18px;
  background-image: url(../img/ico_floating_03.png);
}
.button-quotation__big a {
  background: #00c05b;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  text-align-last: left;
  width: 100%;
  max-width: 40rem;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.button-download a {
  width: 280px;
  height: 50px;
  background: #008c41;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .button-download a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-download a::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../img/ico_floating_01.png);
}
.button-download__type02 a {
  width: 280px;
  height: 50px;
  background: #008c41;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .button-download__type02 a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-download__type02 a::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(../img/ico_cva03.png);
  background-size: 100% auto;
}
.button-download__big a {
  background: #008c41;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  text-align-last: left;
  width: 100%;
  max-width: 40rem;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.button-inactive a {
  width: 280px;
  background: #ccc;
  color: #333;
  padding-left: 30px;
  font-size: 16px;
  position: relative;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .button-inactive a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-inactive a::after {
  content: "";
  width: 6px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 14px;
  background-image: url(../img/ico_arrow_blk.png);
}
.button-inactive.u-centering a {
  justify-content: center;
  padding-left: 0;
}

.button-product a {
  width: 280px;
  background: #fff;
  color: #333;
  padding-left: 30px;
  font-size: 16px;
  position: relative;
  font-weight: bold;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .button-product a {
    width: 100%;
    height: 43px;
    font-size: 14px;
  }
}
.button-product a::before {
  content: "";
  display: block;
  width: 21px;
  height: 18px;
  background-image: url(../img/ico_product.png);
}
.button-product.u-centering a {
  justify-content: center;
  padding-left: 0;
}

.c-card {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem 4rem;
}
.c-card--item {
  background: #fff;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  width: calc((100% - 4rem) / 2);
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .c-card--item {
    width: 100%;
  }
}
.c-card--item figure {
  background: #fcfcfc;
  text-align: center;
}
.c-card--item__text {
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.c-card--item__title {
  color: #00c05b;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .c-card--item__title {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.c-card--item__top {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.c-card--item__col2 {
  display: flex;
  flex-grow: 1;
  gap: 1%;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1280px) {
  .c-card--item__col2 {
    flex-direction: column;
    gap: 0;
  }
}
.c-card--item__col2 ul {
  flex: 1;
}
.c-card--item__dl {
  text-align: right;
  margin-top: 3rem;
}
.c-card__col3 {
  display: flex;
  flex-flow: wrap;
  gap: 6rem 4rem;
}
.c-card__col3--item {
  background: #fff;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  width: calc((100% - 8rem) / 3);
}
@media screen and (max-width: 767px) {
  .c-card__col3--item {
    width: 100%;
  }
}
.c-card__col3--item figure {
  background: #fcfcfc;
}
.c-card__col3--item__text {
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.c-card__col3--item__title {
  color: #00c05b;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .c-card__col3--item__title {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.c-card__col3--item__dl {
  text-align: right;
  margin-top: 3rem;
}
.c-card__simple {
  background: #f8f8f8;
  padding: 4rem;
}
.c-card__simple__title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.c-flex-card {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.c-flex-card.no-link, .c-flex-card > a {
  display: flex;
}
@media screen and (max-width: 767px) {
  .c-flex-card.no-link, .c-flex-card > a {
    flex-direction: column;
  }
}
.c-flex-card__img {
  flex: 1;
}
.c-flex-card__img img {
  width: 100%;
}
.c-flex-card__text {
  flex: 1;
  padding: 4rem;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .c-flex-card__text {
    padding: 3rem;
  }
}
.c-flex-card__text--title {
  font-size: 2rem;
  font-weight: bold;
  color: #008c41;
  margin-bottom: 3rem;
}
.c-flex-card__text p:not([class]), .c-flex-card__text ul {
  flex-grow: 1;
}

.p-column-content .c-card__col3 {
  gap: 6rem 1rem;
}
.p-column-content .c-card__col3--item {
  width: calc((100% - 3rem) / 3);
}
.p-column-content .c-card__col3--item__text {
  padding: 3rem 2rem;
}

.block-column_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .block-column_list {
    display: block;
  }
}
.block-column_list__items {
  width: 48%;
}
@media screen and (max-width: 1280px) {
  .block-column_list__items {
    display: table;
  }
}
@media screen and (max-width: 1150px) {
  .block-column_list__items {
    width: 100%;
  }
}
.block-column_list__items a {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid #c0ccd1;
  padding: 15px 15px 15px 30px;
}
@media screen and (max-width: 767px) {
  .block-column_list__items a {
    display: table-cell;
    vertical-align: middle;
  }
}
@media screen and (max-width: 767px) {
  .block-column_list__items a {
    padding: 15px 10px 15px 0;
  }
}
.block-column_list__items a::after {
  position: absolute;
  background-image: url(../img/ico_arrow_blk.png);
  content: "";
  width: 6px;
  height: 10px;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.block-column_list__items:last-of-type a, .block-column_list__items:nth-last-of-type(2) a {
  border-bottom: 1px solid #c0ccd1;
}
@media screen and (max-width: 767px) {
  .block-column_list__items:last-of-type a {
    border: none;
  }
}
@media screen and (max-width: 767px) {
  .block-column_list__items:nth-last-of-type(2) a {
    border-bottom: none;
  }
}
.block-column_list.u-wide .block-column_list__items {
  width: 100%;
}
.block-column_list.u-wide .block-column_list__items:nth-last-of-type(2) a {
  border-bottom: navajowhite;
}
.block-column_list__date {
  margin-right: 20px;
  font-size: 14px;
}
.block-column_list__cont {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .block-column_list__cont {
    display: block;
  }
}
.block-column_list__cont p {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .block-column_list__cont p {
    font-size: 14px;
    line-height: 1.7;
  }
}
.block-column_list__newIcon {
  margin-right: 15px;
  padding: 0 5px;
  border: 1px solid #d42109;
  color: #d42109;
  text-align: center;
  width: 56px;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .block-column_list__newIcon {
    width: 43px;
    font-size: 12px;
    line-height: 1.7;
    padding: 1px 5px 0;
    margin-bottom: 5px;
  }
}

.c-column-bnr {
  background: #008c41;
  width: 100%;
  margin: auto;
}
.c-column-bnr a {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .c-column-bnr a {
    flex-direction: reverse;
  }
}
.c-column-bnr__text {
  padding: 2rem 2.5rem;
  position: relative;
  width: calc(100% - 119px);
  box-sizing: border-box;
}
@media screen and (max-width: 480px) {
  .c-column-bnr__text {
    width: 70%;
    padding: 1rem 1.5rem;
  }
}
.c-column-bnr__text p {
  color: #f8f8f8;
}
.c-column-bnr__catch {
  font-size: clamp(16px, 0.9375vw, 18px);
  margin-bottom: 1rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .c-column-bnr__catch {
    font-size: clamp(12px, 0.729vw, 14px);
    margin-bottom: 1rem;
    padding-bottom: 6px;
    border-bottom: 1px solid #f8f8f8;
  }
}
.c-column-bnr__catch span {
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .c-column-bnr__catch span {
    border: none;
  }
}
.c-column-bnr__title {
  font-size: clamp(26px, 1.978vw, 32px);
  font-weight: bold;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .c-column-bnr__title {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.c-column-bnr__link {
  position: absolute;
  bottom: 1rem;
  right: -3rem;
  z-index: 1;
  background: #000;
  border-radius: 1000px;
  padding: 1px 3rem 3px;
  font-size: clamp(12px, 0.729vw, 14px);
}
@media screen and (max-width: 480px) {
  .c-column-bnr__link {
    position: relative;
    z-index: 1;
    margin: 2.5rem auto 0;
    width: max-content;
  }
}
.c-column-bnr__link p {
  position: relative;
}
.c-column-bnr__link p::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border: solid #f8f8f8;
  border-width: 0 3px 3px 0;
  position: absolute;
  right: -20px;
  top: 9px;
  z-index: 1;
  transform: rotate(-45deg);
}
@media screen and (max-width: 480px) {
  .c-column-bnr__link p::after {
    width: 6px;
    height: 6px;
    border-width: 0 2px 2px 0;
  }
}
.c-column-bnr__img {
  width: 119px;
}
@media screen and (max-width: 480px) {
  .c-column-bnr__img {
    width: 30%;
    position: relative;
  }
}
@media screen and (max-width: 480px) {
  .c-column-bnr__img img {
    position: absolute;
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-contact ul {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .c-contact ul {
    flex-direction: column;
    max-width: 40rem;
    width: 93%;
    margin: auto;
  }
}
.c-contact ul li .button-contact__big a, .c-contact ul li .button-download__big a, .c-contact ul li .button-quotation__big a {
  width: 40rem;
  height: 8rem;
}
@media screen and (max-width: 767px) {
  .c-contact ul li .button-contact__big a, .c-contact ul li .button-download__big a, .c-contact ul li .button-quotation__big a {
    width: 100%;
  }
}

a.cookielink {
  color: #fff;
  text-decoration: underline;
}
a.cookielink :hover {
  text-decoration: none;
}

.c-cva {
  padding-top: 0 !important;
}

.block-cva_list {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1280px) {
  .block-cva_list {
    width: 100%;
    gap: 10px;
  }
}
@media screen and (max-width: 767px) {
  .block-cva_list {
    flex-wrap: wrap;
  }
}
.block-cva_list__items {
  width: calc((100% - 8rem) / 5);
}
@media screen and (max-width: 767px) {
  .block-cva_list__items {
    width: 48%;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
}
.block-cva_list__items a {
  padding: 0;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  height: 108px;
  font-weight: bold;
}
@media screen and (max-width: 1280px) {
  .block-cva_list__items a {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-cva_list__items a {
    min-height: 75px;
    height: auto;
  }
}
.block-cva_list__items a:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  transition: all 0.5s;
  opacity: 1;
}
.block-cva_list__items a:hover img {
  opacity: 1;
}
.block-cva_list__items.contact a {
  background: #00c05b;
  color: #fff;
}
.block-cva_list__items.download a {
  background: #008c41;
  color: #fff;
}
.block-cva_list__items.guide a {
  background: #008c41;
  color: #fff;
}
.block-cva_list__cont {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .block-cva_list__cont {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }
}
.block-cva_list__cont p {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .block-cva_list__cont p {
    font-size: 13px;
    line-height: 1.4;
  }
}
.block-cva_list__icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .block-cva .block-content__title {
    text-align: center;
    line-height: 1.3;
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .block-cva .recruit {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-cva .recruit .block-cva_list__items a {
    width: 100%;
    height: 75px;
  }
}
.block-cva .recruit .block-cva_list__cont p {
  color: #333;
}
@media screen and (max-width: 767px) {
  .block-cva .recruit .block-cva_list__cont p {
    font-size: 13px;
    line-height: 1.4;
  }
}

.c-feature-block {
  background-image: url("../img/img_footer_feature.png");
  background-position: center 0;
  background-size: 1920px auto;
  background-repeat: no-repeat;
  margin-bottom: 10rem;
}
@media screen and (max-width: 1280px) {
  .c-feature-block {
    background-size: auto 100%;
    background-position: right 0;
  }
}
.c-feature-block .inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 4rem;
}
.c-feature-block__title {
  width: 100%;
  font-weight: bold;
  text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff;
}
.c-feature-block__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 62rem;
  width: calc(50% - 26px);
}
@media screen and (max-width: 1280px) {
  .c-feature-block__list {
    margin-bottom: 4rem;
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .c-feature-block__list {
    width: 100%;
  }
}
.c-feature-block__list li {
  background: #fff;
  padding: 1rem 3rem 1rem 4rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  position: relative;
  min-height: 10rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-feature-block__list li {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    height: auto;
  }
}
.c-feature-block__list li::before {
  content: attr(data-num);
  color: #fff;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -2.5rem;
  width: 5rem;
  height: 5rem;
  background-color: #999;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .c-feature-block__list li::before {
    font-size: 2rem;
    width: 4rem;
    height: 4rem;
    left: -1rem;
  }
}
.c-feature-block__list li p:first-child {
  width: 22rem;
  font-size: 18px;
  font-weight: bold;
  color: #008c41;
}
@media screen and (max-width: 767px) {
  .c-feature-block__list li p:first-child {
    width: 100%;
  }
}
.c-feature-block__list li p:last-child {
  width: calc(100% - 22rem);
}
@media screen and (max-width: 767px) {
  .c-feature-block__list li p:last-child {
    width: 100%;
  }
}
.c-feature-block__bnr {
  max-width: 62rem;
  width: calc(50% - 26px);
  background: #fff;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 1280px) {
  .c-feature-block__bnr {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .c-feature-block__bnr {
    width: 100%;
    margin-top: 2rem;
  }
}

footer {
  background: #fff;
}
@media screen and (max-width: 767px) {
  footer {
    padding-bottom: 80px;
  }
}
footer .inner {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 100px 0 30px;
  position: relative;
}
@media screen and (max-width: 767px) {
  footer .inner {
    width: 93%;
    margin: 0 auto;
    padding: 70px 0 30px;
  }
}

.footer-cont {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
@media screen and (max-width: 767px) {
  .footer-cont {
    display: flex;
    flex-wrap: wrap;
  }
}
.footer-cont_heading {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .footer-cont_heading {
    margin-bottom: 5px;
  }
}
.footer-cont_heading a {
  color: #333;
}
.footer-cont_list {
  padding-left: 1em;
}
@media screen and (max-width: 767px) {
  .footer-cont_list {
    padding-left: 0;
  }
}
.footer-cont_list li a {
  font-size: 14px;
  color: #333;
}
@media screen and (max-width: 767px) {
  .footer-cont_list li a {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .footer-cont_items {
    margin-bottom: 48px;
    width: 48%;
  }
}
.footer-sns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 30px 0;
  border-bottom: 1px solid #c0ccd1;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .footer-sns {
    justify-content: center;
  }
}
.footer-copy {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer-copy {
    display: block;
  }
}
.footer-copy_logo {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .footer-copy_logo {
    display: block;
  }
}
.footer-copy_logo p {
  margin-left: 20px;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .footer-copy_logo p {
    margin-left: 0;
    line-height: 1.7;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .footer-copy_logo a {
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
}
.footer-copy_copyright {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .footer-copy_copyright {
    display: flex;
    align-items: unset;
    justify-content: center;
  }
}
.footer-copy_copyright p {
  font-size: 12px;
  line-height: 1.7;
}
@media screen and (max-width: 767px) {
  .footer-copy_copyright p {
    font-size: 11px;
  }
}

header {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #fff;
}
@media screen and (max-width: 767px) {
  header {
    padding: 10px 5px;
  }
}
header .header_inner {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  header .header_inner {
    padding: 0;
  }
}
header .header_logo {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  header .header_logo {
    width: 80%;
  }
}
header .header_logo h1, header .header_logo .header_logo__text {
  font-size: 1.2rem;
}
@media screen and (max-width: 767px) {
  header .header_logo h1, header .header_logo .header_logo__text {
    line-height: 1;
    margin-bottom: 5px;
  }
}
header .header_logo img {
  width: 100%;
}
@media screen and (min-width: 1281px) and (max-width: 1480px) {
  header .header_logo img {
    width: 28vw;
  }
}
@media screen and (max-width: 1280px) {
  header .header_logo img {
    width: 25vw;
    min-width: 280px;
  }
}
@media screen and (max-width: 767px) {
  header .header_logo img {
    min-width: auto;
    width: 100%;
  }
}
header .header_cont {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  header .header_cont {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    background: #fff;
    text-align: center;
    transform: translateY(-100%);
    transition: 0.3s ease-in-out;
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
    -webkit-overflow-scrolling: touch;
  }
}
header .header_cont__list {
  display: flex;
  justify-content: space-between;
  gap: 3.5rem;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
}
@media screen and (min-width: 1281px) and (max-width: 1480px) {
  header .header_cont__list {
    gap: 1.25vw;
  }
}
@media screen and (max-width: 1280px) {
  header .header_cont__list {
    gap: 15px;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list {
    display: block;
    margin: 0 auto;
    padding: 155px 20px 50px 20px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: transparent;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li {
    font-size: 1.1em;
    list-style-type: none;
    padding: 0;
    border: none;
    margin: 0;
    text-align: left;
    margin-bottom: 35px;
    position: relative;
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  header .header_cont__list li .header_cont__list--link {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li .header_cont__list--link {
    display: block;
    color: #333;
    height: auto;
    align-items: center;
    font-size: 16px !important;
    letter-spacing: 0.07em;
    text-align: left;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li .header_cont__list--link::before {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li .header_cont__list--link::after {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 1px;
    width: 20px;
    background: #333;
    left: 12px;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li a span {
    margin-right: 5px;
    font-weight: bold;
    font-family: Verdana, "Noto Sans JP", Arial, sans-serif;
    color: #2c2c2c;
    font-size: 40px;
    font-style: italic;
    position: absolute;
    z-index: -1;
    left: 15px;
    top: -2px;
    letter-spacing: -0.07em;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li img {
    right: -75px;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list li:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
header .header_cont__list li:last-of-type a {
  background: none;
  text-align: left;
  line-height: unset;
}
header .header_cont__list li:last-of-type .header_cont__list--link:before {
  display: none;
}
header .header_cont__list > li:hover .child-menu_content {
  visibility: visible;
}
header .header_cont__list--items {
  display: inline-block;
}
@media screen and (max-width: 767px) {
  header .header_cont__list--items .child-menu_contArea ul {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    gap: 5px 10px;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list--items .child-menu_contArea ul:last-of-type {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  header .header_cont__list--items .child-menu_contArea ul li {
    width: calc(50% - 20px) !important;
    margin-bottom: 0;
    margin-right: 0;
  }
}
header .header_cont__list--items:last-child {
  padding: 15px 25px 15px 0;
}
header .header_cont__list--items a {
  color: #333;
}
header .header_cont__list--link {
  display: flex;
  padding: 0;
  height: 100%;
  align-items: center;
}
@media screen and (max-width: 767px) {
  header .header_cont.active {
    transform: translateY(0%);
    opacity: 1;
    background: #fff;
  }
}
header .navToggle {
  display: none;
}
@media screen and (max-width: 767px) {
  header .navToggle {
    display: block;
    position: fixed;
    right: 16px;
    top: 12px;
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    background: #00c262;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 50%;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle span {
    display: block;
    position: absolute;
    border-bottom: solid 3px #fff;
    transition: 0.35s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  header .navToggle span:nth-child(1) {
    top: 12px;
    left: 50%;
    width: 19px;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle span:nth-child(2) {
    top: 20px;
    left: 43%;
    width: 13px;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle span:nth-child(3) {
    top: 28px;
    left: 35%;
    width: 6px;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle span:nth-child(4) {
    border: none;
    color: #eee;
    font-size: 9px;
    font-weight: bold;
    top: 34px;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle.active {
    background: #00c262;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle.active::after {
    content: "CLOSE";
    position: fixed;
    top: 23px;
    right: 68px;
    color: #00c262;
    z-index: 12;
    font-size: 13px;
    font-style: italic;
    transition: 0.35s ease-in-out;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle.active span {
    width: 20px;
    top: 19px;
    left: 11px;
    border-bottom: solid 3px #fff;
  }
}
@media screen and (max-width: 767px) {
  header .navToggle.active span:nth-child(1) {
    transform: rotate(-45deg);
  }
}
@media screen and (max-width: 767px) {
  header .navToggle.active span:nth-child(2), header .navToggle.active span:nth-child(3) {
    transform: rotate(45deg);
  }
}
header .child-menu_content {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
}
@media screen and (max-width: 767px) {
  header .child-menu_content {
    visibility: visible;
    position: static;
    width: 100%;
    padding-top: 35px;
  }
}
header .child-menu_content__inner {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}
header .child-menu_content .button-default a {
  font-size: clamp(13px, 1.5vw, 16px);
  width: min(280px, 100%);
  padding: 10px 13px;
}
@media screen and (min-width: 1281px) {
  header .child-menu_content .button-default a {
    width: 280px;
  }
}
header .child-menu_contArea {
  width: 67%;
  background: #fff;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea {
    display: block;
    width: 100%;
    padding: 0;
  }
}
header .child-menu_contArea ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  width: 90%;
  max-width: 91rem;
}
@media screen and (max-width: 1280px) {
  header .child-menu_contArea ul {
    justify-content: space-between;
  }
}
header .child-menu_contArea__img {
  border: 1px solid #f1f1f1;
  padding: 10px 35px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea__img {
    padding: 10px;
    display: none;
  }
}
header .child-menu_contArea__img img {
  width: 100%;
}
header .child-menu_contArea__title {
  text-align: center;
  font-weight: 500;
  font-size: clamp(14px, 1vw, 15px);
  line-height: 1.3;
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea__title {
    line-height: unset;
    font-size: 13px;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea-textOnly_title {
    font-size: 14px;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea-textOnly li a {
    font-size: 13px;
    display: block;
    text-align: left;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  header .child-menu_contArea-textOnly li a::before {
    content: none;
  }
}
header .child-menu_Ttlarea {
  width: 33%;
  background: #f2f2f2;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  header .child-menu_Ttlarea {
    display: none;
  }
}
header .child-menu_Ttlarea__item {
  padding: 54px 72px;
  box-sizing: border-box;
}
@media screen and (max-width: 1280px) {
  header .child-menu_Ttlarea__item {
    width: 100%;
    padding: 54px 30px 0 20px;
  }
}
header .child-menu_ttlarea__head {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  header .child-menu_ttlarea__head {
    font-size: 27px;
    margin-bottom: 40px;
  }
}
header .child-menu_ttlarea__head .en {
  color: #454545;
  font-size: 13px;
  display: block;
  margin-top: 4px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  header .child-menu_ttlarea__head .en {
    margin-top: 4px;
  }
}
header .child-menu_contArea.child-menu_contArea-textOnly {
  display: block;
}
header .child-menu_contArea-textOnly ul {
  margin-bottom: 30px;
  justify-content: flex-start !important;
}
header .child-menu_contArea-textOnly li {
  margin-right: 50px;
}
header .child-menu_contArea-textOnly li a {
  font-weight: 400;
  display: flex;
  margin-left: -7px;
}
header .child-menu_contArea-textOnly li a:before {
  content: "・";
  color: #00c05d;
  font-size: 1.5rem;
}
header .child-menu_contArea-textOnly_title {
  margin-bottom: 10px;
}
header .has-child-product .child-menu_contArea ul li {
  width: 21%;
}
@media screen and (max-width: 1280px) {
  header .has-child-product .child-menu_contArea ul li {
    width: 23%;
  }
}
header .has-child-solution .child-menu_contArea ul li {
  width: calc((100% - 30px) / 4);
}
header .has-child-solution .child-menu_contArea__img {
  padding: 0;
}
header .has-child-case .child-menu_contArea ul li {
  width: 180px;
}
header .has-child-case .child-menu_contArea__img {
  padding: 0;
}
header .has-child-support .child-menu_contArea ul {
  justify-content: flex-start;
}
header .has-child-support .child-menu_contArea ul li {
  width: calc((100% - 30px) / 4);
}
header .has-child-support .child-menu_contArea__img {
  padding: 0;
}
header .has-child-company .child-menu_contArea ul {
  justify-content: flex-start;
}
header .has-child-company .child-menu_contArea__img {
  padding: 0;
}
@media screen and (max-width: 767px) {
  header.unpinned nav ul li a {
    color: #fff;
  }
}

.menu_hover_bg {
  display: none;
  transition: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  position: fixed;
}
.menu_hover_bg.hov {
  display: block;
}

section:first-of-type {
  padding-top: 99px;
}
@media screen and (max-width: 767px) {
  section:first-of-type {
    padding-top: 66px;
  }
}

@media screen and (max-width: 767px) {
  .has-child-contact {
    display: none;
  }
}
.has-child-contact .child-menu_contArea-textOnly li {
  width: 317px;
}
@media screen and (max-width: 767px) {
  .has-child-contact.header_cont__list--items {
    display: none;
  }
}
.has-child-contact.header_cont__list--items .header_cont__list--link {
  background: #00c05b;
  color: #fff;
  padding: 0 20px;
  font-size: clamp(11px, 1.2vw, 14px);
  box-sizing: border-box;
  border-radius: 10000px;
  width: 205px;
  height: 70px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1480px) {
  .has-child-contact.header_cont__list--items .header_cont__list--link {
    width: 13vw;
    min-width: 150px;
  }
}
.has-child-contact.header_cont__list--items .header_cont__list--link img {
  display: inline-block;
  margin-right: 3px;
}

.c-img-text.u-flex__col2 .c-list-ul,
.c-img-text.u-flex__col2 .c-list-ol {
  column-count: 2;
  column-gap: 4rem;
}
@media screen and (max-width: 767px) {
  .c-img-text.u-flex__col2 .c-list-ul,
.c-img-text.u-flex__col2 .c-list-ol {
    column-count: 1;
  }
}
.c-img-text.u-flex__col2 .c-list-ul.u-flex,
.c-img-text.u-flex__col2 .c-list-ol.u-flex {
  column-count: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0;
}
.c-img-text.u-flex__col2 .c-list-ul.u-flex li,
.c-img-text.u-flex__col2 .c-list-ol.u-flex li {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .c-img-text.u-flex__col2 .c-list-ul.u-flex li,
.c-img-text.u-flex__col2 .c-list-ol.u-flex li {
    width: 100%;
  }
}
.c-img-text__item figure {
  margin-bottom: 3rem;
}
.c-img-text__item p {
  margin-bottom: 3rem;
}
.c-img-text__item--text {
  text-align: left;
}
.c-img-text__item--title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3.5rem;
}

.c-linkbox__flex {
  display: flex;
  gap: 1rem 2rem;
  flex-wrap: wrap;
}

.c-textlink-box__v {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.c-textlink-box__h {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
}
.c-textlink__external {
  position: relative;
  padding-right: 2rem;
}
.c-textlink__external.bold {
  font-weight: bold;
}
.c-textlink__external::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background-image: url(../../assets/img/ico_link_green.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.c-textlink__within {
  position: relative;
  padding-right: 2rem;
}
.c-textlink__within.bold {
  font-weight: bold;
}
.c-textlink__within::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 13px;
  background: url(../../assets/img/ico_arrow_green.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5px;
  right: 4px;
  transition: 0.3s;
}
.c-textlink__within:hover::after {
  right: 0;
}
.c-textlink__file {
  position: relative;
  padding-left: 2.5rem;
}
.c-textlink__file::after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 21px;
  background: url(../../assets/img/ico_file_green.png);
  position: absolute;
  top: 3px;
  left: 0;
}
.c-textlink__file--line {
  position: relative;
}
.c-textlink__file--line::before {
  content: "";
  display: inline-block;
  width: calc(100% - 2.5rem);
  height: 1px;
  background: #333;
  position: absolute;
  bottom: -2px;
  left: 2.5rem;
}
.c-textlink__page {
  position: relative;
}
.c-textlink__page::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../../assets/img/ico_arrow_green.png) no-repeat;
  position: absolute;
  top: calc(50% + 2px);
  right: 1.5rem;
  transform: translateY(calc(-50% + 3px)) rotate(90deg);
}
.c-textlink__download {
  position: relative;
  padding-right: 2.5rem;
}
.c-textlink__download.bold {
  font-weight: bold;
}
.c-textlink__download::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 14px;
  background: url(../../assets/img/ico_floating_01_green.png) no-repeat;
  position: absolute;
  bottom: 3px;
  right: 0;
}
.c-textlink__text {
  color: #008c41 !important;
  border-bottom: 1px solid #008c41;
  padding: 0 2px;
}
.c-textlink__text--external {
  color: #008c41 !important;
  text-indent: 0;
  border-bottom: 1px solid #008c41;
  position: relative;
  padding: 0 2.5rem 0 2px;
  display: inline-block;
}
.c-textlink__text--external::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../../assets/img/ico_link_green.png) center/contain no-repeat;
  position: absolute;
  bottom: 6px;
  right: 2px;
}

.c-pagelink {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
}
.c-pagelink-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 2rem;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 100%;
    row-gap: 0;
  }
}
.c-pagelink-list__col15 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.c-pagelink-list__col15 li {
  width: 6.6666666667%;
  height: 8.5rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__col15 li {
    width: 33.3333333333%;
  }
}
.c-pagelink-list__col15 li:not(:nth-child(15n)) {
  border-right: 1px solid #f8f8f8;
}
.c-pagelink-list__col15 li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-indent: -2rem;
}
.c-pagelink-list li {
  border-left: 1px solid #ccc;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list li {
    width: 50%;
    border-left: none;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (max-width: 480px) {
  .c-pagelink-list li {
    width: 100%;
  }
}
.c-pagelink-list li:last-child {
  border-right: 1px solid #ccc;
}
.c-pagelink-list li a {
  display: inline-block;
  padding: 2rem 4.5rem 2rem 2rem;
  box-sizing: border-box;
}
@media screen and (max-width: 1280px) {
  .c-pagelink-list li a {
    padding: 2rem 3.5rem 2rem 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-pagelink-list li a {
    width: 100%;
    height: 100%;
  }
}
.c-pagelink-list__col3 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  margin-bottom: 10rem;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__col3 {
    margin-bottom: 5rem;
  }
}
.c-pagelink-list__col3 li {
  width: 33.3333333333%;
  background: #fff;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__col3 li {
    width: 50%;
  }
}
.c-pagelink-list__col3 li:not(:nth-child(5n)) {
  border-right: 1px solid #f8f8f8;
}
.c-pagelink-list__col3 li:nth-child(n+6) {
  border-top: 1px solid #f8f8f8;
}
.c-pagelink-list__col5 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.c-pagelink-list__col5 li {
  width: 20%;
  background: #fff;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__col5 li {
    width: 50%;
  }
}
.c-pagelink-list__col5 li:not(:nth-child(5n)) {
  border-right: 1px solid #f8f8f8;
}
.c-pagelink-list__col5 li:nth-child(n+6) {
  border-top: 1px solid #f8f8f8;
}
.c-pagelink-list__img {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__img {
    gap: 5px;
  }
}
.c-pagelink-list__img p {
  width: calc(100% + 4.5rem);
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .c-pagelink-list__img p {
    font-size: 14px;
  }
}
.c-pagelink-list__imgbox a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.8rem;
  height: 100%;
  box-sizing: border-box;
}
.c-pagelink-list__imgbox a img {
  width: fit-content;
}

.inner .c-pagelink {
  width: 100%;
}

.c-list-ol {
  list-style: decimal-leading-zero inside;
}
.c-list-ol li {
  padding-left: 2.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  text-indent: -3rem;
}
.c-list-ol li::marker {
  color: #008c41;
  font-weight: bold;
}
.c-list-ul {
  list-style: disc inside;
}
.c-list-ul li {
  padding-left: 3.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  text-indent: -2.5rem;
}
.c-list-ul li::marker {
  color: #008c41;
}
.c-list-ul li > ul {
  list-style: disc inside;
}
.c-list-ul li > ul li {
  padding-left: 3.5rem;
  text-indent: -2.5rem;
}
.c-list-ul li > ul li::marker {
  color: #999;
}
.c-list-ul__wide {
  list-style: disc inside;
}
.c-list-ul__wide li {
  padding-left: 3.5rem;
  text-indent: -2.5rem;
  margin-bottom: 2.8rem;
}
.c-list-ul__wide li::marker {
  color: #00c05b;
}
.c-list-ul__wide li > ul {
  list-style: disc inside;
  margin-top: 2rem;
}
.c-list-ul__wide li > ul li {
  padding-left: 3.5rem;
  text-indent: -2.5rem;
}
.c-list-ul__wide li > ul li::marker {
  color: #999;
}
.c-list-annotation li {
  font-size: 12px;
  line-height: 1.67;
  position: relative;
  padding-left: 2rem;
}
.c-list-annotation li::before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.c-list-label--item {
  height: 11.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.c-list-label--item.item01 {
  background-color: #00c05b;
}
.c-list-label--item.item02 {
  background-color: #008c41;
}
.c-list-label--item.item03 {
  background-color: #24593d;
}
.c-list-label--item p {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
}
.c-list-labelbox--item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.5rem;
  gap: 2rem;
  margin-bottom: 3rem;
}
.c-list-labelbox--item.item01 {
  background-color: #00c05b;
}
.c-list-labelbox--item.item02 {
  background-color: #008c41;
}
.c-list-labelbox--item.item03 {
  background-color: #24593d;
}
.c-list-labelbox--item p {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}
.c-list-labelbox--item__title {
  color: #008c41;
  font-weight: bold;
  margin-bottom: 3rem;
}

.c-vertical-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.c-vertical-list__item {
  padding: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  box-sizing: border-box;
}
.c-vertical-list__item.green {
  background-color: #00c05b;
}
.c-vertical-list__item.darkgreen {
  background-color: #008c41;
}
.c-vertical-list__item.deepgreen {
  background-color: #24593d;
}
.c-vertical-list__item.middlegreen {
  background-color: #006934;
}
.c-vertical-list__item p {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.c-flow-list {
  column-count: 2;
  gap: 4.5rem 4rem;
}
@media screen and (max-width: 767px) {
  .c-flow-list {
    column-count: 1;
  }
}
.c-flow-list--item {
  display: flex;
  align-items: center;
  gap: 4rem;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 4.5rem;
}
@media screen and (max-width: 767px) {
  .c-flow-list--item {
    gap: 2rem;
  }
}
.c-flow-list--line {
  position: relative;
}
.c-flow-list--line::after {
  content: "";
  display: block;
  width: 1px;
  height: 4.5rem;
  background: #f8f8f8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.c-movie {
  width: 100%;
  max-width: 1024px;
  margin: auto;
}
.c-movie iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.top-mv {
  height: 80vh;
}
@media screen and (max-width: 767px) {
  .top-mv {
    height: 60vh;
  }
}
.top-mv_slider__items {
  position: relative;
}
.top-mv_slider__items a {
  overflow: hidden;
}
.top-mv_slider__items.active-slide .top-mv__img img {
  animation: fadezoom 5s forwards;
}
.top-mv__summary {
  position: absolute;
  right: 15%;
  bottom: 4vw;
  background: rgba(255, 255, 255, 0.8);
  padding: 35px;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .top-mv__summary {
    width: 100%;
    min-width: unset;
    bottom: 0;
    right: 0;
    padding: 23px 5%;
  }
}
.top-mv__summary h2 {
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.3em;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .top-mv__summary h2 {
    font-size: 23px;
    margin-bottom: 12px;
  }
}
.top-mv__summary p {
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.7em;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .top-mv__summary p {
    font-size: 12px;
    margin-bottom: 12px;
  }
}
.top-mv__summary.min-width {
  min-width: 620px;
}
@media screen and (max-width: 767px) {
  .top-mv__summary.min-width {
    min-width: unset;
    width: 100%;
  }
}
.top-mv__btn {
  width: 160px;
  background: #fff;
  border: 1px solid #333;
  padding: 7px 15px;
  font-size: 16px;
  text-align: center;
  display: block;
  margin: 0 auto;
  position: relative;
}
.top-mv__btn:after {
  content: "";
  background-image: url(../img/ico_arrow_blk.png);
  width: 6px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: 14px;
}
@media screen and (max-width: 767px) {
  .top-mv__btn {
    padding: 3px 15px;
    width: 160px;
    font-size: 14px;
  }
}
.top-mv .bx-wrapper .bx-loading {
  background: none !important;
}
.top-mv .bx-wrapper .bx-viewport {
  height: 80vh !important;
}
@media screen and (max-width: 767px) {
  .top-mv .bx-wrapper .bx-viewport {
    height: 60vh !important;
  }
}
.top-mv .bx-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 80vh;
}
@media screen and (max-width: 767px) {
  .top-mv .bx-wrapper img {
    object-position: -57vw;
    height: 60vh;
  }
}
.top-mv .bx-wrapper .bx-pager {
  bottom: -80px;
}

.float-nav {
  position: fixed;
  right: -120px;
  bottom: 50%;
  transform: translateY(calc(50% - 60px));
  z-index: 500;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .float-nav {
    top: auto;
    bottom: 40px;
    right: auto;
    width: 100%;
    transform: translateY(0);
  }
}
.float-nav.show {
  right: 0;
}
.float-nav_inner {
  width: 120px;
}
@media screen and (max-width: 767px) {
  .float-nav_inner {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .float-nav_cont ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.float-nav_cont__items {
  width: 120px;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items {
    width: 34%;
    border-right: 1px solid #fff;
    border-bottom: none;
  }
}
.float-nav_cont__items a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #00c05b;
  color: #fff;
  text-align: center;
  height: 120px;
  font-size: 15px;
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items a {
    height: 45px;
    opacity: 1;
  }
}
.float-nav_cont__items a img {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items a p {
    font-size: 12px;
    line-height: 1.1em;
  }
}
.float-nav_cont__items a:hover {
  opacity: 0.7;
}
.float-nav_cont__items p {
  color: #fff;
}
.float-nav_cont__items img {
  display: block;
  margin-bottom: 3px;
  opacity: 0.7;
}
.float-nav_cont__items.download a {
  background: #008c41;
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items.download a img {
    width: 15px;
  }
}
.float-nav_cont__items.download a:hover img {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .float-nav_cont__items.contact {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items.contact a img {
    width: 16px;
  }
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items.estimate a img {
    width: 11px;
  }
}
@media screen and (max-width: 767px) {
  .float-nav_cont__items:last-of-type {
    border-right: none;
  }
}

#page_top {
  position: fixed;
  right: -120px;
  bottom: 50%;
  transform: translateY(calc(50% + 121px));
  background: #000;
  opacity: 1;
  z-index: 500;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  #page_top {
    right: 0;
    bottom: 0px;
    width: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}
#page_top.show {
  right: 0;
}
#page_top a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  height: 120px;
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 767px) {
  #page_top a {
    width: 100%;
    height: 40px;
    font-size: 11px;
    line-height: 1.1em;
  }
}
#page_top p {
  color: #fff;
}
#page_top:hover {
  opacity: 0.7;
  transition: all 0.3s 0s ease;
}

.c-pagenavi {
  max-width: 128rem;
  width: 93%;
  margin: 12rem auto;
  padding: 4rem 5rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-pagenavi {
    padding: 3rem;
  }
}
.c-pagenavi * {
  box-sizing: border-box;
}
.c-pagenavi ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .c-pagenavi ul {
    flex-direction: column;
  }
}
.c-pagenavi ul li {
  width: 25%;
  padding-right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-pagenavi ul li {
    width: 100%;
  }
}
.c-pagenavi ul li.c-pagenavi__auto {
  width: auto;
}
.c-pagenavi p {
  text-align: right;
  margin-top: 2rem;
  padding-right: 2rem;
}

.inner .c-pagenavi {
  width: 100%;
}

.c-pagination__prev a, .c-pagination__next a, .c-pagination__prev, .c-pagination__next, .c-pagination ul li a, .c-pagination ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-pagination__prev, .c-pagination__next, .c-pagination ul li {
  width: 4rem;
  height: 4rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.c-pagination ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: calc(100% - 11rem);
}
.c-pagination ul li.current {
  background-color: #ccc;
}
.c-pagination ul li a {
  width: 100%;
  height: 100%;
}
.c-pagination ul li a:hover {
  background-color: #ccc;
}
.c-pagination__prev a, .c-pagination__next a {
  width: 100%;
  height: 100%;
}
.c-pagination__prev a:hover, .c-pagination__next a:hover {
  background-color: #ccc;
}
.c-pagination__prev img {
  transform: rotate(180deg);
}

.c-product-detail__box a {
  display: flex;
  background-color: #f8f8f8;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 767px) {
  .c-product-detail__box a {
    flex-direction: column;
  }
}
.c-product-detail__box__img {
  background-color: #fff;
  width: 30%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .c-product-detail__box__img {
    width: 100%;
  }
}
.c-product-detail__box__text {
  padding: 3rem 4rem;
  display: flex;
  width: 70%;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-product-detail__box__text {
    width: 100%;
  }
}
.c-product-detail__box__text > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.c-product-detail__box__text > div p:not([class]), .c-product-detail__box__text > div ul {
  flex-grow: 1;
}
.c-product-detail__box__text .align-right {
  margin-top: 3rem;
}

.c-product-table {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}
.c-product-table tr:first-of-type th {
  background: #999;
}
.c-product-table tr:not(:first-child) {
  border-bottom: 1px solid #ccc;
}
.c-product-table tr th, .c-product-table tr td {
  vertical-align: middle;
  padding: 15px;
}
.c-product-table tr th {
  font-weight: bold;
}
.c-product-table tr td {
  text-align: left;
}
.c-product-table tr > *:nth-child(odd) {
  background: #f8f8f8;
}
.c-product-table.c-table__2col th, .c-product-table.c-table__2col td {
  width: 50%;
}
.c-product-table.c-table__3col th {
  width: 30%;
}
.c-product-table.c-table__3col td {
  width: 35%;
}
.c-product-table.align-center th, .c-product-table.align-center td {
  text-align: center !important;
}

.c-list-tile {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 62rem;
}
@media screen and (max-width: 767px) {
  .c-list-tile {
    width: 100%;
  }
}
.c-list-tile__item {
  width: 14rem;
  height: 10rem;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px) {
  .c-list-tile__item {
    width: calc((100% - 6rem) / 4);
  }
}
@media screen and (max-width: 767px) {
  .c-list-tile__item {
    width: calc((100% - 2rem) / 2);
  }
}

section.block .inner {
  width: 1280px;
  margin: 0 auto;
  padding: 100px 0;
  position: relative;
}
@media screen and (max-width: 1280px) {
  section.block .inner {
    width: 100%;
    padding: 100px 30px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 767px) {
  section.block .inner {
    width: 93%;
    margin: 0 auto;
    padding: 70px 0;
  }
}
section.block .inner.bg-about {
  max-width: 1720px;
  width: 100%;
  display: block;
}
@media screen and (max-width: 767px) {
  section.block .inner.bg-about {
    margin-bottom: 0;
  }
}

.holiday {
  margin-bottom: 40px;
}

.block-topics_list__items a {
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f2f2;
  padding: 15px 35px;
}
@media screen and (max-width: 767px) {
  .block-topics_list__items a {
    display: block;
  }
}
.block-topics_list__items:last-of-type a {
  border-bottom: 1px solid #f2f2f2;
}
.block-topics_list__date {
  width: 7.5rem;
  margin-right: 20px;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .block-topics_list__date {
    display: inline-block;
    margin-right: 6px;
  }
}
.block-topics_list__tag {
  margin-right: 20px;
  background: #333;
  color: #fff;
  box-sizing: border-box;
  width: 150px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .block-topics_list__tag {
    display: inline-block;
    line-height: 1.9em;
    width: auto;
    font-size: 11px;
    padding: 0 16px;
    margin-top: 3px;
  }
}
.block-topics_list__cont {
  width: calc(100% - 26.5rem);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .block-topics_list__cont {
    width: 100%;
  }
}
.block-topics_list__cont p {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .block-topics_list__cont p {
    font-size: 13px;
    line-height: 1.7;
  }
}
.block-topics_list__cont .block-topics_list__newIcon + p {
  width: calc(100% - 56px);
}
.block-topics_list__newIcon {
  margin-right: 15px;
  padding: 0 5px;
  border: 1px solid #d42109;
  color: #d42109;
  text-align: center;
  width: 56px;
  font-size: 12px;
  line-height: 1.7;
}
.block-topics_list--arrow a {
  position: relative;
}
@media screen and (max-width: 767px) {
  .block-topics_list--arrow a {
    vertical-align: middle;
  }
}
@media screen and (max-width: 767px) {
  .block-topics_list--arrow a {
    padding: 15px 10px 15px 0;
  }
}
.block-topics_list--arrow a::after {
  position: absolute;
  background-image: url(../img/ico_arrow_blk.png);
  content: "";
  width: 6px;
  height: 10px;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.block-content__background {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 0;
  background-color: #f8f8f8;
  top: 0;
  height: calc(50% + 90px);
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}
@media screen and (max-width: 1280px) {
  .block-content__background {
    margin: 0 auto;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .block-content__background {
    width: 100vw;
  }
}
.block-content__background.product {
  height: 220px;
}
@media screen and (max-width: 767px) {
  .block-content__background.product {
    height: 149px;
  }
}
@media screen and (max-width: 480px) {
  .block-content__background.product {
    width: 100vw;
    margin: 0 -3.5%;
  }
}
.block-content__background.solution {
  height: 235px;
}
@media screen and (max-width: 767px) {
  .block-content__background.solution {
    height: 157px;
  }
}
@media screen and (max-width: 480px) {
  .block-content__background.solution {
    width: 100vw;
    margin: 0 -3.5%;
  }
}
.block-content__background.case {
  height: 235px;
}
@media screen and (max-width: 767px) {
  .block-content__background.case {
    height: 157px;
  }
}
.block-content__title {
  position: relative;
  text-align: center;
  line-height: 1.25;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .block-content__title {
    font-size: 27px;
    margin-bottom: 40px;
  }
}
.block-content__title .en {
  color: #454545;
  font-size: 16px;
  display: block;
  margin-top: 12px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .block-content__title .en {
    font-size: 13px;
    margin-top: 4px;
  }
}
.block-content__description {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .block-content__description {
    font-size: 13px;
    text-align: left;
  }
}

.block-pickup_content {
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .block-pickup_content {
    flex-wrap: wrap;
  }
}
.block-pickup_content__items {
  width: 620px;
  position: relative;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  box-sizing: border-box;
  transition: all 0.5s;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items img {
    width: 100%;
  }
}
.block-pickup_content__items:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  transition: all 0.5s;
  opacity: 1;
}
.block-pickup_content__items:hover a,
.block-pickup_content__items:hover img {
  opacity: 1;
}
.block-pickup_content__items--read {
  position: absolute;
  width: 234px;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items--read {
    width: 100%;
    position: static;
    padding: 20px 20px;
    box-sizing: border-box;
  }
}
.block-pickup_content__items--read h3 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items--read h3 {
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 1em;
  }
}
.block-pickup_content__items--read p {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items--read p {
    font-size: 13px;
    line-height: 1.7em;
    letter-spacing: 0.05em;
  }
}
.block-pickup_content__items--read.first {
  top: 35px;
  left: 42px;
}
.block-pickup_content__items--read.first h3,
.block-pickup_content__items--read.first p {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items--read.first h3,
.block-pickup_content__items--read.first p {
    color: #333;
  }
}
.block-pickup_content__items--read.second {
  top: 35px;
  right: 42px;
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items--read.second {
    background: rgba(255, 255, 255, 0.3);
  }
}
@media screen and (max-width: 767px) {
  .block-pickup_content__items:last-of-type {
    margin-bottom: 0;
  }
}

.block-product_content {
  position: relative;
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .block-product_content {
    margin-top: 50px;
  }
}
.block-product_content ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2.6666666667%;
}
@media screen and (max-width: 767px) {
  .block-product_content ul {
    gap: 2%;
  }
}
.block-product_content__items {
  width: 23%;
  margin-bottom: 35px;
}
@media screen and (max-width: 767px) {
  .block-product_content__items {
    width: 49%;
    margin-bottom: 10px;
  }
}
.block-product_content__items a {
  padding: 25px;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  box-sizing: border-box;
  display: block;
  transition: all 0.5s;
}
@media screen and (max-width: 767px) {
  .block-product_content__items a {
    padding: 18px;
  }
}
.block-product_content__items a:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  transition: all 0.5s;
  opacity: 1;
}
.block-product_content__items a:hover img {
  opacity: 1;
}
.block-product_content__items--img {
  margin-bottom: 20px;
  height: 180px;
}
@media screen and (max-width: 767px) {
  .block-product_content__items--img {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 480px) {
  .block-product_content__items--img {
    height: 90px;
  }
}
.block-product_content__items--img img {
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .block-product_content__items--img img {
    width: 100%;
  }
}
.block-product_content__items h3 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .block-product_content__items h3 {
    font-size: 14px;
  }
}

.block-solution_content {
  position: relative;
  margin-top: 115px;
}
@media screen and (max-width: 767px) {
  .block-solution_content {
    margin-top: 60px;
  }
}
.block-solution_content ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block-solution_content__items {
  width: calc((100% - 80px) / 3);
  margin-bottom: 35px;
}
@media screen and (max-width: 767px) {
  .block-solution_content__items {
    width: 49%;
    margin-bottom: 10px;
  }
}
.block-solution_content__items a {
  padding: 0;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  box-sizing: border-box;
  display: block;
  transition: all 0.5s;
}
.block-solution_content__items a:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  transition: all 0.5s;
  opacity: 1;
}
.block-solution_content__items a:hover img {
  opacity: 1;
}
.block-solution_content__items h3 {
  text-align: center;
  font-weight: bold;
  padding: 13px 0;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .block-solution_content__items h3 {
    font-size: 14px;
    padding: 9px 0;
  }
}
.block-solution_content__items--img {
  height: 150px;
}
@media screen and (max-width: 767px) {
  .block-solution_content__items--img {
    height: 65px;
  }
}
.block-solution_content__items--img img {
  display: block;
  margin: 0 auto;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .block-solution_content__items--img img {
    width: 100%;
  }
}
.block-solution_content__items:last-of-type, .block-solution_content__items:nth-last-of-type(2) {
  margin-bottom: 0;
}

.block-case {
  position: relative;
  margin: 0 auto;
  padding: 100px 0;
}
@media screen and (max-width: 767px) {
  .block-case {
    padding: 70px 0;
  }
}
.block-case_content {
  position: relative;
  margin-top: 115px;
}
@media screen and (max-width: 767px) {
  .block-case_content {
    margin-top: 100px;
  }
}
.block-case_content_slider {
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}
.block-case_content_slider .slick-slide {
  margin: 0 10px;
  background: #f8f8f8;
  padding: 0;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  box-sizing: border-box;
  display: block;
  transition: all 0.5s;
}
.block-case_content_slider .slick-slide:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  transition: all 0.5s;
  opacity: 1;
}
.block-case_content_slider .slick-slide:hover a,
.block-case_content_slider .slick-slide:hover img {
  opacity: 1;
}
.block-case_content_slider .slick-arrow {
  position: absolute;
  z-index: 50;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .block-case_content_slider .slick-arrow {
    top: -35px;
    width: 40px;
    height: 40px;
  }
}
.block-case_content_slider .slick-button-next {
  background-image: url(../img/button-block-case-next.png);
  right: 14%;
}
@media screen and (max-width: 767px) {
  .block-case_content_slider .slick-button-next {
    right: 6%;
  }
}
.block-case_content_slider .slick-button-prev {
  background-image: url(../img/button-block-case-prev.png);
  left: 14%;
}
@media screen and (max-width: 767px) {
  .block-case_content_slider .slick-button-prev {
    left: auto;
    right: 18%;
  }
}
.block-case_content__items {
  position: relative;
}
.block-case_content__items h3 {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .block-case_content__items h3 {
    margin-bottom: 9px;
  }
}
.block-case_content__items h3 span {
  font-size: 1rem;
  padding-left: 5px;
}
.block-case_content__items__newIcon {
  margin-right: 15px;
  padding: 0 5px;
  border: 1px solid #d42109;
  color: #d42109;
  text-align: center;
  width: 56px;
  font-size: 12px;
  line-height: 1.7;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
.block-case_content__items--img {
  height: 230px;
  overflow: hidden;
}
.block-case_content__items--img img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-case_content__items--desctiption {
  padding: 20px;
  margin-bottom: 30px;
  line-height: 1.7em;
}
@media screen and (max-width: 767px) {
  .block-case_content__items--desctiption {
    font-size: 13px;
    margin-bottom: 16px;
  }
}
.block-case_content__items--tags ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.block-case_content__items--tags ul li {
  margin-right: 3px;
  margin-bottom: 3px;
  color: #999;
  border: 1px solid #999;
  background: #fff;
  padding: 2px 3px 3px;
  font-size: 12px;
  line-height: 1em;
}
@media screen and (max-width: 767px) {
  .block-case_content__items--tags ul li {
    font-size: 11px;
  }
}
.block-case_content__items--tags ul li.gray {
  border: 1px solid #999;
  background: #999;
  color: #fff;
}

.c-introduction .inner {
  padding: 0;
}
.c-introduction .inner > p:first-child {
  margin-bottom: 2rem;
}
.c-introduction small {
  font-size: 14px;
}

.c-sentence {
  margin-bottom: 6rem;
}
.c-sentence:last-of-type {
  margin-bottom: 10rem;
}
.c-sentence .inner {
  padding: 0;
}
.c-sentence small {
  font-size: 14px;
}
.c-sentence h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .c-sentence h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.c-sentence h3 {
  font-size: clamp(16px, 0.9375vw, 18px);
  font-weight: bold;
  margin-bottom: 1rem;
}
.c-sentence ul, .c-sentence ol, .c-sentence p, .c-sentence div {
  margin-bottom: 2.5rem;
}
.c-sentence .u-indent {
  padding-left: 2.5rem;
}
.c-sentence .u-indent span {
  display: block;
  text-indent: -2.5rem;
}
.c-sentence__link {
  padding-left: 2rem;
  margin-bottom: 4rem;
  display: inline-block;
}
.c-sentence__iso--data :is(dt, dd) * {
  margin: 0;
}
.c-sentence__iso--data dt {
  width: 30rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .c-sentence__iso--data dt {
    width: 30%;
  }
}
.c-sentence__iso--data dd {
  width: calc(100% - 30rem);
}
@media screen and (max-width: 1280px) {
  .c-sentence__iso--data dd {
    width: 70%;
  }
}
.c-sentence__iso--img {
  margin-top: -4rem !important;
  margin-bottom: 10rem !important;
}
.c-sentence__iso--img figure {
  background: #f8f8f8;
  padding: 3rem 5rem;
}
@media screen and (max-width: 1280px) {
  .c-sentence__iso--img figure {
    padding: 3rem;
  }
}

.c-share__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.c-share__list li:first-child > iframe {
  width: 92px;
}

.slick-slider button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.c-tab__switch {
  max-width: 128rem;
  width: 93%;
  margin: auto;
}
.c-tab__switch ul {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .c-tab__switch ul {
    border-top: 1px solid #ccc;
  }
}
.c-tab__switch ul li {
  font-weight: bold;
  padding: 1.5rem 3rem;
  border: solid #ccc;
  border-width: 1px 1px 1px 0;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .c-tab__switch ul li {
    width: 50%;
    box-sizing: border-box;
    border-width: 0 1px 1px 0;
  }
}
.c-tab__switch ul li:hover, .c-tab__switch ul li.current {
  background: #e5f9ee;
}
.c-tab__switch ul li:has(a) {
  padding: 0;
}
.c-tab__switch ul li:has(a) a {
  display: inline-block;
  padding: 1.5rem 3rem;
}
@media screen and (max-width: 767px) {
  .c-tab__switch ul li:has(a) a {
    font-size: 14px;
    padding: 1rem 1.5rem;
  }
}

.inner .c-tab__switch {
  width: 100%;
}

.c-table {
  display: flex;
  flex-wrap: wrap;
  width: 93%;
  max-width: 128rem;
  margin: auto;
  border-top: 1px solid #ccc;
}
.c-table dt,
.c-table dd {
  padding: 3rem 4rem;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-table dt,
.c-table dd {
    padding: 2rem;
  }
}
.c-table dt {
  width: 30rem;
  background: #f8f8f8;
  font-weight: bold;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .c-table dt {
    width: 30%;
  }
}
.c-table dt.u-accent {
  color: #008c41;
}
.c-table dd {
  width: calc(100% - 30rem);
}
@media screen and (max-width: 1280px) {
  .c-table dd {
    width: 70%;
  }
}
.c-table dd.u-accent {
  font-weight: bold;
  background: #e5f9ee;
}
.c-table th,
.c-table td {
  padding: 3rem 4rem;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .c-table th,
.c-table td {
    padding: 2rem;
  }
}
.c-table th {
  width: 30rem;
  background: #f8f8f8;
  font-weight: bold;
}
@media screen and (max-width: 1280px) {
  .c-table th {
    width: 30%;
  }
}
.c-table th.u-accent {
  color: #008c41;
}
.c-table td {
  width: calc(100% - 30rem);
}
@media screen and (max-width: 1280px) {
  .c-table td {
    width: 70%;
  }
}
.c-table td.u-accent {
  font-weight: bold;
  background: #e5f9ee;
}
.c-table.ver02 dd {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.c-table.ver02 .c-table__dd--main, .c-table.ver02 .c-table__dd--sub {
  padding: 3rem 4rem;
  box-sizing: border-box;
}
.c-table.ver02 .c-table__dd--main {
  width: calc(100% - 30rem);
  border-right: 1px solid #fff;
}
.c-table.ver02 .c-table__dd--sub {
  width: 30rem;
  background: #f8f8f8;
}
.c-table-head {
  display: flex;
  height: 45px;
  background: #999;
}
.c-table-head__title, .c-table-head__content {
  padding: 1rem 4rem;
  font-weight: bold;
  box-sizing: border-box;
}
.c-table-head__title {
  width: 30rem;
  border-right: 1px solid #fff;
}
.c-table-head__content {
  width: calc(100% - 30rem);
}
.c-table-head.ver02 .c-table-head__content {
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.c-table-head.ver02 .c-table-head__content--main, .c-table-head.ver02 .c-table-head__content--sub {
  padding: 1rem 4rem;
  box-sizing: border-box;
}
.c-table-head.ver02 .c-table-head__content--main {
  width: calc(100% - 30rem);
  border-right: 1px solid #fff;
}
.c-table-head.ver02 .c-table-head__content--sub {
  width: 30rem;
}

.p-column-content .c-table {
  width: 100%;
}
.p-column-content .c-table dt,
.p-column-content .c-table dd {
  padding: 1.2rem 1.6rem;
}
.p-column-content .c-table dt {
  align-items: baseline;
  width: 20rem;
}
@media screen and (max-width: 1280px) {
  .p-column-content .c-table dt {
    width: 30%;
  }
}
.p-column-content .c-table dd {
  width: calc(100% - 20rem);
}
@media screen and (max-width: 1280px) {
  .p-column-content .c-table dd {
    width: 70%;
  }
}

.inner .c-table {
  width: 100%;
}

th.white {
  color: #fff;
}

.c-list-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-list-tag__item {
  line-height: 1;
}
.c-list-tag__item.industry {
  background: #d42109;
}
.c-list-tag__item.product {
  background: #00c05b;
}
.c-list-tag__item a {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
}

.c-tag ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-tag ul li {
  padding: 5px 7px;
  font-size: 14px;
  line-height: 1;
  border: 1px solid;
}
.c-tag__gray ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-tag__gray ul li {
  padding: 5px 7px;
  background-color: #999;
  color: #fff;
  font-size: 14px;
  line-height: 1;
}
.c-tag__gray ul li a {
  color: #fff;
}
.c-tag__white ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-tag__white ul li {
  padding: 5px 7px;
  border: 1px solid #999;
  background-color: #fff;
  color: #999;
  font-size: 14px;
  line-height: 1;
}
.c-tag__white ul li a {
  color: #999;
}
.c-tag__white--red ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-tag__white--red ul li {
  padding: 5px 7px;
  border: 1px solid #d42109;
  background-color: #fff;
  color: #d42109;
  font-size: 14px;
  line-height: 1;
}
.c-tag__white--red ul li a {
  color: #d42109;
}

.c-tag-span__red {
  padding: 0px 2px 1px;
  border: 1px solid #d42109;
  background-color: #fff;
  color: #d42109;
  font-size: 14px;
  line-height: 1;
  vertical-align: baseline;
  margin-right: 5px;
}

.c-text-align__center {
  text-align: center !important;
}
.c-text-align__left {
  text-align: left !important;
}
.c-text-align__right {
  text-align: right !important;
}

.va-tb {
  vertical-align: text-bottom !important;
  line-height: normal;
}

.c-textbox__line {
  padding: 3rem;
  border: 1px solid #f8f8f8;
  margin-top: 6rem;
}
.c-textbox__lineBottom {
  padding: 0 0 6rem 0;
  margin-bottom: 6rem;
  border-bottom: 1px solid #f8f8f8;
}
.c-textbox__lineTop {
  padding: 3rem 0 0 0;
  margin-top: 5rem;
  border-top: 1px solid #ccc;
}

.c-title__main, .c-title__h2 {
  font-size: clamp(26px, 1.978vw, 38px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}
.c-title__chap, .c-title__h3 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}
.c-title__chap--under, .c-title__h3--under {
  font-size: 3rem;
  line-height: 1.5;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .c-title__chap--under, .c-title__h3--under {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.c-title__sec, .c-title__h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}
.c-title__para, .c-title__h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}
#columnDetailPage .c-title__sec, #columnDetailPage .c-title__h4 {
  margin-bottom: 1.5rem;
}
#columnDetailPage .c-title__para, #columnDetailPage .c-title__h5 {
  margin-bottom: 1rem;
}
.c-title-num {
  display: flex;
  height: 93px;
  margin-bottom: 3rem;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .c-title-num {
    height: auto;
  }
}
.c-title-num__num {
  background-color: #008c41;
  font-size: 3rem;
  color: #fff;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
}
.c-title-num__text {
  background-color: #f8f8f8;
  font-size: 18px;
  font-weight: bold;
  color: #008c41;
  align-self: stretch;
  display: flex;
  align-items: center;
  width: calc(100% - 6rem);
  padding: 1rem 2.5rem;
}
.c-title-num__text.bg_white {
  background-color: #FFF;
}

.bg-about {
  background-image: url(../img/img_top_about_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

section.block .inner.bg-about {
  max-width: 1720px;
  width: 100%;
  margin-bottom: 100px;
}

.block-about {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1280px) {
  .block-about {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-about {
    width: 93%;
    flex-wrap: wrap;
  }
}
.block-about_items:first-of-type {
  width: 676px;
}
@media screen and (max-width: 1280px) {
  .block-about_items:first-of-type {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .block-about_items:first-of-type {
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .block-about_items:last-of-type {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .block-about_items:last-of-type {
    width: 100%;
  }
}
.block-about_items p {
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .block-about_items p {
    font-size: 13px;
    line-height: 1.7;
  }
}
.block-about_titleImg {
  margin-right: 60px;
}
@media screen and (max-width: 1280px) {
  .block-about_titleImg {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .block-about_titleImg {
    margin: 35px auto 0;
  }
}
@media screen and (max-width: 1280px) {
  .block-about_titleImg img {
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .block-about_titleImg img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-about .block-content__title {
    text-align: center;
    line-height: 1.3;
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .block-about .button-default a {
    margin: 0 auto !important;
  }
}

#aboutPage main + section.block {
  padding-top: 12rem;
}
@media screen and (max-width: 767px) {
  #aboutPage main + section.block {
    padding-top: 66px;
  }
}

.p-about-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8rem 4rem;
  margin-top: 10rem;
}
@media screen and (max-width: 767px) {
  .p-about-list {
    gap: 3rem 2%;
  }
}
.p-about-list__item {
  transition: 0.5s;
  text-align: center;
}
.p-about-list__item:nth-child(-n+2) {
  width: calc((100% - 4rem) / 2);
}
@media screen and (max-width: 767px) {
  .p-about-list__item:nth-child(-n+2) {
    width: 100%;
  }
}
.p-about-list__item:nth-child(n+3) {
  width: calc((100% - 8rem) / 3);
}
@media screen and (max-width: 767px) {
  .p-about-list__item:nth-child(n+3) {
    width: 49%;
  }
}
.p-about-list__item figure {
  margin-bottom: 1.5rem;
}
.p-about-list__item a {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 2.5rem;
}
.p-about-message__catch {
  background: #f5fdff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .p-about-message__catch {
    gap: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-about-message__catch {
    flex-direction: column;
    padding-top: 5vw;
  }
}
.p-about-message__catch--text {
  font-size: 3rem;
  line-height: 1.5;
  margin-top: -3rem;
  padding-left: 2rem;
}
@media screen and (max-width: 1280px) {
  .p-about-message__catch--text {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
@media screen and (max-width: 767px) {
  .p-about-message__catch--text {
    margin-top: 0;
    width: 100%;
  }
}
.p-about-message__catch figure {
  padding-top: 3rem;
}
@media screen and (max-width: 1280px) {
  .p-about-message__catch figure {
    width: 38vw;
  }
}
@media screen and (max-width: 767px) {
  .p-about-message__catch figure {
    width: 100%;
    text-align: center;
  }
}
.p-about-message__text .inner {
  padding: 0;
}
.p-about-message__text--content {
  padding: 9rem 9rem 3rem;
  background: #fff;
  column-count: 2;
  column-gap: 5rem;
  margin-top: -5rem;
  position: relative;
  text-align: justify;
}
@media screen and (max-width: 1280px) {
  .p-about-message__text--content {
    padding: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-about-message__text--content {
    column-count: 1;
  }
}
.p-about-message__text--ceo {
  text-align: right;
  padding-right: 9rem;
}
@media screen and (max-width: 1280px) {
  .p-about-message__text--ceo {
    padding-right: 3rem;
  }
}
.p-about-message__text--ceo--name {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1rem;
}
@media screen and (max-width: 1280px) {
  .p-about-message__text--ceo--name {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-corporate__data dt {
  width: 30rem;
}
@media screen and (max-width: 767px) {
  .p-about-corporate__data dt {
    width: 30%;
  }
}
.p-about-corporate__data dd {
  width: calc(100% - 30rem);
}
@media screen and (max-width: 767px) {
  .p-about-corporate__data dd {
    width: 70%;
  }
}
.p-about-corporate__data small {
  margin-top: 2rem;
  font-size: 12px;
  display: inline-block;
}
.p-about-history.u-pb100 {
  padding-bottom: 10rem;
}
.p-about-history .inner {
  padding: 0;
}
.p-about-history h2 {
  font-size: 3rem;
  text-align: center;
  margin: 6rem auto 3rem;
}
@media screen and (max-width: 1280px) {
  .p-about-history h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-history h2 small {
  font-size: 80%;
}
.p-about-history__list dt {
  width: 13.5rem;
}
.p-about-history__list dd {
  width: calc(100% - 13.5rem);
}
.p-about-history__parts {
  position: relative;
}
.p-about-history__parts--small {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  margin: 1rem auto -4rem;
  position: relative;
}
.p-about-history__parts--small::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #ccc;
  position: absolute;
  top: -50%;
  transform: translateY(50%);
}
.p-about-history__parts--small p {
  font-size: clamp(18px, 1.046vw, 20px);
  line-height: 1;
  display: inline-block;
  background: #ccc;
  padding: 1rem 2rem;
}
.p-about-history__parts--big {
  position: relative;
}
.p-about-history__parts--big::before {
  content: "";
  display: block;
  width: 1px;
  height: 27.5rem;
  background: #ccc;
  position: absolute;
  top: -12.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.p-about-history__parts--big p {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  max-width: 767px;
  width: 100%;
  margin: auto auto 6rem;
  padding: 2.5rem 0;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;
}
@media screen and (max-width: 1280px) {
  .p-about-history__parts--big p {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-history__parts--text {
  margin-left: 50%;
  font-size: clamp(18px, 1.046vw, 20px);
}
@media screen and (max-width: 1280px) {
  .p-about-history__parts--text {
    margin: -2rem 0 -3rem 50%;
  }
}
.p-about-history__parts--text p {
  display: inline-block;
  position: relative;
  padding-left: 12rem;
  font-weight: bold;
}
@media screen and (max-width: 1280px) {
  .p-about-history__parts--text p {
    padding-left: 8vw;
  }
}
@media screen and (max-width: 767px) {
  .p-about-history__parts--text p {
    padding-left: 6vw;
  }
}
@media screen and (min-width: 1281px) {
  .p-about-history__parts--text p br {
    display: none;
  }
}
.p-about-history__parts--text p::before {
  content: "";
  display: block;
  width: 11rem;
  height: 1px;
  background: #ccc;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1280px) {
  .p-about-history__parts--text p::before {
    width: 7vw;
  }
}
@media screen and (max-width: 767px) {
  .p-about-history__parts--text p::before {
    width: 5vw;
  }
}
.p-about-history#apresia {
  padding-top: 2.5rem;
  padding-bottom: 10rem;
}
.p-about-history#apresia dt, .p-about-history#apresia dd {
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .p-about-history#apresia dt, .p-about-history#apresia dd {
    padding: 2rem;
  }
}
.p-about-history#apresia dt {
  width: 30rem;
  background: #ccc;
  border-bottom: 1px solid #f8f8f8;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .p-about-history#apresia dt {
    width: 30%;
  }
}
.p-about-history#apresia dt:last-of-type {
  border-bottom: 1px solid #ccc;
}
.p-about-history#apresia dd {
  width: calc(100% - 30rem);
  background: #fff;
}
@media screen and (max-width: 1280px) {
  .p-about-history#apresia dd {
    width: 70%;
  }
}
.p-about-history.bg-gray .p-about-history__parts--small {
  margin-top: -1.5rem;
  padding: 7.5rem 0 3rem;
}
.p-about-koukoku__file .inner {
  padding-bottom: 0;
}
.p-about-koukoku__file h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .p-about-koukoku__file h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-koukoku__box {
  display: flex;
  gap: 4rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .p-about-koukoku__box {
    flex-direction: column;
    gap: 0;
  }
}
.p-about-koukoku__box--item {
  width: calc((100% - 4rem) / 2);
  align-self: baseline;
}
@media screen and (max-width: 767px) {
  .p-about-koukoku__box--item {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-about-koukoku__box--item:not(:first-child) {
    border-top: none;
  }
}
.p-about-koukoku__box--item dt {
  width: 134px;
}
.p-about-koukoku__box--item dd {
  width: calc(100% - 134px);
}
.p-about-location__block#loc-main {
  padding-top: 10rem;
}
.p-about-location__block .inner {
  padding: 0 0 6rem;
}
.p-about-location__block:last-of-type .inner {
  padding-bottom: 0;
}
.p-about-location__block h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .p-about-location__block h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-location__detail {
  display: flex;
  border: solid #ccc;
  border-width: 1px 0;
}
@media screen and (max-width: 767px) {
  .p-about-location__detail {
    flex-direction: column;
  }
}
.p-about-location__detail--name, .p-about-location__detail--access {
  padding: 3rem 4rem;
}
@media screen and (max-width: 767px) {
  .p-about-location__detail--name, .p-about-location__detail--access {
    padding: 2rem;
  }
}
.p-about-location__detail--name {
  background: #f8f8f8;
}
@media screen and (max-width: 767px) {
  .p-about-location__detail--name {
    border-bottom: 1px solid #ccc;
  }
}
.p-about-location__detail--name p {
  font-weight: bold;
}
.p-about-location__detail--access01 {
  margin-bottom: 3rem;
}
.p-about-publish {
  padding-top: 0 !important;
}
.p-about-publish:not(:first-of-type) .inner {
  padding: 6rem 0 0;
}
.p-about-publish h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .p-about-publish h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-about-publish__list {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem 4rem;
}
@media screen and (max-width: 767px) {
  .p-about-publish__list {
    gap: 3rem;
  }
}
.p-about-publish__list figure {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-about-publish__list .c-textlink__download {
  text-align: right;
  display: block;
  margin-top: 3rem;
}
.p-about-publish__item--wide {
  width: 100%;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.p-about-publish__item--wide.hover-action {
  display: flex;
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--wide {
    flex-direction: column;
  }
}
.p-about-publish__item--wide figure {
  width: 49%;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--wide figure {
    width: 100%;
  }
}
.p-about-publish__item--wide__info {
  width: 51%;
  padding: 4rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--wide__info {
    width: 100%;
    padding: 2rem;
  }
}
.p-about-publish__item--name {
  font-size: 2rem;
  font-weight: bold;
  color: #008c41;
  margin-bottom: 1rem;
}
@media screen and (max-width: 1280px) {
  .p-about-publish__item--name {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.p-about-publish__item--time {
  font-size: 12px;
  color: #ccc;
  padding-left: 2rem;
  margin-bottom: 2rem;
  position: relative;
}
.p-about-publish__item--time::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url("../../assets/img/ico_update.png");
  position: absolute;
  left: 0;
  top: 6px;
}
.p-about-publish__item--product {
  padding: 2rem 3rem;
  border: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--product {
    padding: 2rem;
  }
}
.p-about-publish__item--product.u-flex {
  display: flex;
  gap: 2%;
}
.p-about-publish__item--product.u-flex .c-list-ul {
  width: 49%;
}
.p-about-publish__item--half {
  width: calc((100% - 4rem) / 2);
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--half {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-about-publish__item--half figure {
    height: auto;
  }
}
.p-about-publish__item--half__info {
  padding: 4rem;
}
@media screen and (max-width: 1280px) {
  .p-about-publish__item--half__info {
    padding: 2rem;
  }
}

.p-case {
  padding-bottom: 10rem;
}
@media screen and (max-width: 767px) {
  .p-case {
    padding-bottom: 5rem;
  }
}
.p-case__inner {
  border: none !important;
  padding-bottom: 0 !important;
}
.p-case__main {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-case__main {
    flex-direction: column;
  }
}
.p-case__mv {
  flex-shrink: 0;
}
.p-case__title--small {
  color: #999;
  font-weight: bold;
  margin-bottom: 2rem;
}
.p-case__title h1 {
  margin-bottom: 3rem;
}
.p-case__title h1 + p {
  margin-bottom: 3rem;
}
.p-case__mv img {
  width: 100%;
}
.p-case__category {
  display: flex;
  gap: 5px;
}
.p-case__category .c-tag__white {
  border-bottom: none !important;
}
.p-case__content .u-col2 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
}
.p-case__content.flex .p-case__content__inner {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-case__content.flex .p-case__content__inner {
    flex-direction: column;
  }
}
.p-case__content.flex .p-case__content__inner .p-case__content__img {
  flex-shrink: 0;
}
.p-case__content.flex .p-case__content__inner .p-case__content__text {
  width: -webkit-fill-available;
  width: -moz-available;
}
.p-case__content.flex .p-case__content__inner.rev {
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .p-case__content.flex .p-case__content__inner.rev {
    flex-direction: column;
  }
}
.p-case__content.float .p-case__content__inner {
  overflow: hidden;
}
.p-case__content.float .p-case__content__inner > .left {
  float: left;
  margin: 0 3rem 3rem 0;
}
@media screen and (max-width: 767px) {
  .p-case__content.float .p-case__content__inner > .left {
    float: none;
  }
}
.p-case__content.float .p-case__content__inner > .right {
  float: right;
  margin: 0 0 3rem 3rem;
}
@media screen and (max-width: 767px) {
  .p-case__content.float .p-case__content__inner > .right {
    float: none;
  }
}
.p-case__content__img img {
  width: 100%;
}
.p-case__content__img figcaption {
  font-size: 14px;
  line-height: 1.5;
}
.p-case__content__text a {
  text-decoration: none !important;
}
.p-case__introduction {
  padding-bottom: 6rem;
  margin-bottom: 6rem;
  border-bottom: 1px solid #f8f8f8;
}
.p-case__introduction__name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.p-case__introduction__label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.p-case__introduction__label + ul {
  margin-bottom: 25px;
}
.p-case__introduction__label + ul.u-col2 {
  column-count: 2;
}
@media screen and (max-width: 767px) {
  .p-case__introduction__label + ul.u-col2 {
    column-count: 1;
  }
}
.p-case__document {
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.21);
}
.p-case__customer__title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.p-case__customer__box {
  background: #f8f8f8;
  padding: 5rem;
}
@media screen and (max-width: 767px) {
  .p-case__customer__box {
    padding: 4rem 3rem 3rem;
  }
}
.p-case__customer__box__title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media screen and (max-width: 767px) {
  .p-case__customer__box__title {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-case__customer__box__title h2 {
    margin: 0;
  }
}
.p-case__customer__box__title a {
  position: relative;
  padding-right: 2rem;
}
.p-case__customer__box__title a::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background-image: url(../../assets/img/ico_link_green.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.p-case__customer__box__content {
  display: flex;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-case__customer__box__content {
    flex-direction: column;
  }
}
.p-case__customer__box__content figure {
  flex-shrink: 0;
}
.p-case__customer__box__content figure img {
  width: 100%;
}
.p-case__customer__box__content table {
  width: 100%;
  border-bottom: 1px solid #ccc;
  border-collapse: collapse;
}
.p-case__customer__box__content table th, .p-case__customer__box__content table td {
  border-top: 1px solid #ccc;
  padding: 2rem;
  vertical-align: middle;
}
.p-case__customer__box__content table th {
  width: 30%;
  font-weight: bold;
  background-color: #ccc;
}
.p-case__customer__box__content table td {
  width: 70%;
}

.c-case__img--3col {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 767px) {
  .c-case__img--3col {
    flex-direction: column;
  }
}
.c-case__img--3col img {
  width: calc((100% - 2rem) / 3);
}
@media screen and (max-width: 767px) {
  .c-case__img--3col img {
    width: 100%;
  }
}

.p-column-category ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-end;
}
.p-column-category ul li {
  color: #999;
  font-size: 14px;
  line-height: 1;
  border: 1px solid #999;
  padding: 5px 7px;
}

.p-column-categories {
  max-width: 128rem;
  width: 93%;
  margin: 9rem auto auto;
}
.p-column-categories ul {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .p-column-categories ul {
    border-top: 1px solid #ccc;
  }
}
.p-column-categories ul li {
  padding: 1.5rem 3rem;
  border: solid #ccc;
  border-width: 1px 1px 1px 0;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .p-column-categories ul li {
    width: 50%;
    box-sizing: border-box;
    border-width: 0 1px 1px 0;
  }
}
.p-column-categories ul li:hover, .p-column-categories ul li.current {
  background: #e5f9ee;
}

.p-column-list__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8rem 2%;
}
@media screen and (max-width: 767px) {
  .p-column-list__wrap {
    gap: 4rem 4%;
  }
}
.p-column-list__wrap > li {
  width: 32%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  background: #f8f8f8;
}
@media screen and (max-width: 767px) {
  .p-column-list__wrap > li {
    width: 48%;
  }
}
.p-column-list__wrap > li:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  opacity: 1;
  transition: all 0.5s;
}
.p-column-list__text {
  padding: 2.5rem 3rem 4rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .p-column-list__text {
    padding: 2rem;
  }
}
.p-column-list__text .p-column-category {
  margin-top: auto;
}
.p-column-list__title {
  font-size: clamp(18px, 1.046vw, 20px);
  font-weight: bold;
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .p-column-list__title {
    margin-bottom: 2rem;
  }
}
.p-column-list__explanation {
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .p-column-list__explanation {
    margin-bottom: 3rem;
  }
}
.p-column-list__category {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

#columnDetailPage main {
  background: #f8f8f8;
  padding-top: 11rem;
}
@media screen and (max-width: 767px) {
  #columnDetailPage main {
    padding-top: 3rem;
  }
}

.p-column {
  display: flex;
  justify-content: space-between;
  width: 128rem;
  margin: 0 auto;
  padding: 1rem 0 10rem;
  position: relative;
  border-bottom: 1px solid #ccc;
}
@media screen and (max-width: 1280px) {
  .p-column {
    width: 93%;
  }
}
@media screen and (max-width: 767px) {
  .p-column {
    padding: 70px 0;
    flex-direction: column;
  }
}
.p-column-content {
  max-width: 82rem;
  width: 68%;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .p-column-content {
    max-width: none;
    width: 100%;
    margin-bottom: 5rem;
  }
}
.p-column-content__inner {
  background: #fff;
  padding: 5rem 6rem 6rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  .p-column-content__inner {
    padding: 4rem 3rem 4rem;
  }
}
.p-column-content__inner .p-column-category {
  margin-bottom: 3rem;
}
.p-column-content__inner .p-column-category ul {
  justify-content: flex-start;
}
.p-column-content__inner > p,
.p-column-content__inner > .p-column-title__chap,
.p-column-content__inner > .p-column-title__sec,
.p-column-content__inner > .p-column-img {
  margin-bottom: 4rem;
}
@media screen and (max-width: 767px) {
  .p-column-content__inner > p,
.p-column-content__inner > .p-column-title__chap,
.p-column-content__inner > .p-column-title__sec,
.p-column-content__inner > .p-column-img {
    margin-bottom: 3rem;
  }
}
.p-column-content__inner .p-column-img img {
  width: 100%;
}
.p-column-content__inner p strong {
  display: block;
}
.p-column-contents {
  padding: 2.5rem 3rem;
  margin-bottom: 5rem;
  background: #f8f8f8;
}
.p-column-contents__title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.p-column-title__main {
  font-size: clamp(26px, 1.978vw, 38px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}
.p-column-title__chap {
  font-size: clamp(18px, 1.25vw, 24px);
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  padding-bottom: 4px;
  margin: 4rem 0 3rem;
  position: relative;
}
.p-column-title__chap::before {
  content: "";
  display: block;
  width: 30%;
  max-width: 150px;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -2px;
  background: #00c05b;
}
.p-column-title__sec {
  font-size: clamp(18px, 1.046vw, 20px);
  font-weight: bold;
  border-left: 2px solid #00c05b;
  padding-left: 1rem;
  margin: 3rem 0 2rem;
}
.p-column-mv {
  margin-bottom: 4rem;
}
.p-column-sidebar {
  max-width: 36rem;
  width: 30%;
  padding-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-column-sidebar {
    width: 100%;
    max-width: none;
    padding-top: 0;
  }
}
.p-column-sidebar__title {
  font-size: clamp(18px, 1.354vw, 26px);
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  padding-bottom: 2rem;
  position: relative;
}
.p-column-sidebar__title::before {
  content: "";
  display: block;
  width: 30%;
  height: 2px;
  background: #00c05b;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.p-column-sidebar__column {
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.p-column-sidebar__column li {
  border-bottom: 1px solid #ccc;
}
.p-column-sidebar__column li a {
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 1rem;
}
.p-column-sidebar__column__img {
  width: 8rem;
}
.p-column-sidebar__column__title {
  width: calc(100% - 10.5rem);
}
.p-column-sidebar__textlink {
  margin-bottom: 5rem;
}
.p-column-sidebar__textlink li {
  border-bottom: 1px solid #ccc;
  position: relative;
}
.p-column-sidebar__textlink li::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border: solid #008c41;
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  right: 1rem;
  top: 50%;
}
.p-column-sidebar__textlink li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem 2rem 1rem;
  box-sizing: border-box;
}
.p-column-sidebar__bnr {
  margin-bottom: 3rem;
  text-align: center;
}
.p-column-sidebar__bnr img {
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.07);
  height: auto;
  transition: all 0.5s;
}
.p-column-sidebar__bnr img:hover {
  transform: scale(1.03);
}
.p-column-sidebar__document {
  margin-bottom: 5rem;
}
.p-column-sidebar__document li {
  border-bottom: 1px solid #ccc;
  position: relative;
}
.p-column-sidebar__document li::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border: solid #008c41;
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  right: 1rem;
  top: 50%;
}
.p-column-sidebar__document li a {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 2rem 3rem 2rem 1rem;
  box-sizing: border-box;
}
.p-column-sidebar__document__img {
  width: 11rem;
}
.p-column-sidebar__document__content {
  width: calc(100% - 12rem);
}
.p-column-sidebar__document__title {
  font-weight: bold;
}
.p-column-related__title {
  color: #00c05b;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.p-column-related__list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.p-column-related__list li + li {
  padding-top: 2rem;
  border-top: 1px solid #ccc;
}
.p-column-related__list li a {
  display: flex;
  justify-content: space-between;
}
.p-column-related__list li a figure {
  width: 30%;
}
.p-column-related__list--title {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.8rem;
}
.p-column-related__list--text {
  width: calc(70% - 2rem);
}
.p-column-share__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.p-event__close {
  border: 1px solid #d42109;
  text-align: center;
  color: #d42109;
  padding: 3rem;
  font-weight: bold;
  font-size: clamp(24px, 1.562vw, 30px);
}

.bg-faq {
  position: absolute;
  width: 100%;
  max-width: 1600px;
}
.bg-faq img {
  width: 100%;
  max-width: 1600px;
}

.block-faq_content {
  box-sizing: border-box;
  position: absolute;
  top: 50px;
  right: 0;
  width: 56%;
  padding: 80px;
  background: #f8f8f8;
}
@media screen and (max-width: 767px) {
  .block-faq_content {
    position: static;
    width: 100%;
    padding: 40px 25px;
  }
}
.block-faq_content__inner {
  width: 100%;
  max-width: 787px;
  margin-top: 60px;
}
.block-faq_content__inner:first-of-type {
  margin-top: 0;
}
.block-faq_content__title {
  margin-bottom: 25px;
  padding-left: 8px;
  border-left: 1px solid #333;
  font-weight: bold;
  font-size: 18px;
}
.block-faq_content__list {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
}
@media screen and (max-width: 767px) {
  .block-faq_content__list {
    justify-content: space-between;
    gap: 0;
  }
}
.block-faq_content__list.col2 .block-faq_content__items {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .block-faq_content__list.col2 .block-faq_content__items {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-faq_content__list.col2 .block-faq_content__items img {
    width: 100%;
  }
}
.block-faq_content__items {
  width: calc((100% - 34px) / 3);
}
@media screen and (max-width: 767px) {
  .block-faq_content__items {
    width: 49%;
    margin-bottom: 10px;
  }
}
.block-faq_content__items a {
  box-sizing: border-box;
  display: block;
  padding: 0;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  transition: all 0.5s;
}
.block-faq_content__items a:hover {
  box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
  opacity: 1;
  transition: all 0.5s;
}
.block-faq_content__items a:hover img {
  opacity: 1;
}
.block-faq_content__img {
  width: 100%;
}
.block-faq_content__img img {
  width: 100%;
}
.block-faq_content__name {
  padding: 5px 0;
  background: #000;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .block-faq_content__name {
    padding: 2px 0;
    font-size: 13px;
  }
}
.block-faq_content__name p {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .block-faq_titleImg {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .block-faq_titleImg img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .block-faq .block-content__title {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .block-faq .button-default a {
    margin: 0 auto !important;
  }
}

.p-faq-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 2rem;
}
.p-faq-list__item {
  border: 1px solid #ccc;
}
.p-faq-list__item dt, .p-faq-list__item dd {
  display: flex;
  align-items: center;
  padding: 2.5rem;
  gap: 2rem;
}
@media screen and (max-width: 767px) {
  .p-faq-list__item dt, .p-faq-list__item dd {
    padding: 1rem;
  }
}
.p-faq-list__item dt i, .p-faq-list__item dd i {
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  width: 4rem !important;
  height: 4rem !important;
  display: block;
  text-align: center;
  padding-top: 0.5rem;
  box-sizing: border-box;
  margin: 0 0 auto 0;
}
.p-faq-list__item dt span, .p-faq-list__item dd span {
  width: calc(100% - 7rem);
}
.p-faq-list__item dt {
  font-weight: bold;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-faq-list__item dt {
    padding-right: 55px;
  }
}
.p-faq-list__item dt i {
  background: #00c05b;
}
.p-faq-list__item dt::before {
  content: "";
  display: block;
  width: 4rem;
  height: 2px;
  background: #ccc;
  position: absolute;
  right: 25px;
}
@media screen and (max-width: 767px) {
  .p-faq-list__item dt::before {
    width: 3rem;
    right: 20px;
  }
}
.p-faq-list__item dt::after {
  content: "";
  display: block;
  width: 2px;
  height: 4rem;
  background: #ccc;
  position: absolute;
  right: 43px;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .p-faq-list__item dt::after {
    height: 3rem;
    right: 33px;
  }
}
.p-faq-list__item dd {
  background-color: #f8f8f8;
  border-top: 1px solid #ccc;
}
.p-faq-list__item dd i {
  background-color: #999;
}
.p-faq-list__item dl.open dt::after {
  transform: rotate(90deg);
}
.p-faq-bnr {
  width: fit-content;
  margin: auto;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.p-faq-bnr a {
  display: flex;
}
.p-faq-bnr.bnr_left {
  margin-left: 0;
}

.c-faq-anchorlink-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  align-items: normal;
  border-left: 1px solid #ccc;
  box-sizing: border-box;
}
.c-faq-anchorlink-list > li {
  width: 20%;
  box-sizing: border-box;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: table;
  margin-bottom: -1px;
}
@media screen and (max-width: 767px) {
  .c-faq-anchorlink-list > li {
    width: 100%;
  }
}
.c-faq-anchorlink-list > li a {
  display: table-cell;
  vertical-align: middle;
  padding: 2rem 5rem 2rem 3rem;
  font-weight: bold;
  line-height: 1.2;
}
.c-faq-anchorlink-list span {
  display: inline-block;
  margin: auto;
}

.p-industry-wrap {
  padding: 4rem 3rem;
  margin-top: 5rem;
}
.p-industry-wrap.border {
  border: 2px solid #ccc;
}

.youtube-contents {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  height: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.youtube-contents iframe {
  width: 100%;
}

.p-industry-bnr-column {
  background-color: #f8f8f8;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.p-industry-bnr-column a {
  display: block;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  align-items: normal;
}
.p-industry-bnr-column figure {
  width: 384px;
}
@media screen and (max-width: 767px) {
  .p-industry-bnr-column figure {
    width: 100%;
  }
}
.p-industry-bnr-column figure img {
  width: 100%;
  height: auto;
}
.p-industry-bnr-column__info {
  width: calc(100% - 384px);
  box-sizing: border-box;
  padding: 3rem 3rem 6rem 3rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-industry-bnr-column__info {
    width: 100%;
  }
}
.p-industry-bnr-column .c-textlink__within {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
}

.p-industry-cta h3 {
  font-size: 30px;
}
@media screen and (max-width: 767px) {
  .p-industry-cta h3 {
    font-size: 20px;
  }
}

.p-industry-cta1 h3 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .p-industry-cta1 h3 {
    font-size: 16px;
  }
}

.p-inquiry h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .p-inquiry h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-inquiry p {
  text-align: center;
}
.p-inquiry .u-small {
  font-size: 12px;
  text-align: left;
}
.p-inquiry__section01 {
  padding-bottom: 10rem;
}
.p-inquiry__section01 .u-flex__col4 {
  margin-top: 5rem;
}
.p-inquiry__section01__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.p-inquiry__section01__item a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 5rem 2rem 4rem;
  box-sizing: border-box;
}
.p-inquiry__section01__item h3 {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 2rem;
}
@media screen and (max-width: 1280px) {
  .p-inquiry__section01__item h3 {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.p-inquiry__section02 {
  background: #f8f8f8;
  padding-bottom: 10rem;
}
.p-inquiry__section02 .inner {
  padding: 9rem 0 5rem;
}
.p-inquiry__section02__item {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem;
  background: #fff;
}
@media screen and (max-width: 1280px) {
  .p-inquiry__section02__item {
    padding: 3rem;
  }
}
.p-inquiry__section02__item figure {
  margin-bottom: 2rem;
}
.p-inquiry__section02__item h3 {
  font-size: clamp(18px, 1.046vw, 20px);
  font-weight: bold;
}
@media screen and (min-width: 1281px) {
  .p-inquiry__section02__item h3 br {
    display: none;
  }
}
.p-inquiry__section02__item h4 {
  font-weight: bold;
  font-size: clamp(16px, 0.9375vw, 18px);
}
.p-inquiry__section02__item--box {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  width: 100%;
  box-sizing: border-box;
}
.p-inquiry__section02__item--box a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 3.5rem 2rem;
  box-sizing: border-box;
}

#newsDetailPage main {
  background-color: #f8f8f8;
  padding-top: 10.7rem;
}
#newsDetailPage main .c-breadcrumb {
  margin-bottom: 0;
}

.p-news-detail__note {
  font-size: 1.4rem;
}
.p-news-detail__inner {
  padding: 1rem 0 10rem;
  border-bottom: 1px solid #ccc;
  max-width: 128rem;
  width: 93%;
  margin: auto;
}
.p-news-detail__to {
  font-weight: bold;
  margin-bottom: 4rem;
}
.p-news-detail-content {
  background: #fff;
  padding: 9rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .p-news-detail-content {
    padding: 2rem;
  }
}
.p-news-detail-content .p-column-title__main {
  white-space: pre-wrap;
}
.p-news-detail-content h2 {
  font-size: 3rem;
  line-height: 1.5;
  margin-bottom: 3rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .p-news-detail-content h2 {
    font-size: 2rem;
  }
}
.p-news-detail-content h2:not(:first-child) {
  margin-top: 4rem;
}
.p-news-detail-content h2 span {
  display: block;
  font-size: 2rem;
}
.p-news-detail-content h3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5;
}
.p-news-detail-content > ul {
  list-style: disc inside;
}
.p-news-detail-content > ul li {
  padding-left: 3.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  text-indent: -2.5rem;
}
.p-news-detail-content > ul li::marker {
  color: #008c41;
}
.p-news-detail-content strong {
  font-weight: bold;
}
.p-news-detail-content p + p {
  margin-top: 1.5rem;
}
.p-news-detail-content a {
  color: #000;
  text-decoration: underline;
  transition: 0.3s;
}
.p-news-detail-content a:hover {
  text-decoration: none;
}
.p-news-detail-content .button-contact a {
  color: #fff;
  text-decoration: none;
}
.p-news-detail-content__date {
  color: #999;
  font-size: 14px;
  text-align: right;
  margin-top: 4rem;
  margin-bottom: 6rem;
}
.p-news-detail-content .c-table {
  width: 100%;
}
.p-news-detail__other {
  width: 93%;
  max-width: 128rem;
  margin: auto;
  padding-top: 6rem;
  padding-bottom: 10rem;
  border-bottom: 1px solid #ccc;
}
.p-news-detail__other h2 {
  margin-bottom: 3.5rem;
}
.p-news-detail__other ul {
  width: 100%;
  margin: 0 auto;
}
.p-news-detail__other ul:first-of-type {
  margin-bottom: 5.5rem;
}
.p-news-detail .button-wrap {
  margin-top: 5rem;
}
.p-news-detail .c-tag__white {
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 2rem;
}
.p-news-detail .p-news-detail-content__date {
  margin-top: 2rem;
}
.p-news-detail .l-table {
  min-width: 768px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-news-detail .l-table {
    width: max-content;
  }
}

h2.h2_error {
  font-size: 200px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  h2.h2_error {
    font-size: 100px;
  }
}
@media screen and (max-width: 767px) {
  h2.h2_error {
    font-size: 60px;
  }
}

.p-other-logo-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  align-items: normal;
  border: 1px solid #ccc;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  margin: auto auto 5rem auto;
}
.p-other-logo-list__item {
  width: 50%;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .p-other-logo-list__item {
    width: 100%;
  }
}
.p-other-logo-list__item:first-child {
  border-right: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .p-other-logo-list__item:first-child {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
.p-other-logo-list__item img {
  width: 100%;
  height: auto;
}

.p-other-mail {
  background-color: #f8f8f8;
  text-align: center;
  padding: 5rem 2rem;
  line-height: 1.4;
  margin-bottom: 8rem;
}

.p-products .c-cardbox__item .c-tag__white--red {
  position: absolute;
  bottom: 4rem;
}
.p-products .c-cardbox__item .align-right {
  margin-top: 5rem;
}
.p-products .c-cardbox__item--title span {
  display: block;
  font-size: 0.8em;
  line-height: 0.5;
  margin-top: 1rem;
}
.p-products .c-cardbox__item .c-list-ul {
  column-count: 1;
}
.p-products-strength .u-flex__col3--item.u-wide {
  width: 100%;
  background: #fcfcfc;
  padding: 4rem;
}

#productsDetailPage .p-faq .button-default a {
  width: 40rem;
}

.c-contact.u-bg__accent {
  padding: 3.5rem 0;
}

#productsCategoryPage .p-faq .button-default a {
  width: 40rem;
}
@media screen and (max-width: 767px) {
  #productsCategoryPage .p-faq .button-default a {
    width: 100%;
    max-width: 40rem;
  }
}

.p-nutanix-effects__list--item {
  display: flex;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-nutanix-effects__list--item {
    flex-direction: column;
  }
}
.p-nutanix-effects__list--item figure {
  width: 15%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-nutanix-effects__list--item figure {
    width: 100%;
  }
}
.p-nutanix-effects__list--text {
  width: 85%;
}
@media screen and (max-width: 767px) {
  .p-nutanix-effects__list--text {
    width: 100%;
  }
}
.p-nutanix-effects__list--title {
  font-size: 2rem;
  font-weight: bold;
}

.p-vmware-virtual__flexbox {
  display: flex;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-vmware-virtual__flexbox {
    flex-direction: column;
  }
}
.p-vmware-virtual__flexbox--text {
  width: 75%;
}
@media screen and (max-width: 767px) {
  .p-vmware-virtual__flexbox--text {
    width: 100%;
  }
}
.p-vmware-virtual__flexbox--img {
  width: 25%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-vmware-virtual__flexbox--img {
    width: 100%;
  }
}

.p-line-service {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.p-line-service li {
  display: flex;
  gap: 3rem;
}

.p-iamos__iot .u-flex__col2 {
  position: relative;
}
.p-iamos__iot .u-flex__col2--item {
  padding: 4rem 3rem;
}
.p-iamos__iot .u-flex__col2--item:not(.c-linebox) {
  background: #fcfcfc;
}
.p-iamos__iot .u-flex__col2::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 36px solid #00c05b;
  border-bottom: 64px solid transparent;
  border-top: 64px solid transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .p-iamos__iot .u-flex__col2::before {
    border-top: 36px solid #00c05b;
    border-right: 64px solid transparent;
    border-left: 64px solid transparent;
    transform: translate(-50%, -20%);
  }
}
.p-iamos__iot table {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #ccc;
  border-collapse: collapse;
  margin-bottom: 2.4rem;
}
.p-iamos__iot table th, .p-iamos__iot table td:nth-of-type(2) {
  background: #f8f8f8;
}
.p-iamos__iot table th, .p-iamos__iot table td {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}
.p-iamos__iot table th {
  font-weight: bold;
}
.p-iamos__iot table td:first-of-type {
  width: 10rem;
}
.p-iamos__products--item {
  padding: 4rem 3rem 3rem;
}
.p-iamos__products--item01 img {
  margin-left: 22rem;
}
@media screen and (max-width: 1280px) {
  .p-iamos__products--item01 img {
    margin: auto;
  }
}
.p-iamos__products--item02 .u-flex {
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-iamos__products--item02 .u-flex {
    flex-direction: column;
  }
}
.p-iamos__products--item02 img {
  margin-left: 4rem;
}
@media screen and (max-width: 1280px) {
  .p-iamos__products--item02 img {
    margin: auto;
  }
}
.p-iamos__products--item02 table {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}
.p-iamos__products--item02 table th, .p-iamos__products--item02 table td {
  vertical-align: middle;
}
.p-iamos__products--item02 table th {
  padding: 0 12px;
  background: #999;
  font-weight: bold;
  text-align: left;
  height: 3rem;
}
.p-iamos__products--item02 table th:first-of-type {
  width: 5rem;
}
.p-iamos__products--item02 table th:not(:first-of-type) {
  border-left: 1px solid #fff;
}
.p-iamos__products--item02 table th:last-of-type {
  width: 8rem;
}
.p-iamos__products--item02 table td {
  padding: 1rem 2rem;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.p-iamos__products--item02 table td:not(:nth-of-type(2)) {
  text-align: center;
  background-color: #fcfcfc;
}

.p-svo-benefit .c-cardbox__item--text {
  flex: none;
}
.p-svo-benefit .c-cardbox__item--text .c-list-ul {
  flex-grow: unset;
}
.p-svo-flow__list {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-gap: 4.5rem 4rem;
}
@media screen and (max-width: 767px) {
  .p-svo-flow__list {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}
.p-svo-flow__list--item {
  display: flex;
  align-items: center;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-svo-flow__list--item {
    gap: 2rem;
  }
}
.p-svo-flow__list--item:nth-child(1) {
  grid-row: 1/2;
  grid-column: 1/2;
}
.p-svo-flow__list--item:nth-child(2) {
  grid-row: 2/3;
  grid-column: 1/2;
}
.p-svo-flow__list--item:nth-child(3) {
  grid-row: 3/4;
  grid-column: 1/2;
}
.p-svo-flow__list--item:nth-child(4) {
  grid-row: 4/5;
  grid-column: 1/2;
}
.p-svo-flow__list--item:nth-child(5) {
  grid-row: 1/2;
  grid-column: 2/3;
}
.p-svo-flow__list--item:nth-child(6) {
  grid-row: 2/3;
  grid-column: 2/3;
}
.p-svo-flow__list--item:nth-child(7) {
  grid-row: 3/4;
  grid-column: 2/3;
}
.p-svo-flow__list--line {
  position: relative;
}
.p-svo-flow__list--line::after {
  content: "";
  display: block;
  width: 1px;
  height: 4.5rem;
  background: #f8f8f8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.p-hcnetconnect-feature__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  gap: 2rem;
  margin-bottom: 3rem;
}
.p-hcnetconnect-feature__item.item01 {
  background-color: #00c05b;
}
.p-hcnetconnect-feature__item.item02 {
  background-color: #008c41;
}
.p-hcnetconnect-feature__item.item03 {
  background-color: #24593d;
}
.p-hcnetconnect-feature__item p {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}
.p-hcnetconnect-feature__title {
  color: #008c41;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 3rem;
}
.p-hcnetconnect-scene figure {
  margin-bottom: 3rem;
}

.p-a3csim-lte__image {
  background-color: #f8f8f8;
  padding: 2rem;
  margin-bottom: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
@media screen and (max-width: 767px) {
  .p-a3csim-lte__image {
    flex-direction: column;
    gap: 0;
  }
}
.p-a3csim-lte__image .c-arrow__right {
  border-top-width: 55px;
  border-bottom-width: 55px;
}
@media screen and (max-width: 767px) {
  .p-a3csim-lte__image .c-arrow__right {
    transform: rotate(90deg);
  }
}

.p-safiego-customize__image figcaption {
  color: #0098a6;
  font-weight: bold;
}
.p-safiego-customize__image p {
  color: #fff;
  font-weight: bold;
  background: #0098a6;
  border-radius: 5px;
  width: 31.5rem;
  margin: auto;
}

.p-viengine-problem__solve--text {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-viengine-problem .c-linebox {
  margin-bottom: 1.5rem;
}

.p-knowbe4-training__list--label {
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
}
.p-knowbe4-training__list--label.train {
  background-color: #1eb5c0;
}
.p-knowbe4-training__list--label.phish {
  background-color: #e77c29;
}
.p-knowbe4-training__list--label.analyze {
  background-color: #90a9b6;
}

.p-knowbe4-function__list {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-gap: 4.5rem 4rem;
}
@media screen and (max-width: 767px) {
  .p-knowbe4-function__list {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}
.p-knowbe4-function__list--item {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-knowbe4-function__list--item {
    gap: 2rem;
  }
}
.p-knowbe4-function__list--item:nth-child(1) {
  grid-row: 1/2;
  grid-column: 1/2;
}
.p-knowbe4-function__list--item:nth-child(2) {
  grid-row: 2/3;
  grid-column: 1/2;
}
.p-knowbe4-function__list--item:nth-child(3) {
  grid-row: 3/4;
  grid-column: 1/2;
}
.p-knowbe4-function__list--item:nth-child(4) {
  grid-row: 4/5;
  grid-column: 1/2;
}
.p-knowbe4-function__list--item:nth-child(5) {
  grid-row: 1/2;
  grid-column: 2/3;
}
.p-knowbe4-function__list--item:nth-child(6) {
  grid-row: 2/3;
  grid-column: 2/3;
}
.p-knowbe4-function__list--item:nth-child(7) {
  grid-row: 3/4;
  grid-column: 2/3;
}
.p-knowbe4-function__list--item figure {
  width: 9rem;
}
.p-knowbe4-function__list--item div {
  width: calc(100% - 9rem);
}

.p-conferenceadapterex-feature__step li:not(:last-of-type) {
  position: relative;
}
.p-conferenceadapterex-feature__step li:not(:last-of-type)::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 30px solid #00c05b;
  border-bottom: 60px solid transparent;
  border-top: 60px solid transparent;
  position: absolute;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-conferenceadapterex-feature__step li:not(:last-of-type)::after {
    border-top: 30px solid #00c05b;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: none;
    top: unset;
    right: 50%;
    bottom: -3.5rem;
    transform: translateX(50%);
  }
}

.p-product-lineup {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .p-product-lineup {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .p-product-lineup__item {
    width: 100%;
  }
}

.p-xtreme-d-customize {
  display: grid;
  grid-template-columns: 20rem 20rem 20.7rem 20.7rem 20.7rem 20.9rem;
  grid-template-rows: 12rem 12rem 5rem 7rem;
  gap: 1rem;
}
@media screen and (max-width: 1280px) {
  .p-xtreme-d-customize {
    grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
  }
}
@media screen and (max-width: 767px) {
  .p-xtreme-d-customize {
    display: flex;
    flex-direction: column;
  }
}
.p-xtreme-d-customize__item {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-xtreme-d-customize__item {
    padding: 2rem;
  }
}
.p-xtreme-d-customize__item p {
  font-weight: bold;
}
.p-xtreme-d-customize__item.item01 {
  grid-row: 1/3;
  grid-column: 1/2;
  background-color: #000;
}
.p-xtreme-d-customize__item.item02 {
  grid-row: 1/2;
  grid-column: 2/3;
  background-color: #00c05b;
}
.p-xtreme-d-customize__item.item02 p {
  color: #fff;
  font-size: 2rem;
}
.p-xtreme-d-customize__item.item03 {
  grid-row: 1/2;
  grid-column: 3/4;
}
.p-xtreme-d-customize__item.item04 {
  grid-row: 1/2;
  grid-column: 4/5;
}
.p-xtreme-d-customize__item.item05 {
  grid-row: 1/2;
  grid-column: 5/6;
}
.p-xtreme-d-customize__item.item06 {
  grid-row: 1/2;
  grid-column: 6/7;
}
.p-xtreme-d-customize__item.item07 {
  grid-row: 2/3;
  grid-column: 2/3;
  background-color: #008c41;
}
.p-xtreme-d-customize__item.item07 p {
  color: #fff;
  font-size: 2rem;
}
.p-xtreme-d-customize__item.item08 {
  grid-row: 2/3;
  grid-column: 3/4;
}
.p-xtreme-d-customize__item.item09 {
  grid-row: 2/3;
  grid-column: 4/5;
}
.p-xtreme-d-customize__item.item10 {
  grid-row: 2/3;
  grid-column: 5/6;
}
.p-xtreme-d-customize__item.item11 {
  grid-row: 2/3;
  grid-column: 6/7;
}
.p-xtreme-d-customize__item.item12 {
  grid-row: 3/4;
  grid-column: 1/7;
}
.p-xtreme-d-customize__item.item13 {
  grid-row: 4/5;
  grid-column: 1/7;
}
.p-xtreme-d-customize__item.item13 ul {
  display: flex;
  gap: 5px;
}
@media screen and (max-width: 767px) {
  .p-xtreme-d-customize__item.item13 ul {
    flex-direction: column;
    width: 100%;
  }
}
.p-xtreme-d-customize__item.item13 ul li {
  padding: 1.5rem;
  border: 2px dashed #333;
  line-height: 1;
  font-weight: bold;
}
.p-xtreme-d-scenario__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}
.p-xtreme-d-scenario__item .c-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8rem;
}
.p-xtreme-d-scenario__item .c-label.c-label01 {
  background: #008c41;
}
.p-xtreme-d-scenario__item .c-label.c-label01 p {
  color: #fff;
}
.p-xtreme-d-scenario__item .c-label.c-label02 {
  background: #fff;
  border: 2px solid #333;
}
.p-xtreme-d-scenario__item .c-label.c-label02 p {
  color: #333;
}
.p-xtreme-d-scenario__item .c-label p {
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .p-xtreme-d-scenario__item .u-flex {
    flex-direction: row;
    gap: 2rem;
  }
}
.p-xtreme-d-scenario__item .u-flex__item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.p-xtreme-d-scenario__item .u-flex__item .c-label {
  flex-grow: 1;
}
.p-xtreme-d-scenario__annotation li {
  vertical-align: middle;
}
.p-xtreme-d-scenario__annotation .c-block {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  vertical-align: sub;
  box-sizing: border-box;
}
.p-xtreme-d-scenario__annotation .c-block.u-border__block {
  border: 1px solid;
}
.p-xtreme-d-subscription .c-cardbox__item ul {
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 3rem;
  flex-grow: 1;
}
.p-xtreme-d-subscription .c-cardbox__item p.align-left {
  flex-grow: 1;
}

.p-zscaler-cloud__img {
  width: fit-content;
  margin: auto;
  position: relative;
}
.p-zscaler-cloud__img figcaption {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: #00c05b;
  padding: 0 2rem;
  position: absolute;
  right: -130%;
  bottom: 1rem;
}
.p-zscaler-cloud__item {
  border: 3px solid #00c05b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.p-zscaler-cloud__item .marker {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: #00c05b;
  padding: 0 1px;
}
.p-zscaler-solution__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}
.p-zscaler-solution__item {
  width: calc((100% - 6px) / 7);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .p-zscaler-solution__item {
    width: calc((100% - 2px) / 3);
  }
}
.p-zscaler-solution__item.item01 {
  background-color: #9f9f9f;
}
.p-zscaler-solution__item.item02 {
  background-color: #f8f8f8;
}
.p-zscaler-solution__item.item03 {
  background-color: #eee;
}
.p-zscaler-solution__item.item04 {
  background-color: #ddd;
}
.p-zscaler-solution__item.item05 {
  background-color: #f1f1f1;
}
.p-zscaler-solution__item.item06 {
  background-color: #949494;
}
.p-zscaler-solution__item.item07 {
  background-color: #858383;
}
.p-zscaler-solution__item.item08 {
  background-color: #e8e8e8;
}
.p-zscaler-solution__item.item09 {
  background-color: #e3e0e0;
}
.p-zscaler-solution__item.item10 {
  background-color: #bbb;
}
.p-zscaler-solution__item.item11 {
  background-color: #ccc;
}
.p-zscaler-solution__item.item12 {
  background-color: #aaa;
}

.p-cloudsirt-column img {
  max-width: none;
  width: 100%;
}

.p-lcx-case {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
}
.p-lcx-case__img.img01 {
  grid-row: 1/2;
  grid-column: 1/3;
}
.p-lcx-case__img.img02 {
  grid-row: 2/3;
  grid-column: 1/2;
}
.p-lcx-case__img.img03 {
  grid-row: 2/3;
  grid-column: 2/3;
}

.p-aruba-tool__box--left {
  display: flex;
  gap: 3rem;
}
.p-aruba-tool__box--left figure {
  width: max-content;
}

.p-conexiometer-step {
  box-sizing: border-box;
}
.p-conexiometer-step ul {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2.5rem 2rem 2rem;
}

.p-amnimo-spec .no-border {
  border: none !important;
}
.p-amnimo-spec .border-top-left {
  border: solid #ccc;
  border-width: 1px 0 1px 1px;
}

.p-pureflow-ws1-specifications .no-border {
  border: none !important;
}
.p-pureflow-ws1-specifications .border-top {
  border-top: 1px solid #ccc;
}

@media screen and (max-width: 767px) {
  #accesspoint figure {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-radwin2000__reverse {
    flex-direction: column-reverse !important;
    gap: 4rem;
  }
}
.p-radwin2000__reverse .u-flex__col2--item,
.p-radwin2000__reverse figure {
  text-align: center;
}

.after-bg-gray::before {
  background-color: #999999 !important;
}

.after-fs-16::before {
  font-size: 1.6rem !important;
}

.after-zi-1::before {
  z-index: 1;
}

.ai-center {
  align-items: center;
}

.ai-flex-start {
  align-items: flex-start;
}

.b-shadow {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}

.bb-white {
  border-bottom: 1px solid #FFFFFF !important;
}

.bc-lightgray {
  background-color: #ccc !important;
}

.bi {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bi-factory-1 {
  background-image: url(../../assets/img/solution/img_solution_social_factory_bg_1.jpg);
}

.bnr_manga {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto 5rem auto;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.bnr_manga .hover-action {
  display: block;
}

.cc-auto {
  column-count: auto !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}
@media screen and (max-width: 767px) {
  .d-flex {
    flex-direction: column;
  }
  .d-flex__row {
    flex-direction: row;
  }
}

.flex-img {
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .flex-img {
    width: 100% !important;
  }
}
.flex-img.width-31-5 {
  width: 31.5%;
}
.flex-img.width-48-5 {
  width: 48.5%;
}
.flex-img > img {
  display: block;
  height: auto;
  width: 100%;
}

.flex-txt {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .flex-txt {
    width: auto;
  }
}
.flex-txt.btn > p.p-link-r {
  align-self: flex-end;
  display: flex;
  margin-top: auto;
}
@media screen and (max-width: 767px) {
  .flex-txt.btn > p.p-link-r {
    margin-top: 2rem;
  }
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.fw-medium {
  font-weight: 500;
}

.height-8 {
  height: 8rem !important;
}

.img-center {
  display: block;
  height: auto;
  margin: 0 auto;
  width: auto;
}

.jc-center {
  justify-content: center;
}

.jc-flex-end {
  justify-content: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.lh-9 {
  line-height: 0.9 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-20 {
  line-height: 2 !important;
}

.m-auto {
  margin: auto;
}

.mh-230 {
  max-height: 230px;
}

.ml-auto {
  margin-left: auto;
}

.ml-flex {
  margin-left: 4rem;
}
@media screen and (max-width: 767px) {
  .ml-flex {
    margin-top: 3.6rem;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .mq-down-fd-column {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .mq-down-mb-5 {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  .mq-down-prl-1-2 {
    padding: 1rem 0 2rem;
  }
}

.mw-860 {
  max-width: 860px;
}

.mw-1000 {
  max-width: 1000px;
}

.p-itsolution-dl-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  align-items: normal;
  margin-bottom: 2rem;
}
.p-itsolution-dl-list dt {
  width: 70px;
  text-align: left;
}
.p-itsolution-dl-list dd {
  width: calc(100% - 70px);
  line-height: 1.5;
}
.p-itsolution-dl-list2 {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: flex-start;
  align-items: normal;
  margin: 0 auto;
  width: 88%;
}
@media screen and (max-width: 767px) {
  .p-itsolution-dl-list2 {
    width: 100%;
  }
}
.p-itsolution-dl-list2 dt {
  width: 110px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .p-itsolution-dl-list2 dt {
    margin: auto;
    padding-bottom: 2rem;
  }
}
.p-itsolution-dl-list2 dd {
  width: calc(100% - 110px);
}
@media screen and (max-width: 767px) {
  .p-itsolution-dl-list2 dd {
    width: 100%;
  }
}
.p-itsolution-wrap {
  width: 100%;
  max-width: 960px;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 36px 93px;
  margin-top: 2rem;
}
.p-itsolution-cta .button-default a {
  width: 400px;
  height: 80px;
}
@media screen and (max-width: 767px) {
  .p-itsolution-cta .button-default a {
    width: 100%;
  }
}
.p-itsolution-cta .button-default a::before {
  border-bottom: 80px solid #333;
}

.p-itsolutionFillTit {
  background-color: #03070A;
  color: #FFFFFF;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0.8rem 1.2rem;
}

.p-itsolutionFourColumn {
  display: flex;
}
.p-itsolutionFourColumn > li {
  width: calc((100% - 14rem) / 5);
}
.p-itsolutionFourColumn > li:nth-child(n+2) {
  margin-left: 3.5rem;
}
.p-itsolutionFourColumn > li > img {
  display: block;
  height: auto;
  margin: 0 auto;
  width: auto;
}
@media screen and (max-width: 767px) {
  .p-itsolutionFourColumn {
    flex-direction: column;
  }
  .p-itsolutionFourColumn > li {
    width: auto;
  }
  .p-itsolutionFourColumn > li:nth-child(n+2) {
    margin-left: 0;
    margin-top: 3.5rem;
  }
}

.p-itsolutionMovie {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 860px;
  height: 472.5px;
}
@media screen and (max-width: 767px) {
  .p-itsolutionMovie {
    width: 90vw;
    height: 51.75vw;
  }
}
.p-itsolutionMovie > iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.p-itsolutionOverlay::before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: "";
  left: 50%;
  max-width: 1920px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-itsolutionOverlay::before {
    left: inherit;
    right: 0;
    width: 100vw;
    z-index: 100;
    transform: inherit;
  }
}

.p-itsolutionRightTriangle::after {
  background-color: #f8f8f8;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  content: "";
  height: 12.8rem;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3.6rem;
}
@media screen and (max-width: 767px) {
  .p-itsolutionRightTriangle::after {
    content: none;
  }
}

.p-itsolutionTriangle::before {
  background-color: white;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  content: "";
  height: 4rem;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 12rem;
}
@media screen and (max-width: 767px) {
  .p-itsolutionTriangle::before {
    height: 3.6rem;
    width: 10.8rem;
  }
}

.p-relative {
  position: relative;
}

.td-underline {
  text-decoration: underline !important;
}

.width-40 {
  width: 40rem !important;
}
@media screen and (max-width: 767px) {
  .width-40 {
    width: 36rem !important;
  }
}

.p-campus-ico {
  text-align: center;
  padding-bottom: 20px;
}
.p-campus-list__item {
  display: block !important;
}
.p-campus-list__text {
  font-weight: bold;
  margin: 1rem 0 0 -2rem;
  color: #008c41;
}

.c-title-num__num.step {
  display: block;
}

.c-title-num__num div.ico_step {
  display: block;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding-top: 2rem;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .c-title-num__num div.ico_step {
    padding-top: 0rem;
  }
}

.c-title-num__num div.ico_num {
  display: block;
  width: 100%;
  text-align: center;
}

.p-dx-iconbox__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5rem 2rem 4rem;
  box-sizing: border-box;
}

.p-social-solution .c-cardbox__item img {
  width: fit-content;
}
.p-social-solution .c-cardbox__item figure {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  flex-grow: 1;
  background: #f8f8f8;
  padding: 2rem 2rem 2.5rem;
  position: relative;
}
.p-social-solution .c-cardbox__item figure::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 3rem solid #f8f8f8;
  border-right: transparent solid 4rem;
  border-left: transparent solid 4rem;
  position: absolute;
  bottom: -3rem;
}
.p-social-solution .c-cardbox__item figcaption {
  font-weight: bold;
}

.link-underline {
  text-decoration: underline !important;
}
.link-underline:hover {
  text-decoration: none !important;
}

.p-security-title__sec {
  border-left: 4px solid #00c05b;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-left: 10px;
}
.p-security-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: space-between;
  align-items: normal;
}
.p-security-wrap__img {
  width: 100%;
  max-width: 400px;
}
.p-security-wrap__item {
  width: calc(100% - 450px);
}
@media screen and (max-width: 767px) {
  .p-security-wrap__item {
    width: 100%;
  }
}

.figure_fit img {
  width: 100%;
  height: auto;
}

.p-tech {
  margin-top: 2.5rem;
}
.p-tech__list {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem 4rem;
}
.p-tech__list > li {
  max-width: 62rem;
  width: calc(50% - 2rem);
}
@media screen and (max-width: 767px) {
  .p-tech__list > li {
    width: 100%;
  }
}
.p-tech__list > li h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: left;
}
@media screen and (max-width: 1280px) {
  .p-tech__list > li h2 {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.p-tech__list.u-flex__col1 > li {
  max-width: max-content;
  width: 100%;
}
.p-tech__list .p-tech__list--item {
  background: #fff;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}
.p-tech__list .p-tech__list--item figure {
  background: #fcfcfc;
}
.p-tech__list .p-tech__list--item__text {
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-tech__list .p-tech__list--item__title {
  color: #00c05b;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
@media screen and (max-width: 1280px) {
  .p-tech__list .p-tech__list--item__title {
    font-size: clamp(18px, 1.046vw, 20px);
  }
}
.p-tech__list .p-tech__list--item__col2 {
  display: flex;
  gap: 1%;
}
@media screen and (max-width: 1280px) {
  .p-tech__list .p-tech__list--item__col2 {
    flex-direction: column;
    gap: 0;
  }
}
.p-tech__list .p-tech__list--item__col2 ul {
  flex: 1;
}
.p-tech__list .p-tech__list--item__dl {
  text-align: right;
  margin-top: 3rem;
}
.p-tech__dl {
  text-align: center;
}
.p-tech__dl figure {
  width: fit-content;
  margin: auto;
}
.p-tech__dl figure a {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
}

.bg-gray {
  background: #f8f8f8 !important;
}

.u-bg__white {
  background-color: #fff !important;
}
.u-bg__green {
  background-color: #00c05b !important;
}
.u-bg__lightgreen {
  background-color: #e5f9ee !important;
}
.u-bg__darkgreen {
  background-color: #008c41 !important;
}
.u-bg__deepgreen {
  background-color: #24593d !important;
}
.u-bg__gray {
  background-color: #f8f8f8 !important;
}
.u-bg__gray2 {
  background-color: #fcfcfc !important;
}
.u-bg__darkgray {
  background-color: #999 !important;
}
.u-bg__accent {
  background-color: #e5f9ee !important;
}
.u-bg__img {
  background-color: #ccc !important;
  padding: 7rem 0;
}
.u-bg__img--text {
  font-size: 3rem;
  line-height: 1.5;
  margin-left: calc(50% + 2rem);
  padding-right: 1rem;
}

.u-border__block {
  border-color: #333;
}
.u-border__darkgray {
  border-color: #999 !important;
}
.u-border__palegray {
  border-color: #f8f8f8 !important;
}
.u-border__green {
  border-color: #00c05b !important;
}

.u-flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .u-flex {
    flex-direction: column;
  }
}
.u-flex > * {
  width: calc((100% - 3rem) / 2);
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex > * {
    width: 100%;
  }
}
.u-flex-nogap {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .u-flex-nogap {
    flex-direction: column;
  }
}
.u-flex-nogap > * {
  width: 50%;
  padding: 4rem;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex-nogap > * {
    width: 100%;
    padding: 3rem;
  }
}
.u-flex__col1 {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.u-flex__col1--item {
  width: 100%;
  box-sizing: border-box;
}
.u-flex__col1--item a {
  flex-direction: row;
}
.u-flex__col2 {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .u-flex__col2 {
    flex-direction: column;
  }
}
.u-flex__col2--item {
  width: calc((100% - 4rem) / 2);
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex__col2--item {
    width: 100%;
  }
}
.u-flex__col3 {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .u-flex__col3 {
    flex-direction: column;
  }
}
.u-flex__col3--item {
  width: calc((100% - 8rem) / 3);
  text-align: center;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex__col3--item {
    width: 100%;
  }
}
.u-flex__col4 {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .u-flex__col4 {
    flex-direction: column;
  }
}
.u-flex__col4--item {
  width: calc((100% - 12rem) / 4);
  text-align: center;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex__col4--item {
    width: 100%;
  }
}
.u-flex__col5 {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
}
@media screen and (max-width: 767px) {
  .u-flex__col5 {
    flex-direction: column;
  }
}
.u-flex__col5--item {
  width: calc((100% - 16rem) / 5);
  text-align: center;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-flex__col5--item {
    width: 100%;
  }
}
.u-flex-cancel {
  display: block !important;
}
.u-flex-column {
  display: flex;
  flex-direction: column;
}
.u-flex-grow {
  flex-grow: 1;
}
.u-flex__vertical {
  display: flex;
  flex-direction: column;
}
.u-flex__simple--center {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.u-flex-grow {
  flex-grow: 1;
}

.inner .u-flex__col1, .inner .u-flex__col2, .inner .u-flex__col3, .inner .u-flex__col4 {
  width: 100%;
}

.u-bold {
  font-weight: bold;
}

.u-normal {
  font-weight: normal;
}

.hover-action {
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
}
.hover-action::before, .hover-action::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  margin: auto;
  transition: 0.2s ease-in-out;
  opacity: 0;
  z-index: 1;
}
.hover-action::before {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}
.hover-action::after {
  color: #fff;
  content: "Read More";
  font-size: 22px;
  font-weight: bold;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
}
.hover-action:hover {
  transform: scale(1.03);
  opacity: 1;
}
.hover-action:hover::before, .hover-action:hover::after {
  opacity: 1;
}

.hover-scale:hover {
  transform: scale(1.03);
  transition-duration: 0.2s;
}

.u-img-border {
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
}

.u-img-ib {
  width: fit-content;
}
.u-img-ib__caption {
  padding-top: 1rem;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .u-img-ib__caption {
    font-size: 12px;
  }
}

.u-line__darkgreen {
  border-bottom: 1px solid #008c41;
}

.u-lh1 {
  line-height: 1 !important;
}
.u-lh2 {
  line-height: 2 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0rem !important;
}

.mt10 {
  margin-top: 1rem !important;
}

.mt20 {
  margin-top: 2rem !important;
}

.mt30 {
  margin-top: 3rem !important;
}

.mt40 {
  margin-top: 4rem !important;
}

.mt50 {
  margin-top: 5rem !important;
}

.mt60 {
  margin-top: 6rem !important;
}

.mt70 {
  margin-top: 7rem !important;
}

.mt80 {
  margin-top: 8rem !important;
}

.mt90 {
  margin-top: 9rem !important;
}

.mt100 {
  margin-top: 10rem !important;
}

.mb0 {
  margin-bottom: 0rem !important;
}

.mb10 {
  margin-bottom: 1rem !important;
}

.mb20 {
  margin-bottom: 2rem !important;
}

.mb30 {
  margin-bottom: 3rem !important;
}

.mb40 {
  margin-bottom: 4rem !important;
}

.mb50 {
  margin-bottom: 5rem !important;
}

.mb60 {
  margin-bottom: 6rem !important;
}

.mb70 {
  margin-bottom: 7rem !important;
}

.mb80 {
  margin-bottom: 8rem !important;
}

.mb90 {
  margin-bottom: 9rem !important;
}

.mb100 {
  margin-bottom: 10rem !important;
}

.ml0 {
  margin-left: 0rem !important;
}

.ml10 {
  margin-left: 1rem !important;
}

.ml20 {
  margin-left: 2rem !important;
}

.ml30 {
  margin-left: 3rem !important;
}

.ml40 {
  margin-left: 4rem !important;
}

.ml50 {
  margin-left: 5rem !important;
}

.ml60 {
  margin-left: 6rem !important;
}

.ml70 {
  margin-left: 7rem !important;
}

.ml80 {
  margin-left: 8rem !important;
}

.ml90 {
  margin-left: 9rem !important;
}

.ml100 {
  margin-left: 10rem !important;
}

.mr0 {
  margin-right: 0rem !important;
}

.mr10 {
  margin-right: 1rem !important;
}

.mr20 {
  margin-right: 2rem !important;
}

.mr30 {
  margin-right: 3rem !important;
}

.mr40 {
  margin-right: 4rem !important;
}

.mr50 {
  margin-right: 5rem !important;
}

.mr60 {
  margin-right: 6rem !important;
}

.mr70 {
  margin-right: 7rem !important;
}

.mr80 {
  margin-right: 8rem !important;
}

.mr90 {
  margin-right: 9rem !important;
}

.mr100 {
  margin-right: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-10 {
  padding-top: 1rem !important;
}

.pt-20 {
  padding-top: 2rem !important;
}

.pt-30 {
  padding-top: 3rem !important;
}

.pt-40 {
  padding-top: 4rem !important;
}

.pt-50 {
  padding-top: 5rem !important;
}

.pt-60 {
  padding-top: 6rem !important;
}

.pt-70 {
  padding-top: 7rem !important;
}

.pt-80 {
  padding-top: 8rem !important;
}

.pt-90 {
  padding-top: 9rem !important;
}

.pt-100 {
  padding-top: 10rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-10 {
  padding-bottom: 1rem !important;
}

.pb-20 {
  padding-bottom: 2rem !important;
}

.pb-30 {
  padding-bottom: 3rem !important;
}

.pb-40 {
  padding-bottom: 4rem !important;
}

.pb-50 {
  padding-bottom: 5rem !important;
}

.pb-60 {
  padding-bottom: 6rem !important;
}

.pb-70 {
  padding-bottom: 7rem !important;
}

.pb-80 {
  padding-bottom: 8rem !important;
}

.pb-90 {
  padding-bottom: 9rem !important;
}

.pb-100 {
  padding-bottom: 10rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-10 {
  padding-left: 1rem !important;
}

.pl-20 {
  padding-left: 2rem !important;
}

.pl-30 {
  padding-left: 3rem !important;
}

.pl-40 {
  padding-left: 4rem !important;
}

.pl-50 {
  padding-left: 5rem !important;
}

.pl-60 {
  padding-left: 6rem !important;
}

.pl-70 {
  padding-left: 7rem !important;
}

.pl-80 {
  padding-left: 8rem !important;
}

.pl-90 {
  padding-left: 9rem !important;
}

.pl-100 {
  padding-left: 10rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-10 {
  padding-right: 1rem !important;
}

.pr-20 {
  padding-right: 2rem !important;
}

.pr-30 {
  padding-right: 3rem !important;
}

.pr-40 {
  padding-right: 4rem !important;
}

.pr-50 {
  padding-right: 5rem !important;
}

.pr-60 {
  padding-right: 6rem !important;
}

.pr-70 {
  padding-right: 7rem !important;
}

.pr-80 {
  padding-right: 8rem !important;
}

.pr-90 {
  padding-right: 9rem !important;
}

.pr-100 {
  padding-right: 10rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.p-10 {
  padding: 1rem !important;
}

.p-20 {
  padding: 2rem !important;
}

.p-30 {
  padding: 3rem !important;
}

.p-40 {
  padding: 4rem !important;
}

.p-50 {
  padding: 5rem !important;
}

.p-60 {
  padding: 6rem !important;
}

.p-70 {
  padding: 7rem !important;
}

.p-80 {
  padding: 8rem !important;
}

.p-90 {
  padding: 9rem !important;
}

.p-100 {
  padding: 10rem !important;
}

.u-pe__none {
  pointer-events: none;
}

.relative-position {
  position: relative;
}
@media screen and (max-width: 767px) {
  .relative-position {
    overflow: hidden;
  }
}

.block.relative-position .inner {
  padding-bottom: 120px;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .block.relative-position .inner {
    margin-top: 0;
    padding-bottom: 64px;
  }
}

.u-boxshadow {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}

.u-datalist {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
}
.u-datalist dt, .u-datalist dd {
  padding: 3rem 4rem;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .u-datalist dt, .u-datalist dd {
    padding: 2rem;
  }
}
.u-datalist dt {
  background: #f8f8f8;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.u-datalist dt.u-accent {
  color: #008c41;
}
.u-datalist dd.u-accent {
  font-weight: bold;
  background: #e5f9ee;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.c-color-green {
  color: #00c05b !important;
}
.c-color-darkgreen {
  color: #008c41 !important;
}
.c-color-lightgreen {
  color: #e5f9ee !important;
}
.c-color-red {
  color: #d42109 !important;
}
.c-color-imgblock {
  color: #000 !important;
}
.c-color-block {
  color: #333 !important;
}
.c-color-darkgray {
  color: #999 !important;
}
.c-color-lightgray {
  color: #ccc !important;
}
.c-color-palegray {
  color: #f8f8f8 !important;
}
.c-color-white {
  color: #fff !important;
}

.u-text-left {
  text-align: left !important;
}
.u-text-right {
  text-align: right !important;
}
.u-text-center {
  text-align: center !important;
}
.u-text__superextra {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.65;
}
@media screen and (max-width: 1280px) {
  .u-text__superextra {
    font-size: clamp(28px, 2.083vw, 40px);
  }
}
.u-text-bold {
  font-weight: bold !important;
}
.u-text-thin {
  font-weight: normal !important;
}
.u-text__extra {
  font-size: 3rem;
  line-height: 1.65;
}
@media screen and (max-width: 1280px) {
  .u-text__extra {
    font-size: clamp(24px, 1.562vw, 30px);
  }
}
.u-text__expansion {
  font-size: 2rem;
  line-height: 1.65;
}
.u-text__expansion-little {
  font-size: 18px;
  line-height: 1.65;
}
.u-text__normal {
  font-size: 16px;
  line-height: 1.65;
}
.u-text__reduction {
  font-size: 14px;
  line-height: 1.65;
}
.u-text__small {
  font-size: 12px;
  line-height: 1.65;
}
.u-text__line--wide {
  line-height: 1.75;
}
.u-text__line--narrow {
  line-height: 1.5;
}
.u-text__addition {
  font-size: 12px;
  line-height: 1.67;
}
.u-text__annotation {
  font-size: 12px;
  position: relative;
  padding-left: 2rem;
}
.u-text__annotation::before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}

.u-v-align__baseline {
  vertical-align: baseline !important;
}
.u-v-align__middle {
  vertical-align: middle !important;
}
.u-v-align__bottom {
  vertical-align: bottom !important;
}

.pc-only {
  display: block;
}
@media screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
}

.pc-only2 {
  display: block;
}
@media screen and (max-width: 480px) {
  .pc-only2 {
    display: none;
  }
}

.sp-only {
  display: none;
}
@media screen and (max-width: 767px) {
  .sp-only {
    display: block;
  }
}

.sp-only2 {
  display: none;
}
@media screen and (max-width: 480px) {
  .sp-only2 {
    display: block;
  }
}

.bx-wrapper {
  position: relative;
  margin-bottom: 0;
  padding: 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

.bxslider {
  margin: 0;
  padding: 0;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  -webkit-transform: translatez(0);
  z-index: 5;
}

.bx-wrapper .bx-controls-auto, .bx-wrapper .bx-pager {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

.bx-wrapper .bx-loading {
  min-height: 50px;
  background: #F00;
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.bx-wrapper .bx-pager {
  height: 76px;
  text-align: center;
  font-size: 0.85em;
  font-family: Arial;
  font-weight: 700;
  color: #666;
  padding: 0px;
  margin: 0 0 0 0;
  z-index: 99;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #CCC;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a:focus, .bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #096B04;
}

.bx-wrapper .bx-controls-auto .bx-controls-auto-item, .bx-wrapper .bx-pager-item {
  display: inline-block;
  vertical-align: bottom;
}

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}

.bx-wrapper .bx-prev {
  left: 10px;
  background: url(images/controls.png) 0 -32px no-repeat;
}

.bx-wrapper .bx-prev:focus, .bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next {
  right: 10px;
  background: url(images/controls.png) -43px -32px no-repeat;
}

.bx-wrapper .bx-next:focus, .bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -11px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start.active, .bx-wrapper .bx-controls-auto .bx-start:focus, .bx-wrapper .bx-controls-auto .bx-start:hover {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -44px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop.active, .bx-wrapper .bx-controls-auto .bx-stop:focus, .bx-wrapper .bx-controls-auto .bx-stop:hover {
  background-position: -86px -33px;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 0.85em;
  padding: 10px;
}