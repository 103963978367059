.c-arrow {
  &__top {
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 36px solid $c_green;
    border-right: 64px solid transparent;
    border-left: 64px solid transparent;
  }

  &__bottom {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 36px solid $c_green;
    border-right: 64px solid transparent;
    border-left: 64px solid transparent;
  }

  &__right {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 36px solid $c_green;
    border-bottom: 64px solid transparent;
    border-top: 64px solid transparent;
  }

}