.c-introduction {
  .inner {
    padding: 0;

    > p:first-child {
      margin-bottom: 2rem;
    }
  }
  small {
    font-size: 14px;
  }
  // .name {
  //   @include font20;
  // }
}

.c-sentence {
  margin-bottom: 6rem;

  &:last-of-type {
    margin-bottom: 10rem;
  }

  .inner {
    padding: 0;
  }
  small {
    font-size: 14px;
  }
  h2 {
    font-size: 3rem;
    margin-bottom: 3rem;

    @include mq-down(l) {
      @include font30;
    }
  }

  h3 {
    @include font18;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  ul, ol, p, div {
    margin-bottom: 2.5rem;
  }

  .u-indent {
    padding-left: 2.5rem;
    span {
      display: block;
      text-indent: -2.5rem;
    }
  }

  &__link {
    padding-left: 2rem;
    margin-bottom: 4rem;
    display: inline-block;
  }

  &__iso {
    &--data {
      :is(dt, dd) {
        * {
          margin: 0;
        }
      }
      dt {
        width: 30rem;
        font-weight: bold;
        display: flex;
        align-items: center;
  
        @include mq-down(l) {
          width: 30%;
        }
      }
      dd {
        width: calc(100% - 30rem);
  
        @include mq-down(l) {
          width: 70%;
        }
      }
    }

    &--img {
      margin-top: -4rem!important;
      margin-bottom: 10rem!important;

      figure {
        background: $c_palegray;
        padding: 3rem 5rem;

        @include mq-down(l) {
          padding: 3rem;
        }
      }
    }
  }
}
