.c-table {
  display: flex;
  flex-wrap: wrap;
  width: 93%;
  max-width: 128rem;
  margin: auto;
  border-top: 1px solid $c_lightgray;

  dt,
  dd {
    padding: 3rem 4rem;
    border-bottom: 1px solid $c_lightgray;
    box-sizing: border-box;

    @include mq-down() {
      padding: 2rem;
    }
  }
  dt {
    width: 30rem;
    background: $c_palegray;
    font-weight: bold;
    display: flex;
    align-items: center;

    @include mq-down(l) {
      width: 30%;
    }

    &.u-accent {
      color: $c_darkgreen;
    }
  }
  dd {
    width: calc(100% - 30rem);
    @include mq-down(l) {
      width: 70%;
    }

    &.u-accent {
      font-weight: bold;
      background: $c_light_green;
    }
  }

  th,
  td {
    padding: 3rem 4rem;
    border-bottom: 1px solid $c_lightgray;
    box-sizing: border-box;

    @include mq-down() {
      padding: 2rem;
    }
  }
  th {
    width: 30rem;
    background: $c_palegray;
    font-weight: bold;

    @include mq-down(l) {
      width: 30%;
    }

    &.u-accent {
      color: $c_darkgreen;
    }
  }
  td {
    width: calc(100% - 30rem);
    @include mq-down(l) {
      width: 70%;
    }

    &.u-accent {
      font-weight: bold;
      background: $c_light_green;
    }
  }

  &.ver02 {
    dd {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }

    .c-table__dd {
      &--main,
      &--sub {
        padding: 3rem 4rem;
        box-sizing: border-box;
      }

      &--main {
        width: calc(100% - 30rem);
        border-right: 1px solid #fff;
      }

      &--sub {
        width: 30rem;
        background: $c_palegray;
      }
    }
  }

  &-head {
    display: flex;
    height: 45px;
    background: $c_darkgray;

    &__title,
    &__content {
      padding: 1rem 4rem;
      font-weight: bold;
      box-sizing: border-box;
    }

    &__title {
      width: 30rem;
      border-right: 1px solid #fff;
    }

    &__content {
      width: calc(100% - 30rem);
    }

    &.ver02 {
      .c-table-head__content {
        padding: 0;
        display: flex;
        justify-content: space-between;

        &--main,
        &--sub {
          padding: 1rem 4rem;
          box-sizing: border-box;
        }

        &--main {
          width: calc(100% - 30rem);
          border-right: 1px solid #fff;
        }

        &--sub {
          width: 30rem;
        }
      }
    }
  }
}

.p-column-content .c-table {
  width: 100%;

  dt,
  dd {
    padding: 1.2rem 1.6rem;
  }
  dt {
    align-items: baseline;
    width: 20rem;

    @include mq-down(l) {
      width: 30%;
    }
  }
  dd {
    width: calc(100% - 20rem);

    @include mq-down(l) {
      width: 70%;
    }
  }
}

.inner {
  .c-table {
    width: 100%;
  }
}
th.white{
  color: #fff;
}