.relative-position {
  position:relative;
  @include mq-down() {
    overflow: hidden;
  }
}
.block.relative-position .inner{
  padding-bottom:120px;
  margin-top: 60px;
  @include mq-down() {
    margin-top:0;
    padding-bottom:64px;
  }
}