.c-adobe {
  background: $c_palegray;
  display: flex;
  gap: 2.5rem;
  padding: 1.5rem 6.5rem;

  &--img,
  &--text {
    margin-bottom: 0!important;
  }
}