.p-tech {
  margin-top: 2.5rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 6rem 4rem;

    > li {
      max-width: 62rem;
      width: calc(50% - 2rem);

      @include mq-down() {
        width: 100%;
      }

      h2 {
        font-size: 3rem;
        margin-bottom: 2rem;
        text-align: left;

        @include mq-down(l) {
          @include font30;
        }
      }
    }

    &.u-flex__col1 {
      > li {
        max-width: max-content;
        width: 100%;
      }
    }

    .p-tech__list--item {
      background: #fff;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

      figure {
        background: $c_palegray2;
      }

      &__text {
        padding: 4rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // min-height: 30rem;

        // @include mq-down(l) {
        //   min-height: 28vw;
        // }
        // @include mq-down() {
        //   min-height: unset;
        // }
      }

      &__title {
        color: $c_green;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 3rem;

        @include mq-down(l) {
          @include font20;
        }
      }

      &__col2 {
        display: flex;
        gap: 1%;

        @include mq-down(l)  {
          flex-direction: column;
          gap: 0;
        }

        ul {
          flex: 1;
        }
      }

      &__dl {
        text-align: right;
        margin-top: 3rem;
      }

    }
  }

  &__dl {
    text-align: center;

    figure {
      width: fit-content;
      margin: auto;

      a {
        font-size: 2rem;
        font-weight: bold;
        display: inline-block;
        width: 100%;
      }
    }
  }
}