.bg-about {
  background-image: url(../img/img_top_about_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
section.block .inner.bg-about {
  max-width: 1720px;
  width: 100%;
  margin-bottom: 100px;
}
.block-about {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @include mq-down(l) {
    width: 100%;
  }
  @include mq-down(m) {
    width:93%;
    flex-wrap:wrap;
  }

  &_items {
    &:first-of-type {
      width: 676px;
      @include mq-down(l) {
        width: 48%;
      }
      @include mq-down(m) {
        width: 100%;
      }
    }
    &:last-of-type {
      @include mq-down(l) {
        width: 48%;
      }
      @include mq-down(m) {
        width: 100%;
      }
    }
    p {
      letter-spacing: 0.05em;
      @include mq-down() {
        font-size:13px;
        line-height: 1.7;
      }
    }
  }

  &_titleImg {
    margin-right: 60px;
    @include mq-down(l) {
      margin-right: 0;
    }
    @include mq-down(m) {
      margin:35px auto 0;
    }

    img {
      @include mq-down(l) {
        margin:0 auto;
        display:block;
      }
      @include mq-down(m) {
        width: 100%;
      }
    }
  }

  .block-content__title {
    @include mq-down() {
      text-align:center;
      line-height: 1.3;
      font-size: 24px;
    }
  }

  .button-default a {
    @include mq-down() {
      margin:0 auto !important;
    }
  }
}

// 会社情報トップ
#aboutPage main + section.block {
  padding-top: 12rem;

  @include mq-down() {
    padding-top: 66px;
  }
}

// 会社情報ページ
.p-about {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8rem 4rem;
    margin-top: 10rem;
    @include mq-down() {
      gap: 3rem 2%;
    }

    &__item {
      transition: .5s;
      text-align: center;
      // padding-bottom: 2.5rem;
      &:nth-child(-n+2) {
        width: calc((100% - 4rem) / 2);

        @include mq-down() {
          width: 100%;
        }
      }
      &:nth-child(n+3) {
        width: calc((100% - 8rem) / 3);
        @include mq-down() {
          width: 49%;
        }
      }
      figure {
        margin-bottom: 1.5rem;
      }
      a {
        // @include font24;
        font-size: 2rem;
        font-weight: bold;
        display: inline-block;
        padding-bottom: 2.5rem;
      }
    }
  }

  // 社長挨拶
  &-message {
    &__catch {
      background: #f5fdff;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq-down(l) {
        gap: 0;
      }

      @include mq-down() {
        flex-direction: column;
        padding-top: 5vw;
      }

      &--text {
        font-size: 3rem;
        line-height: 1.5;
        margin-top: -3rem;
        padding-left: 2rem;

        @include mq-down(l) {
          @include font30;
        }

        @include mq-down() {
          margin-top: 0;
          width: 100%;
        }

      }

      figure {
        padding-top: 3rem;

        @include mq-down(l) {
          width: 38vw;
        }

        @include mq-down() {
          width: 100%;
          text-align: center;
        }
      }
    }

    &__text {

      .inner {
        padding: 0;
      }

      &--content {
        // padding: 9rem;
        padding: 9rem 9rem 3rem;
        background: #fff;
        column-count: 2;
        column-gap: 5rem;
        margin-top: -5rem;
        position: relative;
        text-align: justify;

        @include mq-down(l) {
          padding: 3rem;
        }
        @include mq-down() {
          column-count: 1;
        }
      }

      &--ceo {
        text-align: right;
        padding-right: 9rem;
        @include mq-down(l) {
          padding-right: 3rem;
        }

        &--name {
          font-size: 3rem;
          font-weight: bold;
          margin-top: 1rem;

          @include mq-down(l) {
            @include font30;
          }
        }
      }
    }
  }

  // 会社概要
  &-corporate {
    &__data {
      dt {
        width: 30rem;

        @include mq-down() {
          width: 30%;
        }
      }
      dd {
        width: calc(100% - 30rem);

        @include mq-down() {
          width: 70%;
        }
      }

      small {
        margin-top: 2rem;
        font-size: 12px;
        display: inline-block;
      }
    }
  }

  // 沿革
  &-history {

    &.u-pb100 {
      padding-bottom: 10rem;
    }

    .inner {
      padding: 0;
    }
    h2 {
      font-size: 3rem;
      text-align: center;
      margin: 6rem auto 3rem;
      @include mq-down(l) {
        @include font30;
      }

      small {
        font-size: 80%;
      }
    }
    &__list {
      dt {
        width: 13.5rem;
      }
      dd {
        width: calc(100% - 13.5rem);
      }
    }

    &__parts {
      position: relative;

      &--small {
        display: flex;
        justify-content: center;
        padding: 3rem 0;
        margin: 1rem auto -4rem;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: $c_lightgray;
          position: absolute;
          top: -50%;
          transform: translateY(50%);
        }

        p {
          @include font20;
          line-height: 1;
          display: inline-block;
          background: $c_lightgray;
          padding: 1rem 2rem;
        }
      }

      &--big {
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 27.5rem;
          background: $c_lightgray;
          position: absolute;
          top: -12.5rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 0;
        }

        p {
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
          max-width: 767px;
          width: 100%;
          margin: auto auto 6rem;
          padding: 2.5rem 0;
          background: #fff;
          border: 1px solid $c_lightgray;
          position: relative;

          @include mq-down(l) {
            @include font30;
          }
        }
      }

      &--text {
        margin-left: 50%;
        @include font20;
        @include mq-down(l) {
          margin: -2rem 0 -3rem 50%;
        }

        p {
          display: inline-block;
          position: relative;
          padding-left: 12rem;
          font-weight: bold;
          @include mq-down(l) {
            padding-left: 8vw;
          }
          @include mq-down() {
            padding-left: 6vw;
          }

          br {
            @include mq-up(l) {
              display: none;
            }
          }

          &::before {
            content: '';
            display: block;
            width: 11rem;
            height: 1px;
            background: $c_lightgray;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @include mq-down(l) {
              width: 7vw;
            }

            @include mq-down() {
              width: 5vw;
            }
          }
        }
      }
    }

    &#apresia {
      padding-top: 2.5rem;
      padding-bottom: 10rem;

      dt, dd {
        box-sizing: border-box;

        @include mq-down() {
          padding: 2rem;
        }
      }
      dt {
        width: 30rem;
        background: $c_lightgray;
        border-bottom: 1px solid $c_palegray;
        display: flex; align-items: center;
        @include mq-down(l) {
          width: 30%;
        }

        &:last-of-type {
          border-bottom: 1px solid $c_lightgray;
        }
      }
      dd {
        width: calc(100% - 30rem);
        background: #fff;

        @include mq-down(l) {
          width: 70%;
        }
      }
    }

    &.bg-gray {
      .p-about-history__parts--small {
        margin-top: -1.5rem;
        padding: 7.5rem 0 3rem;
      }
    }
  }

  // 電子公告
  &-koukoku {
    &__file {
      .inner {
        padding-bottom: 0;
      }
      h2 {
        text-align: center;
        font-size: 3rem;
        margin-bottom: 3rem;

        @include mq-down(l) {
          @include font30;
        }
      }
    }
    &__box {
      display: flex;
      gap: 4rem;
      margin-bottom: 5rem;
      @include mq-down() {
        flex-direction: column;
        gap: 0;
      }

      &--item {
        width: calc((100% - 4rem) / 2);
        align-self: baseline;

        @include mq-down() {
          width: 100%;
        }

        &:not(:first-child) {
          @include mq-down() {
            border-top: none;
          }
        }

        dt {
          width: 134px;
        }
        dd {
          width: calc(100% - 134px);
        }
      }
    }
  }

  // 事業所一覧
  &-location {
    &__block {
      &#loc-main {
        padding-top: 10rem;
      }

      .inner {
        padding: 0 0 6rem;
      }

      &:last-of-type .inner {
        padding-bottom: 0;
      }

      h2 {
        font-size: 3rem;
        margin-bottom: 3rem;

        @include mq-down(l) {
          @include font30;
        }
      }
    }

    &__detail {
      display: flex;
      border: solid $c_lightgray;
      border-width: 1px 0;

      @include mq-down() {
        flex-direction: column;
      }

      &--name, &--access {
        padding: 3rem 4rem;

        @include mq-down() {
          padding: 2rem;
        }
      }
      &--name {
        background: $c_palegray;
        @include mq-down() {
          border-bottom: 1px solid $c_lightgray;
        }
        p {
          font-weight: bold;
        }
      }
      &--access {
        &01 {
          margin-bottom: 3rem;
        }
      }
    }
  }

  // カタログ・導入事例ダウンロード
  &-publish {
    padding-top: 0!important;

    &:not(:first-of-type) {
      .inner {
        padding: 6rem 0 0;
      }
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 3rem;
      width: 100%;
      @include mq-down(l) {
        @include font30;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 6rem 4rem;

      @include mq-down() {
        gap: 3rem;
      }

      figure {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .c-textlink__download {
        text-align: right;
        display: block;
        margin-top: 3rem;
      }
    }

    &__item {
      &--wide {
        width: 100%;
        // display: flex;
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
        &.hover-action {
          display: flex;
        }

        @include mq-down() {
          flex-direction: column;
        }

        figure {
          width: 49%;
          background: $c_palegray2;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mq-down() {
            width: 100%;
          }
        }

        &__info {
          width: 51%;
          padding: 4rem;
          box-sizing: border-box;

          // @include mq-down(l) {
          //   padding: 3rem;
          // }

          @include mq-down() {
            width: 100%;
            padding: 2rem;
          }
        }
      }

      &--name {
        font-size: 2rem;
        font-weight: bold;
        color: $c_darkgreen;
        margin-bottom: 1rem;

        @include mq-down(l) {
          @include font20;
        }
      }

      &--time {
        font-size: 12px;
        color: $c_lightgray;
        padding-left: 2rem;
        margin-bottom: 2rem;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url('../../assets/img/ico_update.png');
          position: absolute;
          left: 0;
          top: 6px;
        }
      }

      &--product {
        padding: 2rem 3rem;
        border: 1px solid $c_lightgray;

        @include mq-down() {
          padding: 2rem;
        }

        &.u-flex {
          display: flex;
          gap: 2%;

          .c-list-ul {
            width: 49%;
          }
        }
      }

      &--half {
        width: calc((100% - 4rem) / 2);
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

        @include mq-down() {
          // width: calc((100% - 3rem) / 2);
          width: 100%;
        }

        figure {
          @include mq-down() {
            height: auto;
          }
        }

        &__info {
          padding: 4rem;

          @include mq-down(l) {
            padding: 2rem;
          }
        }
      }
    }
  }
}
