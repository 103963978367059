.bg-faq {
  position: absolute;
  width: 100%;
  max-width: 1600px;

  img {
    width: 100%;
    max-width: 1600px;
  }
}

.block-faq {
  &_content {
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    right: 0;
    width: 56%;
    padding: 80px;
    background: #f8f8f8;

    @include mq-down(m) {
      position: static;
      width: 100%;
      padding: 40px 25px;
    }

    &__inner {
      width: 100%;
      max-width: 787px;
      margin-top: 60px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__title {
      margin-bottom: 25px;
      padding-left: 8px;
      border-left: 1px solid #333;
      font-weight: bold;
      font-size: 18px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 17px;

      @include mq-down(m) {
        justify-content: space-between;
        gap: 0;
      }

      &.col2 {
        .block-faq_content__items {
          width: 48%;

          @include mq-down(m) {
            width: 100%;
          }

          img {
            @include mq-down(m) {
              width: 100%;
            }
          }
        }
      }
    }

    &__items {
      width: calc((100% - 34px) / 3);
      @include mq-down(m) {
        width: 49%;
        margin-bottom: 10px;
      }

      a {
        box-sizing: border-box;
        display: block;
        padding: 0;
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
        transition: all 0.5s;

        &:hover {
          box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
          opacity: 1;
          transition: all 0.5s;

          img {
            opacity: 1;
          }
        }
      }
    }

    &__img {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__name {
      padding: 5px 0;
      background: #000;
      color: #fff;
      font-size: 14px;
      text-align: center;

      @include mq-down(m) {
        padding: 2px 0;
        font-size: 13px;
      }

      p {
        color: #fff;
      }
    }
  }

  &_titleImg {
    @include mq-down(m) {
      margin-top: 20px;
    }

    img {
      @include mq-down(m) {
        width: 100%;
      }
    }
  }

  .block-content__title {
    @include mq-down(m) {
      text-align: center;
    }
  }

  .button-default {
    a {
      @include mq-down(m) {
        margin: 0 auto !important;
      }
    }
  }
}