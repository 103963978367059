.p-0 {
  padding: 0!important;
}

@for $p_num from 0 through 10 {
  .pt-#{$p_num * 10} { padding-top: $p_num * 1rem!important; }
}

@for $p_num from 0 through 10 {
  .pb-#{$p_num * 10} { padding-bottom: $p_num * 1rem!important; }
}

@for $p_num from 0 through 10 {
  .pl-#{$p_num * 10} { padding-left: $p_num * 1rem!important; }
}

@for $p_num from 0 through 10 {
  .pr-#{$p_num * 10} { padding-right: $p_num * 1rem!important; }
}

@for $p_num from 0 through 10 {
  .p-#{$p_num * 10} { padding: $p_num * 1rem!important; }
}