.p-products {
  .c-cardbox__item {
    .c-tag__white--red {
      position: absolute;
      bottom: 4rem;
    }
    .align-right {
      margin-top: 5rem;
    }

    &--title {
      span {
        display: block;
        font-size: .8em;
        line-height: 0.5;
        margin-top: 1rem;
      }
    }

    .c-list-ul {
      column-count: 1;
    }
  }

  &-strength {
    .u-flex__col3--item.u-wide {
      width: 100%;
      background: $c_palegray2;
      padding: 4rem;

      // table {
      //   width: 100%;
      // }
    }
  }
}

#productsDetailPage {
  .p-faq {
    .button-default a {
      width: 40rem;
    }
  }
}

.c-contact.u-bg__accent {
  padding: 3.5rem 0;
}

#productsCategoryPage {
  .p-faq .button-default a {
    width: 40rem;

    @include mq-down() {
      width: 100%;
      max-width: 40rem;
    }
  }
}

.p-nutanix-effects {
  &__list {

    &--item {
      display: flex;
      gap: 4rem;

      @include mq-down() {
        flex-direction: column;
      }

      figure {
        width: 15%;
        text-align: center;

        @include mq-down() {
          width: 100%;
        }
      }
    }

    &--text {
      width: 85%;

      @include mq-down() {
        width: 100%;
      }
    }

    &--title {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}

.p-vmware-virtual {
  &__flexbox {
    display: flex;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }

    &--text {
      width: 75%;
      @include mq-down() {
        width: 100%;
      }
    }

    &--img {
      width: 25%;
      text-align: center;
      @include mq-down() {
        width: 100%;
      }
    }
  }
}

.p-line-service {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  li {
    display: flex;
    gap: 3rem;
  }
}

.p-iamos {
  &__iot {
    .u-flex__col2 {
      position: relative;

      &--item {
        padding: 4rem 3rem;

        &:not(.c-linebox) {
          background: $c_palegray2;
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 36px solid $c_green;
        border-bottom: 64px solid transparent;
        border-top: 64px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include mq-down() {
          border-top: 36px solid $c_green;
          border-right: 64px solid transparent;
          border-left: 64px solid transparent;
          transform: translate(-50%, -20%);
        }
      }
    }

    table {
      width: 100%;
      box-sizing: border-box;
      border-top: 1px solid $c_lightgray;
      border-collapse: collapse;
      margin-bottom: 2.4rem;

      th, td:nth-of-type(2) {
        background: $c_palegray;
      }
      th, td {
        padding: 15px;
        border-bottom: 1px solid $c_lightgray;
      }
      th {
        font-weight: bold;
      }
      td:first-of-type {
        width: 10rem;
      }
    }
  }

  &__products {
    &--item {
      padding: 4rem 3rem 3rem;

      &01 {
        img {
          margin-left: 22rem;

          @include mq-down(l) {
            margin: auto;
          }
        }
      }

      &02 {
        .u-flex {
          gap: 4rem;

          @include mq-down() {
            flex-direction: column;
          }
        }

        img {
          margin-left: 4rem;

          @include mq-down(l) {
            margin: auto;
          }
        }

        table {
          width: 100%;
          box-sizing: border-box;
          border-collapse: collapse;

          th, td {
            vertical-align: middle;
          }
  
          th {
            padding: 0 12px;
            background: $c_darkgray;
            font-weight: bold;
            text-align: left;
            height: 3rem;

            &:first-of-type {
              width: 5rem;
            }
  
            &:not(:first-of-type) {
              border-left: 1px solid #fff;
            }

            // &:nth-of-type(2) {}

            &:last-of-type {
              width: 8rem;
            }
          }
  
          td {
            padding: 1rem 2rem;
            border-bottom: 1px solid $c_lightgray;
            background-color: #fff;

            &:not(:nth-of-type(2)) {
              text-align: center;
              background-color: $c_palegray2;
            }
          }
  
        }
      }
    }
  }
}

.p-svo {
  &-benefit {
    .c-cardbox__item--text {
      flex: none;
      .c-list-ul {
        flex-grow: unset;
      }
    }
  }

  &-flow {
    &__list {
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr 1fr;
      grid-gap: 4.5rem 4rem;
      @include mq-down() {
        display: flex;
        flex-direction: column;
        gap: 4rem;
      }

      &--item {
        display: flex;
        align-items: center;
        gap: 4rem;
        @include mq-down() {
          gap: 2rem;
        }

        &:nth-child(1) {
          grid-row: 1/2;
          grid-column: 1/2;
        }
        &:nth-child(2) {
          grid-row: 2/3;
          grid-column: 1/2;
        }
        &:nth-child(3) {
          grid-row: 3/4;
          grid-column: 1/2;
        }
        &:nth-child(4) {
          grid-row: 4/5;
          grid-column: 1/2;
        }
        &:nth-child(5) {
          grid-row: 1/2;
          grid-column: 2/3;
        }
        &:nth-child(6) {
          grid-row: 2/3;
          grid-column: 2/3;
        }
        &:nth-child(7) {
          grid-row: 3/4;
          grid-column: 2/3;
        }
      }

      &--line {
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 4.5rem;
          background: $c_palegray;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.p-hcnetconnect {
  &-feature {
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8rem;
      gap: 2rem;
      margin-bottom: 3rem;

      &.item01 {
        background-color: $c_green;
      }
      &.item02 {
        background-color: $c_darkgreen;
      }
      &.item03 {
        background-color: $c_deepgreen;
      }

      p {
        color: #fff;
        font-size: 2.5rem;
        font-weight: bold;
      }
    }

    &__title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 3rem;
    }
  }

  &-scene {
    figure {
      margin-bottom: 3rem;
    }
  }
}

.p-a3csim-lte {
  &__image {
    background-color: $c_palegray;
    padding: 2rem;
    margin-bottom: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    @include mq-down() {
      flex-direction: column;
      gap: 0;
    }

    .c-arrow__right {
      border-top-width: 55px;
      border-bottom-width: 55px;

      @include mq-down() {
        transform: rotate(90deg);
      }
    }
  }
}
.p-safiego {
  &-customize {
    &__image {
      figcaption {
        color: #0098a6;
        font-weight: bold;
      }

      p {
        color: #fff;
        font-weight: bold;
        background: #0098a6;
        border-radius: 5px;
        width: 31.5rem;
        margin: auto;
      }
    }
  }
}

.p-viengine {
  &-problem {
    &__solve {
      &--text {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .c-linebox {
      margin-bottom: 1.5rem;
    }
  }
}

.p-knowbe4 {
  &-training {
    &__list--label {
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 2.5rem;
      font-weight: bold;

      &.train {
        background-color: #1eb5c0;
      }
      &.phish {
        background-color: #e77c29;
      }
      &.analyze {
        background-color: #90a9b6;
      }
    }
  }
}

.p-knowbe4 {
  &-function {
    &__list {
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr 1fr;
      grid-gap: 4.5rem 4rem;
      @include mq-down() {
        display: flex;
        flex-direction: column;
        gap: 4rem;
      }

      &--item {
        display: flex;
        align-items: flex-start;
        gap: 4rem;
        @include mq-down() {
          gap: 2rem;
        }

        &:nth-child(1) {
          grid-row: 1/2;
          grid-column: 1/2;
        }
        &:nth-child(2) {
          grid-row: 2/3;
          grid-column: 1/2;
        }
        &:nth-child(3) {
          grid-row: 3/4;
          grid-column: 1/2;
        }
        &:nth-child(4) {
          grid-row: 4/5;
          grid-column: 1/2;
        }
        &:nth-child(5) {
          grid-row: 1/2;
          grid-column: 2/3;
        }
        &:nth-child(6) {
          grid-row: 2/3;
          grid-column: 2/3;
        }
        &:nth-child(7) {
          grid-row: 3/4;
          grid-column: 2/3;
        }

        figure {
          width: 9rem;
        }

        div {
          width: calc(100% - 9rem);
        }
      }
    }
  }
}

.p-conferenceadapterex {
  &-feature {
    &__step {
      li:not(:last-of-type) {
        position: relative;

        &::after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 30px solid $c_green;
          border-bottom: 60px solid transparent;
          border-top: 60px solid transparent;
          position: absolute;
          top: 50%;
          right: -35px;
          transform: translateY(-50%);
          z-index: 1;

          @include mq-down() {
            border-top: 30px solid $c_green;
            border-left: 60px solid transparent;
            border-right: 60px solid transparent;
            border-bottom: none;
            top: unset;
            right: 50%;
            bottom: -3.5rem;
            transform: translateX(50%);
          }
        }
      }
    }
  }
}

.p-product-lineup {
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @include mq-down() {
    flex-direction: column;
  }

  &__item {
    @include mq-down() {
      width: 100%;
    }
  }
}

.p-xtreme-d {
  &-customize {
    display: grid;
    grid-template-columns: 20rem 20rem 20.7rem 20.7rem 20.7rem 20.9rem;
    grid-template-rows: 12rem 12rem 5rem 7rem;
    gap: 1rem;

    @include mq-down(l) {
      grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
    }

    @include mq-down() {
      display: flex;
      flex-direction: column;
    }

    &__item {
      background-color: $c_palegray;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      text-align: center;

      @include mq-down() {
        padding: 2rem;
      }

      p {
        font-weight: bold;
      }

      &.item01 {
        grid-row: 1/3;
        grid-column: 1/2;
        background-color: $c_img_block;
      }

      &.item02 {
        grid-row: 1/2;
        grid-column: 2/3;
        background-color: $c_green;

        p {
          color: #fff;
          font-size: 2rem;
        }
      }

      &.item03 {
        grid-row: 1/2;
        grid-column: 3/4;
      }

      &.item04 {
        grid-row: 1/2;
        grid-column: 4/5;
      }

      &.item05 {
        grid-row: 1/2;
        grid-column: 5/6;
      }

      &.item06 {
        grid-row: 1/2;
        grid-column: 6/7;
      }

      &.item07 {
        grid-row: 2/3;
        grid-column: 2/3;
        background-color: $c_darkgreen;

        p {
          color: #fff;
          font-size: 2rem;
        }
      }

      &.item08 {
        grid-row: 2/3;
        grid-column: 3/4;
      }

      &.item09 {
        grid-row: 2/3;
        grid-column: 4/5;
      }

      &.item10 {
        grid-row: 2/3;
        grid-column: 5/6;
      }

      &.item11 {
        grid-row: 2/3;
        grid-column: 6/7;
      }

      &.item12 {
        grid-row:3/4 ;
        grid-column: 1/7;
      }

      &.item13 {
        grid-row: 4/5;
        grid-column: 1/7;

        ul {
          display: flex;
          gap: 5px;

          @include mq-down() {
            flex-direction: column;
            width: 100%;
          }

          li {
            padding: 1.5rem;
            border: 2px dashed $c_block;
            line-height: 1;
            font-weight: bold;
          }
        }
      }
    }
  }

  &-scenario {
    &__item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: center;

      .c-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8rem;

        &.c-label01 {
          background: $c_darkgreen;

          p {
            color: #fff;
          }
        }

        &.c-label02 {
          background: #fff;
          border: 2px solid $c_block;

          p {
            color: $c_block;
          }
        }

        p {
          font-weight: bold;
        }
      }

      .u-flex{
        @include mq-down() {
          flex-direction: row;
          gap: 2rem;
        }
        &__item {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .c-label {
            flex-grow: 1;
          }
        }
      }
    }

    &__annotation {
      li {
        vertical-align: middle;
      }
      .c-block {
        width: 2rem;
        height: 2rem;
        display: inline-block;
        vertical-align: sub;
        box-sizing: border-box;

        &.u-border__block {
          border: 1px solid;
        }
      }
    }
  }

  &-subscription {
    .c-cardbox__item {
      ul {
        border-bottom: 1px solid $c_palegray;
        padding-bottom: 3rem;
        flex-grow: 1;
      }

      p.align-left {
        flex-grow: 1;
      }
    }
  }
}

.p-zscaler{
  &-cloud {
    &__img {
      width: fit-content;
      margin: auto;
      position: relative;

      figcaption {
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        background-color: $c_green;
        padding: 0 2rem;
        position: absolute;
        right: -130%;
        bottom: 1rem;
      }
    }

    &__item {
      border: 3px solid $c_green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;

      .marker {
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        background-color: $c_green;
        padding: 0 1px;
      }
    }
  }

  &-solution {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 1px;
    }

    &__item {
      width: calc((100% - 6px) / 7);
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-align: center;
      font-weight: bold;

      @include mq-down() {
        width: calc((100% - 2px) / 3);
      }

      &.item01 {
        background-color: #9f9f9f;
      }
      &.item02 {
        background-color: $c_palegray;
      }
      &.item03 {
        background-color: #eee;
      }
      &.item04 {
        background-color: #ddd;
      }
      &.item05 {
        background-color: #f1f1f1;
      }
      &.item06 {
        background-color: #949494;
      }
      &.item07 {
        background-color: #858383;
      }
      &.item08 {
        background-color: #e8e8e8;
      }
      &.item09 {
        background-color: #e3e0e0;
      }
      &.item10 {
        background-color: #bbb;
      }
      &.item11 {
        background-color: $c_lightgray;
      }
      &.item12 {
        background-color: #aaa;
      }
    }
  }
}

.p-cloudsirt-column {
  img {
    max-width: none;
    width: 100%;
  }
}

.p-lcx {
  &-case {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;

    &__img {
      &.img01 {
        grid-row: 1/2;
        grid-column: 1/3;
      }
      &.img02 {
        grid-row: 2/3;
        grid-column: 1/2;
      }
      &.img03 {
        grid-row: 2/3;
        grid-column: 2/3;
      }
    }
  }
}

.p-aruba {
  &-tool {
    &__box {
      &--left {
        display: flex;
        gap: 3rem;

        figure {
          width: max-content;
        }
      }
    }
  }
}

.p-conexiometer {
  &-step {
    box-sizing: border-box;

    ul {
      display: flex;
      justify-content: center;
      gap: 2rem;
      padding: 2.5rem 2rem 2rem;
    }
  }
}

.p-amnimo {
  &-spec {
    .no-border {
      border: none!important;
    }

    .border-top-left {
      border: solid $c_lightgray;
      border-width: 1px 0 1px  1px;
    }
  }
}

.p-pureflow-ws1 {
  &-specifications {
    .no-border {
      border: none!important;
    }
    .border-top {
      border-top: 1px solid $c_lightgray;
    }
  }
}

#accesspoint figure {
  @include mq-down() {
    text-align: center;
  }
}

.p-radwin2000__reverse {
  @include mq-down() {
    flex-direction: column-reverse!important;
    gap: 4rem;
  }

  .u-flex__col2--item,
  figure {
    text-align: center;
  }
}