.c-cva {
  padding-top: 0!important;
}
.block-cva {
  &_list {
    margin-top:40px;
    display:flex;
    justify-content: space-between;
    @include mq-down(l) {
      width:100%;
      gap:10px;
    }
    @include mq-down(m) {
      flex-wrap:wrap;
    }

    &__items {
      width: calc((100% - 8rem) / 5);
      // @include mq-down(l) {
      //   width:20%;
      // }
      @include mq-down(m) {
        width:48%;
        border: 1px solid #fff;
        box-sizing: border-box;
      }
      a{
        // width:240px;
        padding: 0;
        box-shadow: 0 0 13px rgba(51,51,51,.07);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;
        height: 108px;
        font-weight: bold;
        @include mq-down(l) {
          width: 100%;
        }
        @include mq-down() {
          // height: 75px;
          min-height: 75px;
          height: auto;
        }

        &:hover {
          box-shadow: 0 0 35px rgba(51,51,51,.21);
          transition: all 0.5s;
          opacity: 1;
          img{
            opacity: 1;
          }
        }
      }

      &.contact a {
        background:$c_green;
        color:#fff;
      }

      &.download a {
        background:$c_darkgreen;
        color:#fff;
      }

      &.guide a {
        background:$c_darkgreen;
        color:#fff;
      }
    }

    &__cont {
      display:flex;
      align-items: center;

      @include mq-down() {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
      }

      p {
        color:#fff;
        @include mq-down() {
          font-size: 13px;
          line-height: 1.4;
        }
      }
    }

    &__icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }

  .block-content__title {
    @include mq-down() {
      text-align: center;
      line-height: 1.3;
      font-size: 24px;
    }
  }

  .recruit {
    @include mq-down() {
      width: 100%;
    }

    .block-cva_list__items a {
      @include mq-down() {
        width:100%;
        height: 75px;
      }
    }

    .block-cva_list__cont p {
      color:$c_block;
      @include mq-down() {
        font-size:13px;
        line-height: 1.4;
      }
    }
  }
}