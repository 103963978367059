.l-mv {
  &__underlayer {
    height: 20rem;
    background: $c_palegray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    position: relative;

    @include mq-down() {
      margin-top: 6.2rem;
    }

    h1 {
      font-size: 4rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.5;
      @include mq-down(l) {
        @include font40;
      }
    }

    &__front-img {
      position: absolute;
      left: 65%;
      bottom: 0;
      z-index: 0;
    }

    &__back-img {
      position: absolute;
      z-index: -1;
      img {
        max-width: unset;
        width: auto;
        height: 100%;
      }
    }

    &.l-mv__img {
      height: 30rem;
      background: unset;
      overflow: hidden;

      h1 {
        color: #fff;
      }
    }
  }

  &__product-detail {
    height: 30rem;
    overflow: hidden;
    position: relative;
    background-color: $c_lightgray;

    @include mq-down() {
      height: auto;
      padding-top: 3rem;
      padding-bottom: 2rem;
    }

    &__main {
      position: relative;
      z-index: 1;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__title {
      display: flex;
      flex-direction: column;
      // gap: 4rem;
      max-width: 128rem;
      width: 93%;
      margin: auto;
      h1 {
        color: #fff;
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 4rem;

        @include mq-down() {
          @include font38;
        }
        span {
          font-size: 3rem;
        }
      }

      p {
        color: #fff;
      }
    }

    &__catch {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }

    &__text {
      margin-top: 1rem;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      img {
        max-width: none;
        height: 100%;
      }
    }

    &__flex {
      height: 45rem;
      overflow: hidden;
      position: relative;

      @include mq-down() {
        height: auto;
        padding-top: 3rem;
        padding-bottom: 2rem;
      }

      &::before {
        display: none;
        @include mq-down() {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent $c_img_block;
          border-width: 5rem 0 0 100vw;
          position: absolute;
          right: 0;
          bottom: 30%;
          z-index: 1;
        }
      }

      &::after {
        display: none;
        @include mq-down() {
          content: '';
          display: block;
          width: 100vw;
          height: 30%;
          background: $c_img_block;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }

      &.l-mv__low {
        height: 30rem;

        @include mq-down() {
          height: auto;
        }

        .l-mv__product-detail__flex__bg {
          img {
            max-width: unset;
            width: auto;
            height: 30rem;
  
            @include mq-down() {
              height: 100%;
            }
          }

          &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 30rem 11rem;
            border-color: transparent transparent #000  transparent;
            position: absolute;
            top: 0;
            right: 35%;
            z-index: 1;
  
            @include mq-down() {
              // top: auto;
              // left: 0;
              // right: auto;
              // bottom: 40vw;
              // border-width: 0 100vw 10vw 0;
              display: none;
            }
          }
        }
      }

      &__main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap: 3vw;
        z-index: 3;
        max-width: 128rem;
        width: 93%;
        height: max-content;
        margin: auto;

        @include mq-down(l) {
          gap: 3vw;
        }

        @include mq-down() {
          flex-direction: column;
          gap: 3rem;
          position: relative;
        }
      }

      &__title {
        // max-width: 580px;
        width: 55%;

        &.adapter {
          width: 60%;
        }

        @include mq-down() {
          width: auto;
        }

        h1, p {
          color: #fff;
        }
        h1 {
          font-size: 4rem;
          font-weight: bold;
          line-height: 1.2;
          margin-bottom: 1rem;

          @include mq-down(l) {
            @include font40;
          }

          span {
            font-size: 3rem;
            vertical-align: bottom;

            @include mq-down(l) {
              @include font30;
            }
          }
        }
      }

      &__catch {
        font-size: 2rem;
      }

      &__btns {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .button-product {
          width: 100%;
          a {
            width: 575px;
            justify-content: center;

            @include mq-down(l) {
              width: 100%;
            }
          }
        }

        .button-quotation,
        .button-contact {
          @include mq-down(l) {
            width: calc((100% - 15px) / 2);
          }

          a {
            @include mq-down(l) {
              width: 100%;
            }
          }
        }
      }

      &__img {
        width: 45%;
        display: flex;
        align-items: center;
        // justify-content: center;
        @include mq-down() {
          width: auto;
        }

        &.adapter {
          width: 40%;
        }

        img {
          margin: 0 auto;
        }
      }

      &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @include mq-down() {
          top: auto;
          bottom: 0;
          height: 100%;
        }

        img {
          max-width: unset;
          width: auto;
          height: 45rem;

          @include mq-down() {
            height: 100%;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 45rem 11rem;
          border-color: transparent transparent #000  transparent;
          position: absolute;
          top: 0;
          right: 35%;
          z-index: 1;

          @include mq-down() {
            top: auto;
            left: 0;
            right: auto;
            bottom: 40vw;
            border-width: 0 100vw 10vw 0;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 35%;
          height: 100%;
          background-color: #000;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;

          @include mq-down() {
            // width: 100%;
            // height: 40vw;
            // left: 0;
            // right: auto;
            display: none;
          }
        }
      }
    }

  }

  &__underlayer-m-l {
    height: 450px;
    background: $c_palegray;
    margin-top: 8rem;
    position: relative;
    @include mq-down() {
      margin-top: 6.2rem;
    }

    h1 {
      font-size: 4rem;
      font-weight: bold;
      text-align: left;
      line-height: 1.5;
      margin-bottom: 1.5rem;
      color: #fff;
      @include mq-down(l) {
        @include font40;
      }
    }
    &__catch{
      @include font20;
      font-weight: bold;
      color: #FFF;
      line-height: 1.75;
      margin-bottom: 3rem;
    }
    &__front-img {
      position: absolute;
      left: 65%;
      bottom: 0;
      z-index: 0;
    }

    &__back-img {
      position: absolute;
      z-index: -1;
      top: 0;
      text-align: center;
      width: 100%;
      img {
        display: block;
        margin: auto;
        max-width: unset;
        width: auto;
        height: 100%;
      }
    }
    &__btns{
      width: 584px;
      @include mq-down() {
        width: 100%;
      }
      .button-contact{
        a{
          width: 100%;
        }
      }
    }
    &.l-mv__img {
      height: 450px;
      background: unset;
      overflow: hidden;

      h1 {
        color: #fff;
      }
    }
  }
  &__underlayer-s-l {
    height: 30rem;
    background: $c_palegray;
    margin-top: 8rem;
    position: relative;
    @include mq-down() {
      margin-top: 6.2rem;
      height: auto !important;
      min-height: 27rem;
      padding-bottom: 3rem;
    }

    h1 {
      font-size: 4rem;
      font-weight: bold;
      text-align: left;
      line-height: 1.5;
      margin-bottom: 1.5rem;
      color: #fff;
      span.sub{
        display: block;
        font-size: 18px !important;
      }
     @include mq-down(l) {
        @include font40;
      }
      &.pt-110{
        padding-top: 110px;
      }
    }
    &__catch{
      @include font20;
      font-weight: bold;
      color: #FFF;
      line-height: 1.75;
      margin-bottom: 3rem;
      font-feature-settings: "palt";
    }

    &__back-img {
      position: absolute;
      z-index: -1;
      top: 0;
      text-align: center;
      right: 0;
      width: 100%;
      @include mq-down() {
        width: auto;
        height: 100%;

      }
      img {
        display: block;
        margin: auto;
        max-width: unset;
        width: auto;
        height: 100%;
      }
    }
    &.l-mv__img {
      height: 30rem;
      background: unset;
      overflow: hidden;

      h1 {
        color: #fff;
      }
    }
  }
}
.l-mv__inner{
    width: 93%;
    max-width: 128rem;
    margin: auto;
}