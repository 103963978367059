.m-0 {
  margin:0 !important;
}

@for $m_num from 0 through 10 {
  .mt#{$m_num * 10} { margin-top: $m_num * 1rem!important; }
}

@for $m_num from 0 through 10 {
  .mb#{$m_num * 10} { margin-bottom: $m_num * 1rem!important; }
}

@for $m_num from 0 through 10 {
  .ml#{$m_num * 10} { margin-left: $m_num * 1rem!important; }
}

@for $m_num from 0 through 10 {
  .mr#{$m_num * 10} { margin-right: $m_num * 1rem!important; }
}