.c-tab {
  &__switch {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    ul {
      display: flex;
      flex-wrap: wrap;
      border-left: 1px solid $c_lightgray;
      @include mq-down() {
        border-top: 1px solid $c_lightgray;
      }
  
      li {
        font-weight: bold;
        padding: 1.5rem 3rem;
        border: solid $c_lightgray;
        border-width: 1px 1px 1px 0;
        cursor: pointer;
        @include mq-down() {
          width: 50%;
          box-sizing: border-box;
          border-width: 0 1px 1px 0;
        }
  
        &:hover,
        &.current {
          background: $c_light_green;
        }

        &:has(a) {
          padding: 0;

          a {
            display: inline-block;
            padding: 1.5rem 3rem;

            @include mq-down() {
              font-size: 14px;
              padding: 1rem 1.5rem;
            }
          }
        }
      }
    }
  }
}

.inner {
  .c-tab__switch {
    width: 100%;
  }
}