// 新規追加バナースタイル
.c-bnr-about-company {
  border-bottom: 1px solid $c_lightgray;
  padding-bottom: 104px;
  position: relative;

  &__container {
    width: 100rem;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0px 0px 11.31px 1.69px rgba(51, 51, 51, 0.18);
    font-family: "Noto Sans CJK JP";
    display: block;

    @include mq-down() {
      width: 90%;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include mq-down() {
      width: 100%;
      display: block;
    }
  }

  &__img {
    background-color: #f8f8f8;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-down() {
      width: 100%;
      box-sizing: border-box;
      padding: 4rem 0;
    }
  }

  &__contents {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.7rem;
    padding: 4rem 0 0 4rem;
    box-sizing: border-box;

    @include mq-down() {
      width: 100%;
      padding: 5% 0 0 5%;
    }
  }

  &__txt-01 {
    font-size: 1.6rem;
    color: #008c41;
    font-weight: bold;
    margin-top: 4.8rem;

    @include mq-down() {
      margin-top: 2rem;
    }
  }

  &__txt-02 {
    font-size: 3rem;
    line-height: 1.5;
    font-weight: bold;

    @include mq-down() {
      font-size: 2rem;
    }
  }

  &__txt-03 {
    color: #000000;
    font-size: 1.6rem;
    line-height: 1.75;
  }

  &__link {
    font-size: 1.6rem;
    color: #ffffff !important;
    background-color: #008c41;
    text-decoration: none;
    font-weight: bold;
    margin-left: auto;
    display: block;
    margin-top: 1rem;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    padding: 1rem 2.4rem 1rem 4.4rem;
    float: right;
  }

  &__txt {
    @include mq-down() {
      padding: 0 2rem;
    }
  }
}
