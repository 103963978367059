// コラム共通
.p-column-category {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-end;

    li {
      color: $c_darkgray;
      font-size: 14px;
      line-height: 1;
      border: 1px solid $c_darkgray;
      padding: 5px 7px;
    }
  }
}

// コラム一覧
.p-column-categories {
  max-width: 128rem;
  width: 93%;
  margin: 9rem auto auto;
  ul {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid $c_lightgray;
    @include mq-down() {
      border-top: 1px solid $c_lightgray;
    }

    li {
      padding: 1.5rem 3rem;
      border: solid $c_lightgray;
      border-width: 1px 1px 1px 0;
      cursor: pointer;
      @include mq-down() {
        width: 50%;
        box-sizing: border-box;
        border-width: 0 1px 1px 0;
      }

      &:hover,
      &.current {
        background: $c_light_green;
      }
    }
  }
}
.p-column-list {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8rem 2%;
    @include mq-down() {
      gap: 4rem 4%;
    }

    > li {
      width: 32%;
      max-width: 40rem;
      display: flex;
      flex-direction: column;
      transition: all 0.5s;
      background: $c_palegray;

      @include mq-down() {
        width: 48%;
      }

      &:hover {
        box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }

  &__text {
    padding: 2.5rem 3rem 4rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include mq-down() {
      padding: 2rem;
    }

    .p-column-category {
      margin-top: auto
    }
  }

  &__title {
    @include font20;
    font-weight: bold;
    margin-bottom: 3rem;

    @include mq-down() {
      margin-bottom: 2rem;
    }
  }

  &__explanation {
    margin-bottom: 5rem;

    @include mq-down() {
      margin-bottom: 3rem;
    }
  }

  &__category {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

// コラム記事
#columnDetailPage {
  main {
    background: $c_palegray;
    padding-top: 11rem;

    @include mq-down() {
      padding-top: 3rem;
    }
  }
}
.p-column {
  display: flex;
  justify-content: space-between;
  // width: 123rem;
  width: 128rem;
  margin: 0 auto;
  padding: 1rem 0 10rem;
  position: relative;
  border-bottom: 1px solid $c_lightgray;

  @include mq-down(l) {
    width: 93%;
  }

  @include mq-down() {
    // width: 93%;
    padding: 70px 0;
    flex-direction: column;
  }

  &-content {
    // max-width: 77rem;
    max-width: 82rem;
    width: 68%;
    box-sizing: border-box;
    // @include mq-down(l) {
    //   width: 70%;
    // }
    @include mq-down() {
      max-width: none;
      width: 100%;
      // padding: 3rem;
      margin-bottom: 5rem;
    }

    &__inner {
      background: #fff;
      padding: 5rem 6rem 6rem;
      margin-bottom: 2rem;

      @include mq-down() {
        padding: 4rem 3rem 4rem;
      }

      .p-column-category {
        margin-bottom: 3rem;

        ul {
          justify-content: flex-start;
        }
      }

      > p,
      > .p-column-title__chap,
      > .p-column-title__sec,
      > .p-column-img {
        margin-bottom: 4rem;

        @include mq-down() {
          margin-bottom: 3rem;
        }
      }

      .p-column-img {
        img {
          width: 100%;
        }
      }

      p {
        strong {
          display: block;
        }
      }
    }
  }

  &-contents {
    padding: 2.5rem 3rem;
    margin-bottom: 5rem;
    background: #f8f8f8;

    &__title {
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  &-title {
    &__main {
      @include font38;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 3rem;
    }

    &__chap {
      @include font24;
      font-weight: bold;
      // line-height: 1;
      border-bottom: 2px solid $c_lightgray;
      padding-bottom: 4px;
      margin: 4rem 0 3rem;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 30%;
        max-width: 150px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -2px;
        background: $c_green;
      }
    }

    &__sec {
      @include font20;
      font-weight: bold;
      border-left: 2px solid $c_green;
      padding-left: 1rem;
      margin: 3rem 0 2rem;
    }
  }

  &-mv {
    margin-bottom: 4rem;
  }

  &-sidebar {
    max-width: 36rem;
    width: 30%;
    padding-top: 3rem;

    @include mq-down() {
      width: 100%;
      max-width: none;
      padding-top: 0;
    }

    &__title {
      @include font26;
      font-weight: bold;
      border-bottom: 2px solid $c_lightgray;
      padding-bottom: 2rem;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 30%;
        height: 2px;
        background: $c_green;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }

    &__column {
      margin-top: 1rem;
      margin-bottom: 5rem;
      li {
        border-bottom: 1px solid $c_lightgray;
        a {
          display: flex;
          justify-content: space-between;
          gap: 2.5rem;
          padding: 1rem;
        }
      }

      &__img {
        width: 8rem;
      }

      &__title {
        width: calc(100% - 10.5rem);
      }
    }

    &__textlink {
      margin-bottom: 5rem;
      li {
        border-bottom: 1px solid $c_lightgray;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border: solid $c_darkgreen;
          border-width: 0 2px 2px 0;
          transform: rotate(-45deg) translateY(-50%);
          position: absolute;
          right: 1rem;
          top: 50%;
        }

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 2rem 3rem 2rem 1rem;
          box-sizing: border-box;
        }
      }
    }

    &__bnr {
      margin-bottom: 3rem;
      text-align: center;

      img {
        box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.07);
        height: auto;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.03);
        }
      }
    }

    &__document {
      margin-bottom: 5rem;
      li {
        border-bottom: 1px solid $c_lightgray;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border: solid $c_darkgreen;
          border-width: 0 2px 2px 0;
          transform: rotate(-45deg) translateY(-50%);
          position: absolute;
          right: 1rem;
          top: 50%;
        }

        a {
          display: flex;
          gap: 1rem;
          width: 100%;
          height: 100%;
          padding: 2rem 3rem 2rem 1rem;
          box-sizing: border-box;
        }
      }

      &__img {
        width: 11rem;
      }

      &__content {
        width: calc(100% - 12rem);
      }

      &__title {
        font-weight: bold;
      }
    }
  }

  &-related {
    // margin-bottom: 5rem;

    &__title {
      color: $c_green;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    &__list {
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      gap: 2rem;

      li {
        & + li {
          padding-top: 2rem;
          border-top: 1px solid #ccc;
        }
        // width: calc((100% - 2rem) / 2);

        // @include mq-down() {
        //   width: 100%;
        // }

        a {
          display: flex;
          justify-content: space-between;

          figure {
            width: 30%;
          }
        }
      }

      &--title {
        margin-bottom: 1rem;
        font-weight: bold;
        font-size: 1.8rem;
      }
      &--text {
        width: calc(70% - 2rem);
      }
    }
  }

  &-share {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
