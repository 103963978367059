header {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  top: 0;
  z-index: 9999;
  background: #fff;
  @include mq-down() {
    padding: 10px 5px;
  }

  .header_inner {
    display:flex;
    justify-content: space-between;
    padding-left: 30px;

    @include mq-down() {
      padding: 0;
    }
  }

  .header_logo {
    display: flex;
    align-items: center;
    @include mq-down() {
      width: 80%;
    }

    h1, .header_logo__text {
      // font-size:0.74rem;
      font-size: 1.2rem;
      @include mq-down() {
        line-height: 1;
        margin-bottom: 5px;
      }
    }

    img {
      width: 100%;

      @media screen and (min-width: 1281px) and (max-width: 1480px) {
        width: 28vw;
      }

      @include mq-down(l) {
        width: 25vw;
        min-width: 280px;
      }

      @include mq-down() {
        min-width: auto;
        width: 100%;
      }
    }
  }

  .header_cont {
    display: flex;
    align-items: center;

    @include mq-down() {
      position: fixed;
      z-index: 2;
      left: 0;
      top:0;
      background: #fff;
      text-align: center;
      transform: translateY(-100%);
      transition: 0.3s ease-in-out;
      opacity: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 100px;
      -webkit-overflow-scrolling: touch;
    }

    &__list {
      display:flex;
      justify-content: space-between;
      gap: 3.5rem;
      width: 100%;
      font-weight: bold;
      font-size: 15px;
      @media screen and (min-width: 1281px) and (max-width: 1480px) {
        gap: 1.25vw;
      }
      @include mq-down(l) {
        gap: 15px;
      }
      @include mq-down(m) {
        display: block;
        margin: 0 auto;
        padding: 155px 20px 50px 20px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: transparent;
      }

      li {
        @include mq-down() {
          font-size: 1.1em;
          list-style-type: none;
          padding: 0;
          border: none;
          margin: 0;
          text-align: left;
          margin-bottom: 35px;
          position: relative;
          width: 100%;
        }

        .header_cont__list--link {
          @include mq-down(l) {
            font-size: 14px;
          }
          @include mq-down() {
            display: block;
            color:$c_block;
            height: auto;
            align-items: center;
            font-size: 16px!important;
            letter-spacing: 0.07em;
            text-align: left;
            position:relative;
          }

          &::before {
            @include mq-down() {
              display: none;
            }
          }

          &::after {
            @include mq-down() {
              content:"";
              position:absolute;
              bottom:-10px;
              height:1px;
              width:20px;
              background:$c_block;
              left: 12px;
              transform: translateX(-50%);
            }
          }
        }

        a span {
          @include mq-down() {
            margin-right: 5px;
            font-weight: bold;
            font-family: Verdana,'Noto Sans JP',Arial,sans-serif;
            color: #2c2c2c;
            font-size: 40px;
            font-style: italic;
            position: absolute;
            z-index: -1;
            left: 15px;
            top: -2px;
            letter-spacing: -0.07em;
          }
        }

        img {
          @include mq-down() {
            right: -75px;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }

        &:last-child {
          @include mq-down() {
            padding-bottom: 0;
            border-bottom: none;
          }
        }

        &:last-of-type {
          a {
            background: none;
            text-align: left;
            line-height: unset;
          }
          .header_cont__list--link:before {
            display: none;
          }
        }
      }

      > li:hover .child-menu_content {
        visibility: visible;
      }

      &--items {
        display: inline-block;

        .child-menu_contArea ul {
          @include mq-down() {
            width:100%;
            margin-bottom:30px;
            display: flex;
            justify-content: flex-start;
            gap: 5px 10px;
          }

          &:last-of-type {
            @include mq-down() {
              margin-bottom:0;
            }
          }

          li {
            @include mq-down() {
              width: calc(50% - 20px) !important;
              margin-bottom:0;
              margin-right:0;
            }
          }
        }

        &:last-child {
          padding: 15px 25px 15px 0;
        }
      }

      &--items a{
        color:$c_block;
      }

      &--link {
        display: flex;
        padding: 0;
        height: 100%;
        align-items: center;
      }
    }
    &.active {
      @include mq-down() {
        transform: translateY(0%);
        opacity:1;
        background: #fff;
      }
    }
  }

  .navToggle{
    display:none;
    @include mq-down() {
      display: block;
      position: fixed;
      right: 16px;
      top: 12px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      z-index: 3;
      background: #00c262;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);
      text-align: center;
      border-radius: 50%;
    }
  
    span {
      @include mq-down() {
        display: block;
        position: absolute;
        border-bottom: solid 3px #fff;
        transition: .35s ease-in-out;
        left: 50%;
        transform: translateX(-50%);
      }
  
      &:nth-child(1) {
        @include mq-down() {
          top: 12px;
          left: 50%;
          width: 19px;
        }
      }
  
      &:nth-child(2) {
        @include mq-down() {
          top: 20px;
          left: 43%;
          width: 13px;
        }
      }
  
      &:nth-child(3) {
        @include mq-down() {
          top: 28px;
          left: 35%;
          width: 6px;
        }
      }
  
      &:nth-child(4) {
        @include mq-down() {
          border: none;
          color: #eee;
          font-size: 9px;
          font-weight: bold;
          top: 34px;
        }
      }
    }
  
    &.active {
      @include mq-down() {
        background:#00c262;
      }
      &::after {
        @include mq-down() {
          content: "CLOSE";
          position: fixed;
          top: 23px;
          right: 68px;
          color: #00c262;
          z-index: 12;
          font-size: 13px;
          font-style:italic;
          transition: .35s ease-in-out;
        }
      }
  
      span {
        @include mq-down() {
          width: 20px;
          top: 19px;
          left: 11px;
          border-bottom: solid 3px #fff;
        }
  
        &:nth-child(1) {
          @include mq-down() {
            transform: rotate(-45deg);
          }
        }
  
        &:nth-child(2),
        &:nth-child(3) {
          @include mq-down() {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .child-menu {
    &_content {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      visibility: hidden;
      @include mq-down() {
        visibility: visible;
        position:static;
        width:100%;
        padding-top:35px;
      }

      &__inner {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
      }

      .button-default a {
        font-size: clamp(13px, 1.5vw, 16px);
        width: min(280px, 100%);
        padding: 10px 13px;
        @include mq-up(l) {
          width: 280px;
        }
      }
    }
    &_contArea {
      width: 67%;
      background: #fff;
      padding: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      @include mq-down() {
        display:block;
        width:100%;
        padding:0;
      }

      ul {
        display:flex;
        flex-wrap:wrap;
        justify-content: flex-start;
        gap:10px;
        width:90%;
        max-width: 91rem;
        // min-width: calc(100% - 100px);

        @include mq-down(l) {
          justify-content: space-between;
        }
      }

      &__img{
        border:1px solid #f1f1f1;
        padding: 10px 35px;
        display: flex;
        align-items: center;
        @include mq-down() {
          padding:10px;
          display: none;
        }
      }

      &__img img {
        width:100%;
      }

      &__title {
        text-align:center;
        font-weight:500;
        font-size: clamp(14px, 1vw, 15px);
        line-height: 1.3;
        margin-top: 5px;

        @include mq-down() {
          line-height: unset;
          font-size:13px;
          text-align: left;
        }
      }

      &-textOnly {
        &_title {
          @include mq-down() {
            font-size:14px;
            text-align:left;
          }
        }

        li a {
          @include mq-down() {
            font-size: 13px;
            display:block;
            text-align:left;
            margin-left:0;
          }

          &::before {
            @include mq-down() {
              content:none;
            }
          }
        }
      }
    }
    &_Ttlarea {
      width: 33%;
      background: #f2f2f2;
      display: flex;
      justify-content: flex-end;
      @include mq-down() {
        display: none;
      }
      &__item {
        padding: 54px 72px;
        box-sizing: border-box;
        @include mq-down(l) {
          width:100%;
          padding: 54px 30px 0 20px;          
        }
      }
    }

    &_ttlarea__head {
      font-size:24px;
      font-weight:bold;
      margin-bottom: 60px;
      @include mq-down() {
        font-size:27px;
        margin-bottom:40px;
      }

      .en {
        color:#454545;
        font-size:13px;
        display: block;
        margin-top: 4px;
        font-weight:400;
        @include mq-down() {
          // font-size: 13px;
          margin-top: 4px;
        }
      }
    }
  }

  // メガメニューtextOnly
  .child-menu_contArea.child-menu_contArea-textOnly {
    display: block;
  }
  .child-menu_contArea-textOnly {
    ul {
      margin-bottom: 30px;
      justify-content: flex-start !important;
    }
    li {
      margin-right: 50px;
      a {
        font-weight: 400;
        // font-size: 0.9rem;
        display: flex;
        margin-left:-7px;
        &:before {
          content: "・";
          color: #00c05d;
          font-size: 1.5rem;
        }
      }
    }
    &_title {
      margin-bottom:10px;
    }
  }

  .has-child-product .child-menu_contArea {
    ul li {
      width: 21%;
      @include mq-down(l) {
        width: 23%;
      }
    }
  }

  // メガメニューsolution
  .has-child-solution .child-menu_contArea {
    ul li {
      width: calc((100% - 30px) / 4);
    }
    &__img {
      padding: 0;
    }
  }
  
  .has-child-case .child-menu_contArea ul li {
    width:180px;
  }
  .has-child-case .child-menu_contArea__img {
    padding: 0;
  }

  // メガメニューsupport
  .has-child-support .child-menu_contArea {
    ul {
      justify-content: flex-start;
      li {
        width: calc((100% - 30px) / 4);
      }
    }
    &__img {
      padding: 0;
    }
  }
  // メガメニューcompany
  .has-child-company .child-menu_contArea {
    ul {
      justify-content: flex-start;
    }
    &__img {
      padding: 0;
    }
  }

  &.unpinned nav ul li a {
    @include mq-down() {
      color:#fff;
    }
  }
}
// メニューのグレーアウト
.menu_hover_bg {
	display:none;
  transition: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  position: fixed;
  &.hov {
    display: block;
  }
}
section:first-of-type {
  padding-top:99px;
  @include mq-down() {
    padding-top:66px;
  }
}

// メガメニューcontact
.has-child-contact {
  @include mq-down() {
    display: none;
  }

  .child-menu_contArea-textOnly li {
    width: 317px;
  }

  &.header_cont__list--items {
    @include mq-down() {
      display: none;
    }
    .header_cont__list--link {
      background: $c_green;
      color: #fff;
      padding: 0 20px;
      font-size: clamp(11px, 1.2vw, 14px);
      box-sizing: border-box;
      border-radius: 10000px;
      width: 205px;
      height: 70px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1480px) {
        width: 13vw;
        min-width: 150px;
      }

      img{
        display:inline-block;
        margin-right:3px;
      }
    }
  }
}