.float-nav {
  position:fixed;
  right:-120px;
  bottom:50%;
  transform: translateY(calc(50% - 60px));
  z-index: 500;
  transition: .5s;
  @include mq-down() {
    top: auto;
    bottom: 40px;
    right: auto;
    width: 100%;
    transform: translateY(0);
  }

  &.show {
    right: 0;
  }

  &_inner {
    width:120px;
    @include mq-down() {
      width: 100%;
    }
  }

  &_cont {
    ul {
      @include mq-down() {
        display:flex;
        justify-content: space-between;
        flex-wrap:nowrap;
      }
    }

    &__items {
      width:120px;
      border-bottom: 1px solid #fff;
      @include mq-down() {
        width: 34%;
        border-right: 1px solid #fff;
        border-bottom:none;
      }
  
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background:$c_green;
        color:#fff;
        text-align:center;
        height:120px;
        font-size:15px;
        opacity: 1;
        @include mq-down() {
          height:45px;
          opacity:1;
        }
  
        img{
          opacity: 1;
        }

        p {
          @include mq-down() {
            font-size:12px;
            line-height: 1.1em;
          }
        }
  
        &:hover {
          opacity:.7;
        }
      }
  
      p{
        color:#fff;
      }
  
      img {
        display:block;
        margin-bottom: 3px;
        opacity: 0.7;
      }
  
      &.download a {
        background:$c_darkgreen;

        img {
          @include mq-down() {
            width: 15px;
          }
        }

        &:hover img {
          opacity: 1;
        }
      }

      &.contact {
        @include mq-up() {
          display: none;
        }

        a img {
          @include mq-down() {
            width: 16px;
          }
        }
      }

      &.estimate {
        a img {
          @include mq-down() {
            width: 11px;
          }
        }
      }

      &:last-of-type {
        @include mq-down() {
          border-right:none;
        }
      }
    }
  }
}
