.c-list-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  &__item {
    line-height: 1;

    &.industry {
      background: $c_red;
    }

    &.product {
      background: $c_green;
    }

    a {
      color: #fff;
      font-size: 12px;
      display: inline-block;
      padding: 5px;
    }
  }
}

.c-tag {
  ul { // 色指定なし（複数色）
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    li {
      padding: 5px 7px;
      font-size: 14px;
      line-height: 1;
      border: 1px solid;
    }
  }

  &__gray {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      li {
        padding: 5px 7px;
        background-color: $c_darkgray;
        color: #fff;
        font-size: 14px;
        line-height: 1;

        a {
          color: #fff;
        }
      }
    }
  }

  &__white {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      li {
        padding: 5px 7px;
        border: 1px solid $c_darkgray;
        background-color: #fff;
        color: $c_darkgray;
        font-size: 14px;
        line-height: 1;

        a {
          color: $c_darkgray;
        }
      }
    }

    &--red {
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        li {
          padding: 5px 7px;
          border: 1px solid $c_red;
          background-color: #fff;
          color: $c_red;
          font-size: 14px;
          line-height: 1;

          a {
            color: $c_red;
          }
        }
      }
    }
  }
}

.c-tag-span {
  &__red {
    padding: 0px 2px 1px;
    border: 1px solid $c_red;
    background-color: #fff;
    color: $c_red;
    font-size: 14px;
    line-height: 1;
    vertical-align: baseline;
    margin-right: 5px;
  }
}
