@keyframes fadezoom {
  0% {
    transform: scale(1,1) ;
  }
  100% {
    transform: scale(1.05,1.05) ;
  }
}
@keyframes fadezoomSP {
  0% {
    transform: scale(0.5,0.5) translateY(-50%) translateX(-50%);
  }
  100% {
    transform: scale(0.53,0.53) translateY(-50%) translateX(-50%);
  }
}
