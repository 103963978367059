.block-column_list {
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq-down() {
    display: block;
  }

  &__items {
    width:48%;
    @include mq-down(l) {
      display: table;
      // width: 100%;
    }
    @media screen and (max-width: 1150px) {
      width: 100%;
    }

    a {
      position:relative;
      display:flex;
      align-items: center;
      border-top:1px solid #c0ccd1;
      padding: 15px 15px 15px 30px;
      @include mq-down() {
        display:table-cell;
        vertical-align: middle;
      }
      @include mq-down() {
        padding:15px 10px 15px 0;
      }

      &::after {
        position:absolute;
        background-image: url(../img/ico_arrow_blk.png);
        content: "";
        width: 6px;
        height: 10px;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    &:last-of-type a,
    &:nth-last-of-type(2) a {
      border-bottom:1px solid #c0ccd1;
    }

    &:last-of-type a {
      @include mq-down() {
        border: none;
      }
    }

    &:nth-last-of-type(2) a {
      @include mq-down() {
        border-bottom: none;
      }
    }
  }

  &.u-wide &__items {
    width: 100%;

    &:nth-last-of-type(2) a {
      border-bottom: navajowhite;
    }
  }

  &__date {
    margin-right: 20px;
    font-size:14px;
  }

  &__cont {
    display:flex;
    align-items: center;
    @include mq-down() {
      display:block;
    }
    p{
      font-size:16px;
      @include mq-down() {
        font-size: 14px;
        line-height: 1.7;
      }
    }
  }

  &__newIcon {
    margin-right: 15px;
    padding: 0 5px;
    border: 1px solid $c_red;
    color: $c_red;
    text-align: center;
    width: 56px;
    font-size: 14px;
    @include mq-down() {
      width: 43px;
      font-size: 12px;
      line-height: 1.7;
      padding: 1px 5px 0;
      margin-bottom: 5px;
    }
  }
}

.c-column-bnr {
  background: $c_darkgreen;
  width: 100%;
  margin: auto;

  a {
    display: flex;
    justify-content: space-between;
    @include mq-down(s) {
      flex-direction: reverse;
    }
  }

  &__text {
    padding: 2rem 2.5rem;
    position: relative;
    width: calc(100% - 119px);
    box-sizing: border-box;

    @include mq-down(s) {
      width: 70%;
      padding: 1rem 1.5rem;
    }
    p {
      color: $c_palegray;
    }
  }

  &__catch {
    @include font18;
    margin-bottom: 1rem;
    line-height: 1.5;

    @include mq-down() {
      @include font14;
      margin-bottom: 1rem;
      padding-bottom: 6px;
      border-bottom: 1px solid $c_palegray;
    }

    span {
      border-bottom: 1px solid $c_palegray;
      padding-bottom: 6px;

      @include mq-down() {
        border: none;
      }
    }
  }

  &__title {
    font-size: clamp(26px, 1.978vw, 32px);
    font-weight: bold;
    line-height: 1.5;

    @include mq-down() {
      @include font20;
    }
  }

  &__link {
    position: absolute;
    bottom: 1rem;
    right: -3rem;
    z-index: 1;
    background: $c_img_block;
    border-radius: 1000px;
    padding: 1px 3rem 3px;
    @include font14;

    @include mq-down(s) {
      position: relative;
      z-index: 1;
      margin: 2.5rem auto 0;
      width: max-content;
    }

    p {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border: solid $c_palegray;
        border-width: 0 3px 3px 0;
        position: absolute;
        right: -20px;
        top: 9px;
        z-index: 1;
        transform: rotate(-45deg);

        @include mq-down(s) {
          width: 6px;
          height: 6px;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &__img {
    width: 119px;

    @include mq-down(s) {
      width: 30%;
      position: relative;
    }

    img {
      @include mq-down(s) {
        position: absolute;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}