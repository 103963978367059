.l-overflow {
  &__scroll--x {
    overflow-x: auto;

    > * {
      width: max-content;
    }
  }

  &__scroll--y {
    overflow-y: auto;

    > * {
      width: max-content;
    }
  }

  &__scroll {
    overflow: auto;

    > * {
      width: max-content;
    }
  }

  &__hidden--x {
    overflow-x: hidden;
  }

  &__hidden--y {
    overflow-y: hidden;
  }

  &__hidden {
    overflow: hidden;
  }
}