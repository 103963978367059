@mixin mq-up($breakpoint: m) {
    @media #{map-get($breakpoint-up, $breakpoint)} {
        @content;
    }
}

@mixin mq-down($breakpoint: m) {
    @media #{map-get($breakpoint-down, $breakpoint)} {
        @content;
    }
}
