footer {
  background: #fff;
  @include mq-down() {
    padding-bottom: 80px;
  }

  .inner {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 30px;
    position: relative;

    // @include mq-down(l) {
    //   width: 100%;
    // }
    @include mq-down(m) {
      width:93%;
      margin: 0 auto;
      padding: 70px 0 30px;
    }
  }
}
.footer {
  &-cont {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @include mq-down() {
      display:flex;
      flex-wrap: wrap;
    }

    &_heading {
      font-weight:bold;
      font-size:16px;
      margin-bottom: 25px;
      @include mq-down() {
        margin-bottom: 5px;
      }

      a{
        color:$c_block;
      }
    }

    &_list {
      padding-left:1em;
      @include mq-down() {
        padding-left: 0;
      }

      li a {
        font-size:14px;
        color:$c_block;
        @include mq-down() {
          font-size:13px;
        }
      }
    }

    &_items {
      @include mq-down() {
        margin-bottom:48px;
        width: 48%;
      }
    }
  }

  &-sns {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 30px 0;
    border-bottom: 1px solid #c0ccd1;
    margin-bottom: 30px;

    @include mq-down() {
      justify-content: center;
    }
  }

  &-copy {
    display:flex;
    justify-content: space-between;
    @include mq-down() {
      display: block;
    }

    &_logo {
      display:flex;
      align-items: center;
      @include mq-down() {
        display: block;
      }

      p {
        margin-left:20px;
        font-size:12px;
        @include mq-down() {
          margin-left:0;
          line-height: 1.7;
          margin-bottom: 20px;
        }
      }

      a {
        @include mq-down() {
          text-align: center;
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    &_copyright {
      display: flex;
      align-items: flex-end;
      @include mq-down() {
        display: flex;
        align-items: unset;
        justify-content: center;
      }

      p {
        font-size:12px;
        line-height: 1.7;
        @include mq-down() {
          font-size: 11px;
        }
      }
    }
  }
}