.p-case {
  padding-bottom: 10rem;

  @include mq-down() {
    padding-bottom: 5rem;
  }

  &__inner {
    border: none!important;
    padding-bottom: 0!important;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }
  }

  &__mv {
    flex-shrink: 0;
  }

  &__title {
    &--small {
      color: $c_darkgray;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    h1 {
      margin-bottom: 3rem;

      & + p {
        margin-bottom: 3rem;
      }
    }
  }

  &__mv {
    img {
      width: 100%;
    }
  }

  &__category {
    display: flex;
    gap: 5px;

    .c-tag__white {
      border-bottom: none!important;
    }
  }

  &__content {
    .u-col2 {
      display: flex;
      flex-wrap: wrap;
      column-gap: 3rem;
    }
    &.flex .p-case__content__inner {
      display: flex;
      justify-content: space-between;
      gap: 4rem;
      @include mq-down() {
        flex-direction: column;
      }

      .p-case__content__img {
        flex-shrink: 0;
      }

      .p-case__content__text {
        width: -webkit-fill-available;
        width: -moz-available;
      }

      &.rev {
        flex-direction: row-reverse;

        @include mq-down() {
          flex-direction: column;
        }
      }
    }

    &.float .p-case__content__inner {
      overflow: hidden;

      > .left {
        float: left;
        margin: 0 3rem 3rem 0;

        @include mq-down() {
          float: none;
        }
      }

      > .right {
        float: right;
        margin: 0 0 3rem 3rem;

        @include mq-down() {
          float: none;
        }
      }
    }

    &__img {
      img {
        width: 100%;
      }

      figcaption {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    &__text {
      a {
        text-decoration: none!important;
      }
    }
  }

  &__introduction {
    padding-bottom: 6rem;
    margin-bottom: 6rem;
    border-bottom: 1px solid $c_palegray;

    &__name {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3rem;
    }

    &__label {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;

      & + ul {
        margin-bottom: 25px;

        &.u-col2 {
          column-count: 2;

          @include mq-down() {
            column-count: 1;
          }
        }
      }
    }
  }

  &__document {
    box-shadow: 0 0 8px rgba(51, 51, 51, 0.21);
  }

  &__customer {
    &__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__box {
      background: $c_palegray;
      padding: 5rem;

      @include mq-down() {
        padding: 4rem 3rem 3rem;
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        @include mq-down() {
          flex-direction: column;
          margin-bottom: 1rem;
        }

        h2 {
          @include mq-down() {
            margin: 0;
          }
        }

        a {
          position: relative;
          padding-right: 2rem;

          &::after {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            background-image: url(../../assets/img/ico_link_green.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            bottom: 5px;
            right: 0;
          }
        }
      }

      &__content {
        display: flex;
        gap: 4rem;

        @include mq-down() {
          flex-direction: column;
        }

        figure {
          flex-shrink: 0;

          img {
            width: 100%;
          }
        }

        table {
          width: 100%;
          border-bottom: 1px solid $c_lightgray;
          border-collapse: collapse;

          th,td {
            border-top: 1px solid $c_lightgray;
            padding: 2rem;
            vertical-align: middle;
          }
          th {
            width: 30%;
            font-weight: bold;
            background-color: $c_lightgray;
          }
          td {
            width: 70%;
          }
        }
      }
    }
  }
}
.c-case__img--3col {
  display: flex;
  gap: 1rem;

  @include mq-down() {
    flex-direction: column;
  }

  img {
    width: calc((100% - 2rem) / 3);

    @include mq-down() {
      width: 100%;
    }
  }
}