.p-faq {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 2rem;

    &__item {
      border: 1px solid $c_lightgray;

      dt,dd {
        display: flex;
        align-items: center;
        padding: 2.5rem;
        gap: 2rem;

        @include mq-down() {
          padding: 1rem;
        }

        i {
          border-radius: 50%;
          color: #fff;
          font-weight: bold;
          width: 4rem !important;
          height: 4rem !important;
          display: block;
          text-align: center;
          padding-top: 0.5rem;
          box-sizing: border-box;
          margin: 0 0 auto 0;
        }

        span {
          width: calc(100% - 7rem);
        }
      }

      dt {
        font-weight: bold;
        position: relative;

        @include mq-down() {
          padding-right: 55px;
        }

        i {
          background: $c_green;
        }

        &::before {
          content: '';
          display: block;
          width: 4rem;
          height: 2px;
          background: $c_lightgray;
          position: absolute;
          right: 25px;

          @include mq-down() {
            width: 3rem;
            right: 20px;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 2px;
          height: 4rem;
          background: $c_lightgray;
          position: absolute;
          right: 43px;
          transition: .5s;

          @include mq-down() {
            height: 3rem;
            right: 33px;
          }
        }
      }

      dd {
        background-color: $c_palegray;
        border-top: 1px solid $c_lightgray;
        i {
          background-color: $c_darkgray;
        }
      }

      dl.open {
        dt::after {
          transform: rotate(90deg);
        }
      }
    }
  }
  &-bnr{
    width: fit-content;
    margin: auto;
    box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
    a{
      display: flex;

    }
  }
  &-bnr.bnr_left{
    margin-left: 0;
  }
}



.c-faq-anchorlink{
  &-list{
    width: 100%;
    @include grid(0, flex-start);
    border-left: 1px solid $c_lightgray;
    box-sizing: border-box;
    
    > li{
      width: calc(100% / 5);
      box-sizing: border-box;
      border-top: 1px solid $c_lightgray;
      border-right: 1px solid $c_lightgray;
      border-bottom: 1px solid $c_lightgray;
      display: table;
      margin-bottom: -1px;
      @include mq-down() {
        width: 100%;
      }
  a{
        display: table-cell;
        vertical-align: middle;
        padding: 2rem 5rem 2rem 3rem;
        font-weight: bold;
        line-height: 1.2;
        }
    }
    span{
      display: inline-block;
      margin: auto;
    }
}
}