.c-bnr-import-net {
  position: relative;
  width: 100%;
  margin-bottom: 6rem;
  padding: 2rem 0;
  overflow: hidden;
  border-left: 4px solid $c_darkgreen;
  background-color: $c_palegray;
  display: block;

  @include mq-down() {
    border-top: 4px solid $c_darkgreen;
    border-left: none;
    padding: 0 0 2rem 0;
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    z-index: 1;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 5%;

    @include mq-down() {
      flex-direction: column;
      gap: 2rem;
      margin: 0;
      width: 100%;
    }

    &:before {
      content: "";
      background: #cccccc;
      width: 770px;
      height: 600px;
      position: absolute;
      left: -450px;
      top: -150px;
      z-index: -2;
      border-radius: 2000px;

      @include mq-down() {
        display: none;
      }
    }
  }

  &__img-wrapper,
  &__txt-wrapper {
    width: 50%;
    box-sizing: border-box;
  }

  &__img-wrapper {
    @include mq-down() {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      padding: 5% 10%;
      background: #CCC;
      border-radius: 0 !important;
    }
  }

  &__txt-wrapper {
    padding: 0 0 0 3rem;

    @include mq-down() {
      width: 100%;
      padding: 0 2rem;
      text-align: center;
      box-sizing: border-box;
    }
  }

  &__txt-01,
  &__txt-02 {
    margin-top: 0;
  }

  &__txt-01 {
    font-size: 3rem;
    font-weight: bold;
    padding-top: 2rem;
    line-height: 1.5;
    display: block;

    @include mq-down() {
      font-size: 2.2rem;
    }
  }

  &__txt-02 {
    font-size: 1.6rem;
    padding-top: 2rem;
    line-height: 1.75;
    display: block;
  }

  &__link {
    display: block;
    font-size: 1.6rem;
    width: 100%;
    margin-top: 2rem;
    padding: 1rem 0;
    text-align: center;
    color: #FFF;
    font-weight: bold;
    border-radius: 30px;
    z-index: 50;
    background: #000;

    @include mq-down() {
      width: 90%;
    }

    &:hover {
      color: #FFF
    }
  }
}

.wave-gray {
  background: $c_lightgray;
}
