.u-flex {
  display: flex;
  justify-content: space-between;
  @include mq-down() {
    flex-direction: column;
  }

  > * {
    width: calc((100% - 3rem) / 2);
    box-sizing: border-box;

    @include mq-down() {
      width: 100%;
    }
  }

  &-nogap {
    display: flex;
    justify-content: space-between;
    @include mq-down() {
      flex-direction: column;
    }

    > * {
      width: 50%;
      padding: 4rem;
      box-sizing: border-box;
  
      @include mq-down() {
        width: 100%;
        padding: 3rem;
      }
    }
  }

  &__col1 {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    &--item {
      width: 100%;
      box-sizing: border-box;

      a {
        flex-direction: row;
      }
    }
  }

  &__col2 {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }

    &--item {
      width: calc((100% - 4rem) / 2);
      box-sizing: border-box;

      @include mq-down() {
        width: 100%;
      }
    }
  }

  &__col3 {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }

    &--item {
      width: calc((100% - 8rem) / 3);
      text-align: center;
      box-sizing: border-box;

      @include mq-down() {
        width: 100%;
      }
    }
  }

  &__col4 {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }

    &--item {
      width: calc((100% - 12rem) / 4);
      text-align: center;
      box-sizing: border-box;

      @include mq-down() {
        width: 100%;
      }
    }
  }

  &__col5 {
    max-width: 128rem;
    width: 93%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
    }

    &--item {
      width: calc((100% - 16rem) / 5);
      text-align: center;
      box-sizing: border-box;

      @include mq-down() {
        width: 100%;
      }
    }
  }

  &-cancel {
    display: block!important;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-grow {
    flex-grow: 1;
  }

  &__vertical {
    display: flex;
    flex-direction: column;
  }

  &__simple {
    &--center {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  &-grow {
    flex-grow: 1;
  }
}

.inner {
  .u-flex {
    &__col1,
    &__col2,
    &__col3,
    &__col4 {
      width: 100%;
    }
  }
}