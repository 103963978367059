.p-industry-wrap{
  padding: 4rem 3rem;
  margin-top: 5rem;
  &.border {
    border: 2px solid $c_lightgray;
  }
}

.youtube-contents {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  height: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  iframe{
    width: 100%;
  }
}
.p-industry-bnr-column{
  background-color: $c_palegray;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
  a{
    display: block;
    @include grid(0, flex-start);
  }
  figure{
    width: 384px;
    @include mq-down() {
      width: 100%;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  &__info{
    width: calc(100% - 384px);
    box-sizing: border-box;
    padding: 3rem 3rem 6rem 3rem;
    position: relative;
    @include mq-down() {
      width: 100%;
    }
  }
  .c-textlink__within{
    position: absolute;
    right: 3rem;
    bottom: 3rem;
  }
}
.p-industry-cta h3{
  font-size: 30px;
  @include mq-down() {
    font-size: 20px;
  }
}
.p-industry-cta1 h3{
  font-size: 20px;
  @include mq-down() {
    font-size: 16px;
  }
}