@charset "utf-8";

/* =======================================

	Browser Reset CSS

========================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
/* for PC tablet */
@media only screen and (min-width: 768px) {
  body {
    font-family: $basic_font;
    font-size: 16px;
    line-height: 1.65em;
    color: #333333;
    min-width: 1140px;
  }
}
/* for mobile */
@media only screen and (max-width: 767px) {
  body {
    font-family: $basic_font;
    font-size: 16px;
    line-height: 1.65em;
    color: #333333;
    -webkit-text-size-adjust: 100%;
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* リンク指定
----------------------------------------------- */
/* リンク指定
----------------------------------------------- */
a:link,
a:visited {
  color: #000;
  text-decoration: none;
}
a:hover,
a:active {
  color: #000;
  text-decoration: none;
  filter: alpha(opacity=70);
  opacity: 0.7;
  transition-property: all;
  transition: 0.3s linear;
}

img a {
  border: none;
}
a:hover img {
  filter: alpha(opacity=70);
  opacity: 0.7;
  transition-property: all;
  transition: 0.3s linear;
}

/*.line2.0 {
	line-height: 3.0em;
}*/
strong {
  font-weight: bold;
  vertical-align: baseline;
}
sup {
  font-size: 0.83em;
  vertical-align: super;
}
sub {
  font-size: 0.83em;
  vertical-align: sub;
}
body {
  min-width: unset;
}
