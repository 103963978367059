// green
$c_green: #00c05b;
$c_darkgreen: #008c41;
$c_deepgreen: #24593d;
$c_light_green: #e5f9ee;

// red
$c_red: #d42109;

// black
$c_img_block: #000;
$c_block: #333;
$c_darkgray: #999;
$c_lightgray: #ccc;
$c_palegray: #f8f8f8;
$c_palegray2: #fcfcfc;