h2.h2_error{
  font-size: 200px;
  font-weight:bold;
  line-height: 1;
  margin-bottom: 3rem;
  @include mq-down(l) {
    font-size: 100px;
  }
  @include mq-down() {
    font-size: 60px;
  }
}


.p-other-logo-list{
  @include grid(0, flex-start);
  border: 1px solid $c_lightgray;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  margin: auto auto 5rem auto;
  &__item{
    width: 50%;
    @include mq-down() {
      width: 100%;
    }
    box-sizing: border-box;
    &:first-child{
      border-right: 1px solid $c_lightgray;
      @include mq-down() {
        border-right: none;
        border-bottom: 1px solid $c_lightgray;
      }
      }
    img{
      width: 100%;
      height: auto;
    }
  }
}
.p-other-mail{
  background-color: $c_palegray;
  text-align: center;
  padding: 5rem 2rem;
  line-height: 1.4;
  margin-bottom: 8rem;
}