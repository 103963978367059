.c-feature-block {
  background-image: url('../img/img_footer_feature.png');
  background-position: center 0;
  background-size: 1920px auto;
  background-repeat: no-repeat;
  margin-bottom: 10rem;

  @include mq-down(l) {
    background-size: auto 100%;
    background-position: right 0;
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    column-gap: 4rem;
  }

  &__title {
    width: 100%;
    font-weight: bold;
    text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 62rem;
    width: calc(50% - 26px);

    @include mq-down(l) {
      margin-bottom: 4rem;
      width: 50%;
    }

    @include mq-down(m) {
      width: 100%;
    }

    li {
      background: #fff;
      padding: 1rem 3rem 1rem 4rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      position: relative;
      min-height: 10rem;
      box-sizing: border-box;

      @include mq-down() {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        height: auto;
      }

      &::before {
        content: attr(data-num);
        color: #fff;
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -2.5rem;
        width: 5rem;
        height: 5rem;
        background-color: $c_darkgray;
        border-radius: 50%;

        @include mq-down() {
          font-size: 2rem;
          width: 4rem;
          height: 4rem;
          left: -1rem;
        }
      }

      p:first-child {
        width: 22rem;
        font-size: 18px;
        font-weight: bold;
        color: $c_darkgreen;

        @include mq-down() {
          width: 100%;
        }
      }
      p:last-child {
        width: calc(100% - 22rem);

        @include mq-down() {
          width: 100%;
        }
      }
    }
  }

  &__bnr {
    max-width: 62rem;
    width: calc(50% - 26px);
    background: #fff;
    box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

    @include mq-down(l) {
      width: 50%;
    }

    @include mq-down(m) {
      width: 100%;
      margin-top: 2rem;
    }
  }
}