.u-border {
  &__block {
    border-color: $c_block;
  }

  &__darkgray {
    border-color: $c_darkgray!important;
  }

  &__palegray {
    border-color: $c_palegray!important;
  }

  &__green {
    border-color: $c_green!important;
  }
}