.p-inquiry {
  h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 3rem;
    @include mq-down(l) {
      @include font30;
    }
  }

  p {
    text-align: center;
  }

  .u-small {
    font-size: 12px;
    text-align: left;
  }

  &__section01 {
    padding-bottom: 10rem;

    .u-flex__col4 {
      margin-top: 5rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      // padding: 5rem 2rem 4rem;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 5rem 2rem 4rem;
        box-sizing: border-box;
      }

      // figure {
      //   padding: 0 2rem;
      // }

      h3 {
        font-weight: bold;
        font-size: 2rem;
        margin-top: 2rem;
        // padding: 0 2rem;

        @include mq-down(l) {
          @include font20;
        }
      }
    }
  }

  &__section02 {
    background: $c_palegray;
    padding-bottom: 10rem;

    .inner {
      padding: 9rem 0 5rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      padding: 5rem;
      background: #fff;

      @include mq-down(l) {
        padding: 3rem;
      }

      figure {
        margin-bottom: 2rem;
      }

      h3 {
        @include font20;
        font-weight: bold;

        br {
          @include mq-up(l) {
            display: none;
          }
        }
      }

      h4 {
        font-weight: bold;
        @include font18;
      }

      &--box {
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
        // padding: 3.5rem 2rem;
        width: 100%;
        box-sizing: border-box;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 3.5rem 2rem;
          box-sizing: border-box;
        }
      }
    }
  }
}