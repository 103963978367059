.c-img-text {
  &.u-flex__col2 {
    .c-list-ul,
    .c-list-ol {
      column-count: 2;
      column-gap: 4rem;

      @include mq-down() {
        column-count: 1;
      }

      &.u-flex {
        column-count: 1;
        display: flex;
        flex-wrap: wrap;
        column-gap: 0;

        li {
          width: 50%;

          @include mq-down() {
            width: 100%;
          }
        }
      }
    }
  }

  &__item {
    figure {
      margin-bottom: 3rem;
    }

    p {
      margin-bottom: 3rem;
    }

    &--text {
      text-align: left;
    }

    &--title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3.5rem;
    }
  }
}