.link-underline{
  text-decoration: underline !important;
  &:hover{
    text-decoration: none !important;
  }
}

.p-security{
  &-title{
    &__sec{
      border-left: 4px solid $c_green;
      font-weight: bold;
      margin-bottom: 1rem;
      padding-left: 10px;
    }
  }
  &-wrap{
    @include grid(0, space-between);
    &__img{
      width: 100%;
      max-width: 400px;
    }
    &__item{
      width: calc(100% - 450px);
      @include mq-down() {
        width: 100%;
      }
    }
  }
}
.figure_fit{
  img{
    width: 100%;
    height: auto;
  }
}