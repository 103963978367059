.c-product-detail {
  &__box {

    a {
        display: flex;
        background-color: $c_palegray;
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
    
        @include mq-down() {
          flex-direction: column;
        }
      }

    &__img {
      // padding: 4rem 3.5rem;
      background-color: #fff;
      width: 30%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq-down() {
        width: 100%;
      }
    }

    &__text {
      padding: 3rem 4rem;
      display: flex;
      // align-items: center;
      width: 70%;
      box-sizing: border-box;

      @include mq-down() {
        width: 100%;
      }

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;

        p:not([class]), ul {
          flex-grow: 1;
        }
      }

      .align-right {
        margin-top: 3rem;
      }
    }
  }
}

.c-product-table {
  width: 100%;;
  margin: auto;
  box-sizing: border-box;

  tr {
    &:first-of-type th {
      background: $c_darkgray;
    }

    &:not(:first-child) {
      border-bottom: 1px solid $c_lightgray;
    }

    th,td {
      vertical-align: middle;
      padding: 15px;
    }

    th {
      font-weight: bold;
    }

    td {
      text-align: left;
    }

    > *:nth-child(odd) {
      background: $c_palegray;
    }
  }

  &.c-table__2col {
    th, td {
      width: 50%;
    }
  }
  

  &.c-table__3col {
    th {
      width: 30%;
    }
    td {
      width: 35%;
    }
  }

  &.align-center {
    th, td {
      text-align: center!important;
    }
  }
}

.c-list-tile {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 62rem;
  @include mq-down() {
    width: 100%;
  }

  &__item {
    width: 14rem;
    height: 10rem;
    background: $c_palegray;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-down(l) {
      width: calc((100% - 6rem) / 4);
    }

    @include mq-down(m) {
      width: calc((100% - 2rem) / 2);
    }
  }

}