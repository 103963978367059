.c-textbox {
  &__line {
    padding: 3rem;
    border: 1px solid $c_palegray;
    margin-top: 6rem;
  }
  &__lineBottom {
    padding:0 0 6rem 0;
    margin-bottom: 6rem;
    border-bottom: 1px solid $c_palegray;
  }
  &__lineTop {
    padding:3rem 0 0 0;
    margin-top: 5rem;
    border-top: 1px solid $c_lightgray;
  }
}