.c-linkbox {
  &__flex {
    display: flex;
    gap: 1rem 2rem;
    flex-wrap: wrap;
  }
}
.c-textlink {

  &-box {
    &__v {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    &__h {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 3rem;
    }
  }

  // 外部リンク
  &__external {
    position: relative;
    padding-right: 2rem;

    &.bold {
      font-weight: bold;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 13px;
      height: 13px;
      background-image: url(../../assets/img/ico_link_green.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 5px;
      right: 0;
    }
  }

  // サイト内リンク
  &__within {
    position: relative;
    padding-right: 2rem;

    &.bold {
      font-weight: bold;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 13px;
      background: url(../../assets/img/ico_arrow_green.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 5px;
      right: 4px;
      transition: .3s;
    }

    &:hover {
      &::after {
        right: 0;
      }
    }
  }

  // ファイル
  &__file {
    position: relative;
    padding-left: 2.5rem;

    &::after {
      content: '';
      display: inline-block;
      width: 19px;
      height: 21px;
      background: url(../../assets/img/ico_file_green.png);
      position: absolute;
      top: 3px;
      left: 0;
    }

    &--line {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: calc(100% - 2.5rem);
        height: 1px;
        background: $c_block;
        position: absolute;
        bottom: -2px;
        left: 2.5rem;
      }
    }
    
  }

  // ページリンク
  &__page {
    position: relative;
    // padding-right: 3.5rem;

    &::after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      background: url(../../assets/img/ico_arrow_green.png) no-repeat;
      position: absolute;
      top: calc(50% + 2px);
      right: 1.5rem;
      transform: translateY(calc(-50% + 3px)) rotate(90deg);

      // @include mq-down() {
      //   top: auto;
      //   bottom: -80%;
      //   right: auto;
      //   left: 50%;
      //   transform: translate(-50%) rotate(90deg);
      // }
    }
  }

  &__download {
    position: relative;
    padding-right: 2.5rem;

    &.bold {
      font-weight: bold;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 14px;
      background: url(../../assets/img/ico_floating_01_green.png) no-repeat;
      position: absolute;
      bottom: 3px;
      right: 0;
    }
  }

  // テキスト内リンク
  &__text {
    color: $c_darkgreen!important;
    border-bottom: 1px solid $c_darkgreen;
    padding: 0 2px;

    &--external {
      color: $c_darkgreen!important;
      text-indent: 0;
      border-bottom: 1px solid $c_darkgreen;
      position: relative;
      padding: 0 2.5rem 0 2px;
      display: inline-block;
  
      &::after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url(../../assets/img/ico_link_green.png) center/contain no-repeat;
        position: absolute;
        bottom: 6px;
        right: 2px;
      }
    }
  }
}

.c-pagelink {
  max-width: 128rem;
  width: 93%;
  margin: auto;
  display: flex;
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 2rem;
    @include mq-down() {
      border-top: 1px solid $c_lightgray;
      // border-right: 1px solid $c_lightgray;
      border-left: 1px solid $c_lightgray;
      width: 100%;
      row-gap: 0;
    }

    &__col15 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: calc(100% / 15);
        height: 8.5rem;
        box-sizing: border-box;

        @include mq-down() {
          width: calc(100% / 3);
        }

        &:not(:nth-child(15n)) {
          border-right: 1px solid $c_palegray;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-indent: -2rem;
        }
      }
    }

    li {
      border-left: 1px solid $c_lightgray;
      box-sizing: border-box;
      @include mq-down(m) {
        width: 50%;
        border-left: none;
        border-right: 1px solid $c_lightgray;
        border-bottom: 1px solid $c_lightgray;
      }

      @include mq-down(s) {
        width: 100%;
      }

      // &:first-child {
      //   @include mq-down() {
      //     width: 100%;
      //   }
      // }

      &:last-child {
        border-right: 1px solid $c_lightgray;
        // @include mq-down() {
        //   border-right: none;
        // }
      }
      a {
        display: inline-block;
        padding: 2rem 4.5rem 2rem 2rem;
        box-sizing: border-box;

        @include mq-down(l) {
          padding: 2rem 3.5rem 2rem 1rem;
        }

        @include mq-down() {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__col3 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      margin-bottom: 10rem;

      @include mq-down() {
        margin-bottom: 5rem;
      }

      li {
        width: calc(100% / 3);
        background: #fff;
        box-sizing: border-box;

        @include mq-down() {
          width: 50%;
        }

        &:not(:nth-child(5n)) {
          border-right: 1px solid $c_palegray;
        }

        &:nth-child(n+6) {
          border-top: 1px solid $c_palegray;
        }
      }
    }

    &__col5 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

      li {
        width: 20%;
        background: #fff;
        box-sizing: border-box;

        @include mq-down() {
          width: 50%;
        }

        &:not(:nth-child(5n)) {
          border-right: 1px solid $c_palegray;
        }

        &:nth-child(n+6) {
          border-top: 1px solid $c_palegray;
        }
      }
    }

    &__img {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      height: 100%;

      @include mq-down() {
        gap: 5px;
      }

      p {
        width: calc(100% + 4.5rem);
        line-height: 1;

        @include mq-down() {
          font-size: 14px;
          // text-align: center;
          // padding-bottom: 1rem;
        }
      }
    }

    &__imgbox {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.8rem;
        height: 100%;
        box-sizing: border-box;

        // @include mq-down() {
        //   padding: 1rem 1rem 4rem;
        // }

        img {
          width: fit-content;
        }
      }
    }
  }

}

.inner {
  .c-pagelink {
    width: 100%;
  }
}