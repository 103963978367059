.c-text-align {
  &__center{
    text-align: center !important;
  }
  &__left{
    text-align: left !important;
  }
  &__right{
    text-align: right !important;
  }
}
.va-tb{
  vertical-align: text-bottom !important;
  line-height: normal;
}