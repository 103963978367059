.align-left {
  text-align: left !important;
}
.align-right {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}
.c-color {
  &-green {
    color: $c_green !important;
  }
  &-darkgreen {
    color: $c_darkgreen !important;
  }
  &-lightgreen {
    color: $c_light_green !important;
  }
  &-red {
    color: $c_red !important;
  }
  &-imgblock {
    color: $c_img_block !important;
  }
  &-block {
    color: $c_block !important;
  }
  &-darkgray {
    color: $c_darkgray !important;
  }
  &-lightgray {
    color: $c_lightgray !important;
  }
  &-palegray {
    color: $c_palegray !important;
  }
  &-white {
    color: #fff !important;
  }
}
.u-text {
  &-left {
    text-align: left !important;
  }
  &-right {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }

  &__superextra {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.65;
    @include mq-down(l) {
      @include font40;
    }
  }

  &-bold {
    font-weight: bold !important;
  }

  &-thin {
    font-weight: normal !important;
  }

  &__extra {
    font-size: 3rem;
    line-height: 1.65;
    @include mq-down(l) {
      @include font30;
    }
  }

  &__expansion {
    font-size: 2rem;
    line-height: 1.65;
  }

  &__expansion-little {
    font-size: 18px;
    line-height: 1.65;
  }

  &__normal {
    font-size: 16px;
    line-height: 1.65;
  }

  &__reduction {
    font-size: 14px;
    line-height: 1.65;
  }

  &__small {
    font-size: 12px;
    line-height: 1.65;
  }

  &__line {
    &--wide {
      line-height: 1.75;
    }
    &--narrow {
      line-height: 1.5;
    }
  }

  &__addition {
    font-size: 12px;
    line-height: 1.67;
  }

  &__annotation {
    font-size: 12px;
    position: relative;
    padding-left: 2rem;

    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.u-v-align {
  &__baseline {
    vertical-align: baseline !important;
  }

  &__middle {
    vertical-align: middle !important;
  }

  &__bottom {
    vertical-align: bottom !important;
  }
}
