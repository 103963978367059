.bg {
  &-gray {
    background:$c_palegray!important;
  }
}

.u-bg {
  &__white {
    background-color: #fff!important;
  }

  &__green {
    background-color: $c_green!important;
  }
  &__lightgreen {
    background-color: $c_light_green!important;
  }

  &__darkgreen {
    background-color: $c_darkgreen!important;
  }

  &__deepgreen {
    background-color: $c_deepgreen!important;
  }

  &__gray {
    background-color: $c_palegray!important;
  }

  &__gray2 {
    background-color: $c_palegray2!important;
  }

  &__darkgray {
    background-color: $c_darkgray!important;
  }

  &__accent {
    background-color: $c_light_green!important;
  }

  &__img {
    background-color: $c_lightgray!important;
    padding: 7rem 0;

    &--text {
      font-size: 3rem;
      line-height: 1.5;
      margin-left: calc(50% + 2rem);
      padding-right: 1rem;
    }
  }
}