.c-card {
  display: flex;
  flex-wrap: wrap;
  gap: 6rem 4rem;
  &--item {
    background: #fff;
    box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
    width: calc((100% - 4rem) / 2);
    display: flex;
    flex-direction: column;

    @include mq-down() {
      width: 100%;
    }

    figure {
      background: $c_palegray2;
      text-align: center;
    }

    &__text {
      padding: 4rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__title {
      color: $c_green;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3rem;

      @include mq-down(l) {
        @include font20;
      }
    }

    &__top {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__col2 {
      display: flex;
      flex-grow: 1;
      gap: 1%;
      margin-bottom: 2rem;

      @include mq-down(l)  {
        flex-direction: column;
        gap: 0;
      }

      ul {
        flex: 1;
      }
    }

    &__dl {
      text-align: right;
      margin-top: 3rem;
    }

  }

  &__col3 {
    display: flex;
    flex-flow: wrap;
    gap: 6rem 4rem;

    &--item {
      background: #fff;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      width: calc((100% - 8rem) / 3);
  
      @include mq-down() {
        width: 100%;
      }
  
      figure {
        background: $c_palegray2;
      }
  
      &__text {
        padding: 4rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
  
      &__title {
        color: $c_green;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 3rem;
  
        @include mq-down(l) {
          @include font20;
        }
      }

      &__dl {
        text-align: right;
        margin-top: 3rem;
      }
  
    }
  }

  &__simple {
    background: $c_palegray;
    padding: 4rem;

    &__title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3rem;
    }
  }
}

.c-flex-card {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

  &.no-link, > a {
    display: flex;

    @include mq-down() {
      flex-direction: column;
    }
  }

  &__img {
    flex: 1;

    img {
      width: 100%;
    }
  }

  &__text {
    flex: 1;
    padding: 4rem;
    display: flex;
    flex-direction: column;

    @include mq-down() {
      padding: 3rem;
    }

    &--title {
      font-size: 2rem;
      font-weight: bold;
      color: $c_darkgreen;
      margin-bottom: 3rem;
    }

    p:not([class]), ul {
      flex-grow: 1;
    }
  }
}

.p-column-content .c-card__col3 {
  gap: 6rem 1rem;

  &--item {
    width: calc((100% - 3rem) / 3);

    &__text {
      padding: 3rem 2rem;
    }
  }
}
