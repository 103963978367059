.c-contact {
  ul {
    display: flex;
    justify-content: center;
    gap: 4rem;

    @include mq-down() {
      flex-direction: column;
      max-width: 40rem;
      width: 93%;
      margin: auto;
    }

    li {

      .button-contact__big, .button-download__big, .button-quotation__big {
        a {
          width: 40rem;
          height: 8rem;

          @include mq-down() {
            width: 100%;
          }
        }
      }
    }
  }
}