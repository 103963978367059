.c-title {
  &__main,
  &__h2 {
    @include font38;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  &__chap,
  &__h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  &__chap--under,
  &__h3--under {
    font-size: 3rem;
    line-height: 1.5;
    margin-bottom: 3rem;

    @include mq-down(l) {
      @include font30;
    }
  }

  &__sec,
  &__h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  &__para,
  &__h5 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  #columnDetailPage & {
    &__sec,
    &__h4 {
      margin-bottom: 1.5rem;
    }
    &__para,
    &__h5 {
      margin-bottom: 1rem;
    }
  }

  &-num {
    display: flex;
    height: 93px;
    margin-bottom: 3rem;
    text-align: left;

    @include mq-down() {
      height: auto;
    }

    &__num {
      background-color: $c_darkgreen;
      font-size: 3rem;
      color: #fff;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6rem;
    }

    &__text {
      background-color: $c_palegray;
      font-size: 18px;
      font-weight: bold;
      color: $c_darkgreen;
      align-self: stretch;
      display: flex;
      align-items: center;
      width: calc(100% - 6rem);
      padding: 1rem 2.5rem;
      &.bg_white{
        background-color: #FFF;
      }
    }
  }
}
