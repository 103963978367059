.u-img {
  &-border {
    border: 1px solid $c_palegray;
    box-sizing: border-box;
  }
}
.u-img-ib{
  width: fit-content;
  &__caption{
    padding-top: 1rem;
    font-size: 14px;
    @include mq-down() {
      font-size: 12px;        }
      line-height: 1.4;
    }
}