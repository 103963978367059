#page_top {
  position: fixed;
  right: -120px;
  bottom: 50%;
  transform: translateY(calc(50% + 121px));
  background: $c_img_block;
  opacity: 1;
  z-index: 500;
  transition: .5s;
  @include mq-down() {
    right: 0;
    bottom:0px;
    width:100%;
    opacity: 1;
    transform: translateY(0);
  }

  &.show {
    right: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 120px;
    height: 120px;
    text-decoration: none;
    color:#fff;
    @include mq-down() {
      width: 100%;
      height:40px;
      font-size:11px;
      line-height: 1.1em;
    }
  }

  p{
    color:#fff;
  }

  &:hover{
    opacity:.7;
    transition: all 0.3s 0s ease;
  }
}