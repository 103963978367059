.after-bg-gray {
  &::before {
    background-color: #999999 !important;
  }
}

.after-fs-16 {
  &::before {
    font-size: 1.6rem !important;
  }
}

.after-zi-1 {
  &::before {
    z-index: 1;
  }
}

.ai-center {
  align-items: center;
}

.ai-flex-start {
  align-items: flex-start;
}

.b-shadow {
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
}

.bb-white {
  border-bottom: 1px solid #FFFFFF !important;
}

.bc-lightgray {
  background-color: $c_lightgray !important;
}

.bi {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &-factory-1 {
    background-image: url(../../assets/img/solution/img_solution_social_factory_bg_1.jpg);
  }
}

.bnr_manga{
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto 5rem auto;
  box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

  .hover-action {
    display: block;
  }
}

.cc-auto {
  column-count: auto !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;

  @include mq-down() {
    flex-direction: column;

    &__row {
      flex-direction: row;
    }
  }
}

.flex-img {
  flex-shrink: 0;

  @include mq-down() {
    width: 100% !important;
  }

  &.width-31-5 {
    width: 31.5%;
  }

  &.width-48-5 {
    width: 48.5%;
  }

  & > img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.flex-txt {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq-down() {
    width: auto;
  }

  &.btn {
    & > p.p-link-r {
      align-self: flex-end;
      display: flex;
      margin-top: auto;
  
      @include mq-down() {
        margin-top: 2rem;
      }
    }
  }
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.fw-medium {
  font-weight: 500;
}

.height-8 {
  height: 8rem !important;
}

.img-center {
  display: block;
  height: auto;
  margin: 0 auto;
  width: auto;
}

.jc-center {
  justify-content: center;
}

.jc-flex-end {
  justify-content: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.lh-9 {
  line-height: 0.9 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-20 {
  line-height: 2 !important;
}

.m-auto {
  margin: auto;
}

.mh-230 {
  max-height: 230px;
}

.ml-auto {
  margin-left: auto;
}

.ml-flex {
  margin-left: 4rem;

  @include mq-down() {
    margin-top: 3.6rem;
    margin-left: 0;
  }
}

.mq-down-fd-column {
  @include mq-down() {
    flex-direction: column;
  }
}

.mq-down-mb-5 {
  @include mq-down() {
    margin-bottom: 0.5rem;
  }
}

.mq-down-prl-1-2 {
  @include mq-down() {
    padding: 1rem 0 2rem;
  }
}

.mw-860 {
  max-width: 860px
}

.mw-1000 {
  max-width: 1000px
}

.p-itsolution{
  &-dl{
    &-list{
        @include grid(0, flex-start);
        margin-bottom: 2rem;
      dt{
        width: 70px;
        text-align: left;
      }
      dd{
        width: calc(100% - 70px);
        line-height: 1.5;
      }
    }
    &-list2{
        @include grid(0, flex-start);
        margin: 0 auto;
        width: 88%;
        @include mq-down() {
          width: 100%;
        }
      dt{
        width: 110px;
        text-align: left;
        @include mq-down() {
          margin: auto;
          padding-bottom: 2rem;
        }
      }
      dd{
        width: calc(100% - 110px);
        @include mq-down() {
          width: 100%;
        }
      }
    }
  }
  &-wrap{
    width: 100%;
    max-width: 960px;
    background-color: $c_palegray;
    box-sizing: border-box;
    padding: 36px 93px;
    margin-top: 2rem;
  }
  &-cta{
    .button-default a{
      width: 400px;
      @include mq-down() {
        width: 100%;
      }
      height: 80px;
    }
    .button-default a::before {
      border-bottom: 80px solid $c_block;
    }
  }
}

.p-itsolutionFillTit {
  background-color: #03070A;
  color: #FFFFFF;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0.8rem 1.2rem;
}

.p-itsolutionFourColumn {
  display: flex;

  & > li {
    width: calc((100% - 3.5rem * 4) / 5);
    
    &:nth-child(n + 2) {
      margin-left: 3.5rem;
    }

    & > img {
      display: block;
      height: auto;
      margin: 0 auto;
      width: auto;
    }
  }

  @include mq-down() {
    flex-direction: column;

    & > li {
      width: auto;

      &:nth-child(n + 2) {
        margin-left: 0;
        margin-top: 3.5rem;
      }
    }
  }
}

.p-itsolutionMovie {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 860px;
  height: calc(840px * 0.5625);
  @include mq-down() {
    width: 90vw;
    height: calc(92vw * 0.5625);
    }

  & > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.p-itsolutionOverlay {
  &::before {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    content: '';
    left: 50%;
    max-width: 1920px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    @include mq-down() {
      left: inherit;
      right: 0;
      width: 100vw;
      z-index: 100;
      transform:inherit;
    }
  }
}

.p-itsolutionRightTriangle {
  &::after {
    background-color: $c_palegray;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    content: '';
    height: 12.8rem;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3.6rem;

    @include mq-down() {
      content: none;
    }
  }
}


.p-itsolutionTriangle {
  &::before {
    background-color: rgb(255, 255, 255);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    content: '';
    height: 4rem;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 12rem;

    @include mq-down() {
      height: 3.6rem;
      width: 10.8rem;
    }
  }
}

.p-relative {
  position: relative;
}

.td-underline {
  text-decoration: underline !important;
}

.width-40 {
  width: 40rem !important;

  @include mq-down() {
    width: 36rem !important;
  }
}


.p-campus{
  &-ico{
    text-align: center;
    padding-bottom: 20px;
  }
  &-list{
    &__item{
      display: block !important;
    }
    &__text{
      font-weight: bold;
      margin: 1rem 0 0 -2rem;
      color: $c_darkgreen;
    }
  }
}
.c-title-num__num.step{
  display: block;
}
.c-title-num__num div.ico_step{
  display: block;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding-top: 2rem;
  line-height: 1.4;
  @include mq-down() {
    padding-top:0rem;
  }
}
.c-title-num__num div.ico_num{
  display: block;
  width: 100%;
  text-align: center;
}

.p-dx{
  &-iconbox{
    &__item{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 5rem 2rem 4rem;
      box-sizing: border-box;
    }
  }
}

.p-social-solution {
  .c-cardbox {
    &__item {
      img {
        width: fit-content;
      }
      figure {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
        flex-grow: 1;
        background: $c_palegray;
        padding: 2rem 2rem 2.5rem;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 3rem solid $c_palegray;
          border-right: transparent solid 4rem;
          border-left: transparent solid 4rem;
          position: absolute;
          bottom: -3rem;
        }
      }
      figcaption {
        font-weight: bold;
      }
    }
  }
}