.pc-only {
  display:block;

  @include mq-down() {
    display: none;
  }
}
.pc-only2 {
  display: block;
  @include mq-down(s) {
    display: none;
  }
}
.sp-only {
	display:none;

  @include mq-down() {
    display: block;
  }
}
.sp-only2 {
  display: none;

  @include mq-down(s) {
    display: block;
  }
}