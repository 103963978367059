.top-mv {
  height: 80vh;
  @include mq-down() {
    height:60vh;
  }

  &_slider__items{
    position:relative;
    a {
      overflow: hidden;
    }
    &.active-slide .top-mv__img img{
      animation: fadezoom 5s forwards;
    }
  }

  &__summary {
    position: absolute;
    right: 15%;
    bottom: 4vw;
    background: rgba(255,255,255,0.8);
    padding: 35px;
    box-sizing: border-box;
    @include mq-down() {
      width:100%;
      min-width: unset;
      bottom:0;
      right:0;
      padding: 23px 5%;
    }

    h2 {
      font-size:4rem;
      font-weight:bold;
      text-align: center;
      line-height: 1.3em;
      margin-bottom: 20px;

      @include mq-down() {
        font-size:23px;
        margin-bottom: 12px;
      }
    }

    p {
      text-align: center;
      margin-bottom: 30px;
      line-height: 1.7em;
      font-weight: bold;

      @include mq-down() {
        font-size:12px;
        margin-bottom:12px;
      }
    }

    &.min-width {
      min-width:620px;
      @include mq-down() {
        min-width: unset;
        width:100%;
      }
    }
  }

  &__btn {
    width: 160px;
    background: #fff;
    border: 1px solid $c_block;
    padding: 7px 15px;
    font-size: 16px;
    text-align: center;
    display: block;
    margin: 0 auto;
    position: relative;
    &:after {
      content: "";
      background-image: url(../img/ico_arrow_blk.png);
      width: 6px;
      height: 10px;
      display: inline-block;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 14px;
    }

    @include mq-down() {
      padding: 3px 15px;
      width: 160px;
      font-size:14px;
    }
  }

  // slider
  .bx-wrapper {
    .bx-loading {
      background:none !important;
    }
    .bx-viewport {
      height:80vh !important;
    
      @include mq-down() {
        height:60vh !important;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 80vh;
  
      @include mq-down() {
        object-position: -57vw;
        height:60vh;
      }
    }
    .bx-pager {
      bottom: -80px;
    }
  }
}

