.l-table {
  width: 128rem;
  box-sizing: border-box;
  border-collapse: collapse;

  &.border-top {
    border-top: 1px solid $c_lightgray;
  }

  colgroup {
    .w700 {
      width: 70rem;
    }
    .w600 {
      width: 60rem;
    }
    .w500 {
      width: 50rem;
    }
    .w400 {
      width: 40rem;
    }
    .w300 {
      width: 30rem;
    }
    .w200 {
      width: 20rem;
    }
    .w150 {
      width: 15rem;
    }
    .w100 {
      width: 10rem;
    }
  }

  &.u-fixed {
    table-layout: fixed;
  }

  th {
    border-bottom: 1px solid $c_lightgray;
  }

  td {
    border-bottom: 1px solid $c_lightgray;
  }

  &__th-top {
    thead {
      background-color: $c_darkgray;

      th {
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
        padding: 1rem 2rem;

        &:not(:last-child),
        &.border-right {
          border-right: 1px solid #fff;
        }
        &.bg_palegray{
          background-color: $c_palegray;
          &.border-right {
            border-right: 1px solid $c_lightgray;
          }
          }
      }
      th {
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
        padding: 1rem 2rem;

        &:not(:last-child),
        &.border-right {
          border-right: 1px solid #fff;
        }
      }
      td {
        background-color: $c_palegray;
        text-align: left;
        vertical-align: top;
        font-weight: normal;
        padding: 1rem 2rem;
        &.border-right {
          border-right: 1px solid $c_lightgray;
        }
      }

      &.align-left th {
        text-align: left;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $c_lightgray;
      }
      th, td {
        padding: 2rem;
        vertical-align: middle;

        &.border-right {
          border-right: 1px solid $c_lightgray;
        }
      }
      th {
        text-align: left;
        font-weight: bold;
        background-color: $c_palegray;
      }

      &.u-table-bycolor {
        &01 {
          :is(th,td):nth-child(odd) {
            background-color: $c_palegray;

            &:not(:last-child) {
              border-right: 1px solid #fff;
            }
          }
          :is(th,td):nth-child(even) {
            background-color: #fff;
          }
        }
        &02 {
          :is(th,td):nth-child(even) {
            background-color: $c_palegray;
          }
          :is(th,td):nth-child(odd) {
            background-color: #fff;
          }
        }
        &03 {
          tr:nth-child(odd) {
            background-color: $c_palegray;
          }
          tr:nth-child(even) {
            background-color: #fff;
          }
          th {
            background-color: unset;
          }
        }
        &04 {
          tr:nth-child(even) {
            background-color: $c_palegray;
          }
          tr:nth-child(odd) {
            background-color: #fff;
          }
          th {
            background-color: unset;
          }
        }
      }
    }
  }

  &__th-side {
    // border-top: 1px solid $c_lightgray;

    // tr {
    //   border-bottom: 1px solid $c_lightgray;
    // }
    th, td {
      padding: 2rem;
      vertical-align: middle;
    }
    th {
      text-align: left;
      font-weight: bold;
      background-color: $c_palegray;

      &.border-right {
        border-right: 1px solid $c_lightgray;
      }
    }
    &.u-table-bycolor {
      &01 {
        :is(th,td):nth-child(odd) {
          background-color: $c_palegray;

        }
      }
      &02 {
        :is(th,td):nth-child(even) {
          background-color: $c_palegray;
        }
        :is(th,td):nth-child(odd) {
          background-color: #fff;
        }
      }
      &03 {
        tr:nth-child(odd) {
          background-color: $c_palegray;
        }
        tr:nth-child(even) {
          background-color: #fff;
        }
        th {
          background-color: unset;
        }
      }
      &04 {
        tr:nth-child(even) {
          background-color: $c_palegray;
        }
        tr:nth-child(odd) {
          background-color: #fff;
        }
        th {
          background-color: unset;
        }
      }
    }
  }

  &__th-top-side {

    thead {
      background-color: $c_darkgray;

      th {
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
        padding: 1rem 2rem;

        &:first-child {
          border-bottom: 1px solid $c_lightgray;
        }

        &:last-child {
          border-right: 1px solid $c_darkgray;
        }

        &:not(:last-child),
        &.border-right {
          border-right: 1px solid #fff;
        }
      }
    }
    tbody {
      th, td {
        padding: 2rem;
        vertical-align: middle;

        &.border-right {
          border-right: 1px solid $c_lightgray;
        }
      }
      th {
        text-align: left;
        font-weight: bold;
        background-color: $c_palegray;
      }
      &.u-table-bycolor {
        &01 {
          :is(th,td):nth-child(odd) {
            background-color: $c_palegray;

            &:not(:last-child) {
              border-right: 1px solid #fff;
            }
          }
          :is(th,td):nth-child(even) {
            background-color: #fff;
          }
        }
        &02 {
          :is(th,td):nth-child(even) {
            background-color: $c_palegray;
          }
          :is(th,td):nth-child(odd) {
            background-color: #fff;
          }
        }
        &03 {
          tr:nth-child(odd) {
            background-color: $c_palegray;
          }
          tr:nth-child(even) {
            background-color: #fff;
          }
          th {
            background-color: unset;
          }
        }
        &04 {
          tr:nth-child(even) {
            background-color: $c_palegray;
          }
          tr:nth-child(odd) {
            background-color: #fff;
          }
          th {
            background-color: unset;
          }
        }
      }
    }
  }

  &.no-scroll {
    max-width: 128rem;
    width: 100%;
  }
}

// .c-cardbox__item {
//   .l-table {
//     width: auto!important;
//   }
// }

.l-table-mini {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;

  thead {
    th {
      font-weight: bold;
      text-align: left;
      vertical-align: middle;
      background-color: $c_darkgray;
      height: 3rem;
      padding: 0 1.5rem;

      &:first-child {
        width: 3rem;
      }

      &:not(:last-child) {
        border-right: 1px solid $c_palegray2;
      }
    }
  }

  tbody {
    tr > * {
      height: 4.2rem;
      vertical-align: middle;
      border-bottom: 1px solid $c_lightgray;

      &:first-child {
        text-align: center;
        background-color: $c_palegray2;
      }
      &:not(:first-child) {
        padding: 0 1.5rem;
      }
    }
  }
}
.l-table-mini2 {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;

  thead {
    th {
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      background-color: $c_darkgray;
      padding: 0.5rem 1.5rem;

      &:not(:last-child) {
        border-right: 1px solid $c_palegray2;
      }
    }
  }

  tbody {
    tr > * {
      vertical-align: middle;
      border-bottom: 1px solid $c_lightgray;

    }
    th,td{
      padding: 2.5rem;
    }
    th{
      width: 30%;
      background-color: $c_lightgray;
      font-weight: bold;
      vertical-align: middle;
      border-bottom:1px solid #fff;
      &.bg_palegray{
        background-color: $c_palegray;
        text-align: left;
        border-bottom: 1px solid $c_lightgray;
      }
    }
    td{
      background-color: #FFF;
    }
  }
}

.l-table-scroll {
  overflow-x: auto;
}