section.block .inner {
	width: 1280px;
  margin: 0 auto;
  padding: 100px 0;
	position:relative;
  @include mq-down(l) {
    width:100%;
		padding: 100px 30px;
    box-sizing: border-box;
  }
  @include mq-down(m) {
    width:93%;
    margin: 0 auto;
    padding:70px 0;
  }

  &.bg-about {
    max-width: 1720px;
    width: 100%;
    display: block;

    @include mq-down() {
      margin-bottom: 0;
    }
  }
}
.holiday {
  margin-bottom:40px;
}

.block-topics_list {
  &__items {
    a {
      display:flex;
      align-items: center;
      border-top:1px solid #f2f2f2;
      padding: 15px 35px;

      @include mq-down() {
        display:block;
      }
    }
    &:last-of-type a {
      border-bottom:1px solid #f2f2f2;
    }
  }
  &__date {
    width: 7.5rem;
    margin-right: 20px;
    font-size:14px;

    @include mq-down() {
      display:inline-block;
      margin-right: 6px;
    }
  }
  &__tag {
    margin-right: 20px;
    background: $c_block;
    color: #fff;
    box-sizing: border-box;
    width: 150px;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-down() {
      display: inline-block;
      line-height: 1.9em;
      width: auto;
      font-size: 11px;
      padding: 0 16px;
      margin-top: 3px;
    }
  }
  &__cont {
    width: calc(100% - 26.5rem);
    display:flex;
    align-items: center;

    @include mq-down() {
      width: 100%;
    }
    p{
      font-size:16px;

      @include mq-down() {
        font-size:13px;
        line-height: 1.7;
      }
    }

    .block-topics_list__newIcon + p {
      width: calc(100% - 56px);
    }
  }
  &__newIcon {
    margin-right: 15px;
    padding: 0 5px;
    border: 1px solid $c_red;
    color: $c_red;
    text-align: center;
    width: 56px;
    font-size: 12px;
    line-height: 1.7;
  }

  &--arrow {
    a {
      position:relative;
      @include mq-down() {
        vertical-align: middle;
      }
      @include mq-down() {
        padding:15px 10px 15px 0;
      }

      &::after {
        position:absolute;
        background-image: url(../img/ico_arrow_blk.png);
        content: "";
        width: 6px;
        height: 10px;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
  }
}

.block-content {
  &__background{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    background-color: $c_palegray;
    top: 0;
    height: calc(50% + 90px);
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    @include mq-down(l) {
      margin: 0 auto;
      left: 0;
      width: 100%;
    }
    @include mq-down(s) {
      width: 100vw;
    }

    &.product {
      // width: 100%;
      height:220px;
      @include mq-down() {
        height: 149px;
      }
      @include mq-down(s) {
        width: 100vw;
        margin: 0 calc(-7% / 2);
      }
    }

    &.solution {
      // width: 100%;
      height:235px;
      @include mq-down() {
        height:157px;
      }
      @include mq-down(s) {
        width: 100vw;
        margin: 0 calc(-7% / 2);
      }
    }

    &.case {
      height:235px;
      @include mq-down() {
        height: 157px;
      }
    }
  }

  &__title {
    position:relative;
    text-align: center;
    line-height: 1.25;
    font-size:40px;
    font-weight:bold;
    margin-bottom: 60px;
    @include mq-down() {
      font-size:27px;
      margin-bottom:40px;
    }

    .en {
      color:#454545;
      font-size:16px;
      display: block;
      margin-top: 12px;
      font-weight:400;
      @include mq-down() {
        font-size: 13px;
        margin-top: 4px;
      }
    }
  }

  &__description {
    position:relative;
    text-align: center;

    @include mq-down() {
      font-size:13px;
      text-align:left;
    }
  }
}

.block-pickup_content {
	position: relative;
	display:flex;
	justify-content: space-between;
  @include mq-down() {
    flex-wrap:wrap;
  }

  &__items {
    width:620px;
    position:relative;
    box-shadow: 0 0 13px rgba(51,51,51,.07);
    box-sizing: border-box;
    transition: all 0.5s;
    @include mq-down() {
      width:100%;
      margin-bottom: 30px;
    }

    img {
      @include mq-down() {
        width: 100%;
      }
    }

    &:hover{
      box-shadow: 0 0 35px rgba(51,51,51,.21);
      transition: all 0.5s;
      opacity: 1;

      a,
      img {
        opacity: 1;
      }
    }

    &--read {
      position:absolute;
      width: 234px;
      @include mq-down() {
        width:100%;
        position:static;
        padding: 20px 20px;
        box-sizing: border-box;
      }

      h3 {
        font-weight:bold;
        font-size:22px;
        margin-bottom: 10px;

        @include mq-down() {
          font-size:16px;
          letter-spacing: 0.05em;
          line-height: 1em;
        }
      }

      p {
        font-size:16px;

        @include mq-down() {
          font-size:13px;
          line-height:1.7em;
          letter-spacing: 0.05em;
        }
      }

      &.first {
        top: 35px;
        left: 42px;
        h3,
        p{
          color:#fff;

          @include mq-down() {
            color: $c_block;
          }
        }
      }
      &.second {
        top: 35px;
        right: 42px;
        @include mq-down() {
          background: rgba(255,255,255,0.3);
        }
      }
    }
    &:last-of-type {
      @include mq-down() {
        margin-bottom:0;
      }
    }
  }
}

.block-product_content {
  position:relative;
  margin-top: 100px;
  @include mq-down() {
    margin-top: 50px;
  }

  ul {
    display:flex;
    // justify-content: space-between;
    flex-wrap:wrap;
    gap: calc(8% / 3);

    @include mq-down() {
      gap: 2%;
    }
  }
  &__items {
    width:23%;
    margin-bottom: 35px;
    @include mq-down() {
      width:49%;
      margin-bottom: 10px;
    }

    a {
      padding: 25px;
      box-shadow:0 0 13px rgba(51,51,51,.07);
      box-sizing: border-box;
      display: block;
      transition: all 0.5s;
      @include mq-down() {
        padding: 18px;
      }

      &:hover{
        box-shadow:0 0 35px rgba(51,51,51,.21);
        transition: all 0.5s;
        opacity: 1;
        img{
          opacity:1;
        }
      }
    }
    &--img {
      margin-bottom:20px;
      height: 180px;
      @include mq-down() {
        margin-bottom: 23px;
      }
      @include mq-down(s) {
        height: 90px;
      }

      img {
        display:block;
        margin:0 auto;
        @include mq-down() {
          width: 100%;
        }
      }
    }
    h3 {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      @include mq-down() {
        font-size:14px;
      }
    }
  }
}

.block-solution_content {
  position:relative;
  margin-top: 115px;
  @include mq-down() {
    margin-top: 60px;
  }

  ul{
    display:flex;
    justify-content: space-between;
    flex-wrap:wrap;
  }

  &__items{
    width:calc((100% - 80px) / 3);
    margin-bottom: 35px;
    @include mq-down() {
      width:49%;
      margin-bottom: 10px;
    }

    a{
      padding: 0;
      box-shadow:0 0 13px rgba(51,51,51,.07);
      box-sizing: border-box;
      display: block;
      transition: all 0.5s;
      &:hover{
        box-shadow:0 0 35px rgba(51,51,51,.21);
        transition: all 0.5s;
        opacity: 1;
        img{
          opacity:1;
        }
      }
    }

    h3{
      text-align: center;
      font-weight: bold;
      padding:13px 0;
      font-size:20px;
      @include mq-down() {
        font-size:14px;
        padding: 9px 0;
      }
    }

    &--img {
      height: 150px;
      @include mq-down() {
        height: 65px;
      }

      img {
        display:block;
        margin:0 auto;
        height: 100%;
        object-fit: cover;
        @include mq-down() {
          width: 100%;
        }
      }
    }

    &:last-of-type,
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
}

.block-case {
  position:relative;
  margin: 0 auto;
  padding: 100px 0;
  @include mq-down() {
    padding: 70px 0;
  }

  &_content {
    position:relative;
    margin-top: 115px;
    @include mq-down() {
      margin-top: 100px;
    }

    &_slider{
      margin: 0 calc(50% - 50vw);
      width: 100vw;

      .slick-slide{
        margin: 0 10px;
        background:$c_palegray;
        padding: 0;
        box-shadow:0 0 13px rgba(51,51,51,.07);
        box-sizing: border-box;
        display: block;
        transition: all 0.5s;

        &:hover {
          box-shadow:0 0 35px rgba(51,51,51,.21);
          transition: all 0.5s;
          opacity: 1;

          a,
          img {
            opacity:1;
          }
        }
      }

      .slick-arrow {
        position: absolute;
        z-index: 50;
        top: 50%;
        transform:translateY(-50%);
        width: 48px;
        height: 48px;
        background-size: cover;
        @include mq-down() {
          top:-35px;
          width: 40px;
          height: 40px;
        }
      }

      .slick-button-next{
        background-image: url(../img/button-block-case-next.png);
        right: 14%;
        @include mq-down() {
          right:6%;
        }
      }

      .slick-button-prev {
        background-image: url(../img/button-block-case-prev.png);
        left: 14%;
        @include mq-down() {
          left:auto;
          right:18%;
        }
      }
    }

    &__items {
      position:relative;

      h3{
        text-align: center;
        font-weight: bold;
        font-size:18px;
        margin-bottom: 16px;
        @include mq-down() {
          margin-bottom:9px;
          // font-size:18px;
        }

        span{
          font-size: 1rem;
          padding-left: 5px;
        }
      }

      &__newIcon {
        margin-right: 15px;
        padding: 0 5px;
        border: 1px solid $c_red;
        color: $c_red;
        text-align: center;
        width: 56px;
        font-size: 12px;
        line-height: 1.7;
        position:absolute;
        top:0;
        left:0;
        background:#fff;
      }

      &--img {
        height: 230px;
        overflow: hidden;
        img {
          display:block;
          margin:0 auto;
          width: 100%;
          height: 100%;
          object-fit:cover;
        }
      }

      &--desctiption {
        padding:20px;
        margin-bottom:30px;
        line-height: 1.7em;
        @include mq-down() {
          font-size:13px;
          margin-bottom: 16px;
        }
      }

      &--tags {
        ul{
          display:flex;
          flex-wrap:wrap;
          justify-content: flex-end;

          li{
            margin-right:3px;
            margin-bottom:3px;
            color:$c_darkgray;
            border:1px solid $c_darkgray;
            background:#fff;
            padding: 2px 3px 3px;
            font-size: 12px;
            line-height: 1em;
            @include mq-down() {
              font-size:11px;
            }

            &.gray{
              border:1px solid $c_darkgray;
              background:$c_darkgray;
              color:#fff;
            }
          }
        }
      }
    }
  }
}