.c-list {
  &-ol {
    list-style: decimal-leading-zero inside;

    li {
      padding-left: 2.5rem;
      padding-top: 2px;
      padding-bottom: 2px;
      text-indent: -3rem;

      &::marker {
        color: $c_darkgreen;
        font-weight: bold;
      }
    }
  }

  &-ul {
    list-style: disc inside;
    li {
      padding-left: 3.5rem;
      padding-top: 2px;
      padding-bottom: 2px;
      text-indent: -2.5rem;

      &::marker {
        color: $c_darkgreen;
      }

      > ul {
        list-style: disc inside;
        li {
          padding-left: 3.5rem;
          text-indent: -2.5rem;
          &::marker {
            color: $c_darkgray;
          }
        }
      }
    }

    &__wide {
      list-style: disc inside;
      li {
        padding-left: 3.5rem;
        text-indent: -2.5rem;
        margin-bottom: 2.8rem;
  
        &::marker {
          color: $c_green;
        }
  
        > ul {
          list-style: disc inside;
          margin-top: 2rem;
          li {
            padding-left: 3.5rem;
            text-indent: -2.5rem;
            &::marker {
              color: $c_darkgray;
            }
          }
        }
      }
    }
  }

  &-annotation {
    li {
      font-size: 12px;
      line-height: 1.67;
      position: relative;
      padding-left: 2rem;

      &::before {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &-label {
    &--item {
      height: 11.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      &.item01 {
        background-color: $c_green;
      }
      &.item02 {
        background-color: $c_darkgreen;
      }
      &.item03 {
        background-color: $c_deepgreen;
      }

      p {
        color: #fff;
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1.5;
      }
    }
  }

  &-labelbox {
    &--item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 11.5rem;
      gap: 2rem;
      margin-bottom: 3rem;

      &.item01 {
        background-color: $c_green;
      }
      &.item02 {
        background-color: $c_darkgreen;
      }
      &.item03 {
        background-color: $c_deepgreen;
      }

      p {
        color: #fff;
        font-size: 2.5rem;
        font-weight: bold;
      }

      &__title {
        color: $c_darkgreen;
        font-weight: bold;
        margin-bottom: 3rem;
      }
    }
  }
}

.c-vertical-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__item {
    padding: 3rem;
    display: flex;
    align-items: center;
    width: 100%;
    height: 10rem;
    box-sizing: border-box;

    &.green {
      background-color: $c_green;
    }
    &.darkgreen {
      background-color: $c_darkgreen;
    }
    &.deepgreen {
      background-color: $c_deepgreen;
    }
    &.middlegreen {
      background-color: #006934;
    }

    p {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
    }
  }
}

.c-flow-list {
  column-count: 2;
  gap: 4.5rem 4rem;

  @include mq-down() {
    column-count: 1;
  }

  &--item {
    display: flex;
    align-items: center;
    gap: 4rem;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 4.5rem;
    @include mq-down() {
      gap: 2rem;
    }
  }

  &--line {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 4.5rem;
      background: $c_palegray;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
}