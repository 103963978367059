.c-pagenavi {
  max-width: 128rem;
  width: 93%;
  margin: 12rem auto;
  padding: 4rem 5rem;
  border: 1px solid $c_lightgray;
  box-sizing: border-box;

  @include mq-down() {
    padding: 3rem;
  }

  * {
    box-sizing: border-box;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    border-bottom: 1px solid $c_lightgray;
    padding-bottom: 3rem;
    @include mq-down() {
      flex-direction: column;
    }

    li {
      width: 25%;
      padding-right: 1.5rem;

      @include mq-down() {
        width: 100%;
      }

      &.c-pagenavi__auto {
        width: auto;
      }
    }
  }

  p {
    text-align: right;
    margin-top: 2rem;
    padding-right: 2rem;
  }
}

.inner {
  .c-pagenavi {
    width: 100%;
  }
}