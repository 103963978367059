.p-event {
  &__close {
    border: 1px solid $c_red;
    text-align: center;
    color: $c_red;
    padding: 3rem;
    font-weight: bold;
    @include font30;
  }
}
