.c-breadcrumb {
  margin-bottom: 9rem;

  @include mq-down() {
    margin-bottom: 5rem;
  }

  #columnDetailPage & {
    margin-bottom: 0;

    &__inner {
      // max-width: 123rem;
      max-width: 128rem;
    }
  }

  &__inner {
    max-width: 128rem;
    width: 93%;
    margin: 1rem auto auto;
    // overflow-x: scroll;
    // -ms-overflow-style: none;
    // scrollbar-width: none;

    &::-webkit-scrollbar {
      display:none;
    }
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0 2rem;
    // width: max-content;
    margin: 0 0 0 auto;
    li {
      position: relative;
      font-size: 14px;

      &:not(:first-of-type) {
        &::before {
          content: "";
          display: block;
          width: 4px;
          height: 8px;
          background: url("../../assets/img/ico_arrow_gray.png");
          position: absolute;
          top: 1rem;
          left: -1rem;
        }
      }
    }
  }
}
