.u-datalist {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $c_lightgray;

  dt,dd {
    padding: 3rem 4rem;
    border-bottom: 1px solid $c_lightgray;
    box-sizing: border-box;

    @include mq-down() {
      padding: 2rem;
    }
  }
  dt {
    background: $c_palegray;
    font-weight: bold;
    display: flex;
    align-items: center;

    &.u-accent {
      color: $c_darkgreen;
    }
  }
  dd {
    &.u-accent {
      font-weight: bold;
      background: $c_light_green;
    }
  }
}