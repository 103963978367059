.c-share {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    li {
      &:first-child > iframe {
        width: 92px;
      }
    }
  }
}