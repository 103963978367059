.button-wrap {
	margin-top:40px;

  a {
    margin: 0 auto;
  }

  &__right {
    a {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__left {
    a {
      margin-right: auto;
      margin-left: 0;
    }
  }

  &__col2 {
    display: flex;
    gap: 4rem;

    &--center {
      display: flex;
      justify-content: center;
      gap: 4rem;
    }
  }

  &__vertical {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}

.button-default {
  a {
    width:280px;
    background: #fff;
    border: 1px solid $c_block;
    color: $c_block;
    padding-left:30px;
    font-size: 16px;
    display: block;
    position: relative;
    font-weight: bold;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
      padding-left: 1.5rem;
    }

    &::before {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      right: -60px;
      z-index: -1;
      border-right: 60px solid transparent;
      border-bottom: 60px solid $c_block;
      transform: translateX(-100%);
      transition: transform ease .3s;
    }
    &::after {
      content: "";
      width: 6px;
      height: 10px;
      display: inline-block;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 14px;
      background-image: url(../img/ico_arrow_blk.png);
    }
    &:hover {
      color: #fff;
      &::before {
        transform: translateX(0);
      }
      &::after {
        content:none;
        transition-property: all;
        transition-duration: 0.5s;
        transition-delay: 1s;
        transition-property: content;
        transition: transform ease .3s;
      }
    }
  }

  &.u-long {
    a {
      width: 57.5rem;

      @include mq-down() {
        width: 100%;
      }
    }
  }

  &.u-centering a {
    justify-content: center;
    padding-left: 0;
  }
}

.button-external {
  a {
    width:280px;
    background: #fff;
    border: 1px solid $c_block;
    color: $c_block;
    padding-left:30px;
    font-size: 16px;
    display: block;
    position: relative;
    font-weight: bold;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::before {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      right: -60px;
      z-index: -1;
      border-right: 60px solid transparent;
      border-bottom: 60px solid $c_block;
      transform: translateX(-100%);
      transition: transform ease .3s;
    }
    &::after {
      content: "";
      width: 13px;
      height: 13px;
      display: inline-block;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 14px;
      background-image: url(../img/ico_link_normal.png);
    }
    &:hover {
      color: #fff;
      &::before {
        transform: translateX(0);
      }
      &::after {
        content:none;
        transition-property: all;
        transition-duration: 0.5s;
        transition-delay: 1s;
        transition-property: content;
        transition: transform ease .3s;
      }
    }
  }

  &.u-centering a {
    justify-content: center;
    padding-left: 0;
  }
}

.button-contact {
  a {
    width:280px;
    height: 50px;
    background: $c_green;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::before {
      content: '';
      display: block;
      width: 17px;
      height: 11px;
      background-image: url(../img/ico_floating_02.png);
    }
  }

  &__big {
    a {
      background:$c_green;
      color:#fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      text-align-last: left;
      width: 100%;
      max-width: 40rem;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
}

.button-quotation {
  a {
    width:280px;
    height: 50px;
    background: $c_green;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::before {
      content: '';
      display: block;
      width: 13px;
      height: 18px;
      background-image: url(../img/ico_floating_03.png);
    }
  }

  &__big {
    a {
      background:$c_green;
      color:#fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      text-align-last: left;
      width: 100%;
      max-width: 40rem;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
}

.button-download {
  a {
    width:280px;
    height: 50px;
    background: $c_darkgreen;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(../img/ico_floating_01.png);
    }
  }

  &__type02 {
    a {
      width:280px;
      height: 50px;
      background: $c_darkgreen;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      z-index: 1;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      @include mq-down() {
        width: 100%;
        height: 43px;
        font-size: 14px;
      }
  
      &::before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(../img/ico_cva03.png);
        background-size: 100% auto;
      }
    }
  }

  &__big {
    a {
      background:$c_darkgreen;
      color:#fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      text-align-last: left;
      width: 100%;
      max-width: 40rem;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }
}

.button-inactive {
  a {
    width:280px;
    background: $c_lightgray;
    color: $c_block;
    padding-left:30px;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    pointer-events: none;

    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::after {
      content: "";
      width: 6px;
      height: 10px;
      display: inline-block;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 14px;
      background-image: url(../img/ico_arrow_blk.png);
    }
  }

  &.u-centering a {
    justify-content: center;
    padding-left: 0;
  }
}

.button-product {
  a {
    width:280px;
    background: #fff;
    color: $c_block;
    padding-left:30px;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include mq-down() {
      width: 100%;
      height: 43px;
      font-size: 14px;
    }

    &::before {
      content: '';
      display: block;
      width: 21px;
      height: 18px;
      background-image: url(../img/ico_product.png);
    }
  }

  &.u-centering a {
    justify-content: center;
    padding-left: 0;
  }
}