.c-box {
  &__item {
    &--wide {
      width: 100%;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      display: flex;

      @include mq-down() {
        flex-direction: column;
      }

      figure {
        width: 49%;
        background: $c_palegray2;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq-down() {
          width: 100%;
        }
      }

      &__info {
        width: 51%;
        padding: 4rem;
        box-sizing: border-box;

        @include mq-down() {
          width: 100%;
          padding: 2rem;
        }

        p {
          margin: 2rem 0;
        }
      }
      .c-textlink__download {
        text-align: right;
        display: block;
        margin-top: 3rem;
      }
    }

    &--name {
      font-size: 2rem;
      font-weight: bold;
      color: $c_darkgreen;
      margin-bottom: 1rem;

      @include mq-down(l) {
        @include font20;
      }
    }

    &--time {
      font-size: 12px;
      color: $c_lightgray;
      padding-left: 2rem;
      margin-bottom: 2rem;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url("../../assets/img/ico_update.png");
        position: absolute;
        left: 0;
        bottom: 5px;
      }
    }

    &--product {
      padding: 2rem 3rem;
      border: 1px solid $c_lightgray;

      @include mq-down() {
        padding: 2rem;
      }

      &.u-flex {
        display: flex;
        gap: 2%;

        .c-list-ul {
          width: 49%;
        }
      }
    }
    &--half {
      width: calc((100% - 4rem) / 2);
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

      @include mq-down() {
        width: calc((100% - 3rem) / 2);
      }

      &__info {
        padding: 4rem;

        @include mq-down(l) {
          padding: 2rem;
        }
      }

      .c-textlink__download {
        text-align: right;
        display: block;
        margin-top: 3rem;
      }
    }

    &--article {
      width: 100%;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      border: 1px solid #ccc;
      box-sizing: border-box;
      display: flex;

      @include mq-down() {
        flex-direction: column;
      }

      figure {
        width: 16rem;
        background: $c_palegray2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 1;

        @include mq-down() {
          width: 100%;
        }

        img {
          object-fit: cover;
          width: 16rem;
          height: 16.2rem;
        }
      }

      &__info {
        width: calc(100% - 16rem);
        padding: 2rem;
        box-sizing: border-box;

        @include mq-down() {
          width: 100%;
          padding: 2rem;
        }

        p {
          margin: 2rem 0;
        }
      }
      .c-textlink__download {
        text-align: right;
        display: block;
        margin-top: 3rem;
      }
    }
  }
}

.c-cardbox {
  &.c-cardbox-img {
    .c-cardbox__item {
      a {
        display: flex;
        flex-direction: row;
      }

      &--img {
        width: 13rem;
        height: 100%;

        @include mq-down() {
          width: 10rem;
        }
      }

      &--text {
        padding: 2rem;
        padding-right: 4rem!important;

        @include mq-down() {
          padding-right: 3rem!important;
        }
      }
    }
  }
  &.u-flex__col1 {
    .c-list-ul,
    .c-list-ol {
      &.u-col02 {
        column-count: 2;
        column-gap: 1rem;

        @include mq-down() {
          column-count: 1;
        }
      }
    }

    a {
      display: flex;

      @include mq-down() {
        flex-direction: column;
      }
    }

    figure {
      width: 62rem;

      @include mq-down(l) {
        width: 50%;
      }

      @include mq-down() {
        width: 100%;
      }

      // img {
      //   height: 100%;
      //   object-fit: cover;
      // }
    }

    .c-cardbox__item--text {
      width: calc(100% - 62rem);

      @include mq-down(l) {
        width: 50%;
      }

      @include mq-down() {
        width: 100%;
      }

      *:has(+ .align-right) {
        flex-grow: 1;
      }
    }
  }

  &.u-flex__col2 {
    .c-list-ul,
    .c-list-ol {
      column-count: 2;
      column-gap: 1rem;
      @include mq-down() {
        column-count: 1;
      }

      &.u-col01 {
        column-count: 1;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;

    &.u-text-only {
      .c-link {
        &__within {
          .c-cardbox__item--text {
            position: relative;
            padding-right: 6rem;

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 2rem;
              width: 15px;
              height: 15px;
              transform: translateY(calc(-50% + 3px));
              background: url(../../assets/img/ico_arrow_green.png) no-repeat;

              @include mq-down() {
                right: 1rem;
              }
            }
          }
        }
        &__external {
          .c-cardbox__item--text {
            position: relative;
            padding-right: 6rem;
  
            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 2rem;
              width: 19px;
              height: 19px;
              transform: translateY(calc(-50% + 3px));
              background: url(../../assets/img/ico_link_green.png) no-repeat;
            }

          }
        }
      }
      .c-cardbox__item--text {
        justify-content: center;
        gap: 15px;
        .c-tag__white--red {
          position: static;
        }
      }
      .c-cardbox__item--title {
        color: $c_block;
        margin-bottom: 0;
      }
    }

    a {
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      width: 100%;
      // height: 100%;
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    &--text {
      padding: 4rem 3rem;
      text-align: left;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      background-color: #fff;

      @include mq-down() {
        padding: 3rem 2rem;
      }

      p:not([class]) {
        flex-grow: 1;
        margin-bottom: 2rem;
      }

      .c-list-ul,
      .c-list-ol {
        // margin-bottom: 6rem;
        flex-grow: 1;

        li {
          figure {
            margin-bottom: 6.5rem;
          }
        }
      }
    }

    &--title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 2rem;

      @include mq-down() {
        font-size: 16px;
      }
    }

    &--list {
      margin-bottom: 4.5rem;
      flex-grow: 1;

      .c-list-ul,
      .c-list-ol {
        border: 1px solid $c_lightgray;
        padding: 2rem;
        margin-bottom: 0;
      }
    }
  }

  &.no-link {
    .c-cardbox__item--title {
      text-align: center;
    }
    .c-cardbox__item--text {
      padding: 3rem 0 0;
    }
  }

  &.is-numbering {
    .c-cardbox__item {
      position: relative;

      &::before {
        content: attr(data-num);
        color: #fff;
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -2.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 5rem;
        height: 5rem;
        background-color: $c_darkgreen;
        border-radius: 50%;
      }
    }
    &.u-text-only {
      .c-cardbox__item--text {
        background-color: $c_palegray;
      }
    }

  }
}

.c-cardbox-simple {
  a {
    display: flex;
    box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

    @include mq-down() {
      flex-direction: column;
    }

    figure {
      width: 40rem;

      @include mq-down() {
        width: 100%;
        text-align: center;
      }

      img {
        width: 100%;
      }
    }
  }
  &__text {
    padding: 4rem;
    width: calc(100% - 40rem);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include mq-down() {
      width: 100%;
      padding: 3rem;
    }

    &--title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 3rem;
    }

    &--link {
      text-align: right;
    }

    p:not([class]) {
      flex-grow: 1;
    }
  }
}

.c-imgbox {
  &__col1 {
    width: 93%;
    max-width: 128rem;
    margin: auto;

    a {
      display: flex;
      box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

      @include mq-down() {
        flex-direction: column;
      }
    }

    figure {
      width: 62rem;
      display: flex;
      align-items: center;

      @include mq-down(l) {
        width: 48%;
      }

      @include mq-down() {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    &--text {
      width: calc(100% - 62rem);
      padding: 4rem;
      box-sizing: border-box;

      @include mq-down(l) {
        width: 52%;
      }

      @include mq-down() {
        width: 100%;
      }
    }

    &--title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 3rem;
    }

    &--list {
      margin-bottom: 4.5rem;
      padding: 2rem 3rem;
      border: 1px solid $c_lightgray;
    }
  }

  &__col3bg {
    &--white {
      .c-imgbox__col3bg__text {
        background: #fff;
      }
    }

    &--gray {
      .c-imgbox__col3bg__text {
        background: $c_palegray;
      }
    }

    &__item {
      width: calc((100% - 8rem) / 3);
      transition: all 0.5s;
      display: flex;
      flex-direction: column;

      @include mq-down() {
        width: 100%;
      }

      &:hover {
        box-shadow: 0 0 35px rgba(51, 51, 51, 0.21);
        opacity: 1;
        transition: all 0.5s;
      }

      a {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      figure {
        img {
          width: 100%;
        }
      }
    }

    &__text {
      padding: 3rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      @include mq-down() {
        padding: 2rem;
      }

      p:not([class*="title"]), ul:not([class*="tag"]) {
        flex-grow: 1;
      }
    }

    &__title {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.75;
      margin-bottom: 3rem;
    }

    &__tag {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 5px;
      margin-top: 6rem;

      li {
        padding: 5px;
        font-size: 14px;
        line-height: 1;
      }

      .u-bg {
        &__darkgray {
          color: #fff;
        }
        &__white {
          color: $c_darkgray;
          background: #fff;
          border: 1px solid $c_darkgray;
        }
      }
    }
  }

  &__simple {

    &__shadow {
      .c-imgbox__simple--item {
        box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);
      }
    }

    &--item {
      a {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.5em;
        width: 100%;
      }

      figure {
        margin-bottom: 3rem;
        img {
          width: 100%;
        }
      }
    }

    &--title {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &__class {

    figure {
      margin-bottom: 3rem;

      img {
        width: 100%;
      }
    }

    &--title {
      font-size: 2rem;
      font-weight: bold;
      text-align: left;
      color: $c_darkgreen;
      margin-bottom: 2rem;
    }

    .c-list-ul,
    .c-list-ol {
      width: 100%;
      text-align: left;

      li {
        padding-left: 2rem;
        text-indent: -2rem;
      }
    }
  }
}

.c-iconbox {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    box-shadow: 0 0 13px rgba(51, 51, 51, 0.07);

    a {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 5rem 2rem 4rem;
      box-sizing: border-box;
    }

    &--title {
      font-weight: bold;
      font-size: 2rem;
      margin-top: 2rem;

      @include mq-down(l) {
        @include font20;
      }
    }
  }
}

.c-linebox {
  background: $c_palegray;
  border-top: 3px solid $c_green;
  padding: 3rem;

  a {
    margin: -3rem;
    padding: 3rem;
    box-shadow: none;
    justify-content: start;
  }

  &-img {
    border-top: 3px solid $c_green;
  }
}

.c-content-box {
  background: $c_palegray;
  padding: 4rem;

  ul {
    column-count: 4;

    &.u-col01 {
      column-count: 1;
    }
  }
}

.c-label-box {
  &__item {
    &--label {
      color: #fff;
      font-weight: bold;
      font-size: 2rem;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--content {
      padding-top: 3rem;
      text-align: left;
    }
  }
}

.c-detail-box {
  &__item {
    &--text {
      padding: 3rem;
      background: $c_palegray;
      display: flex;
      flex-direction: column;
    }

    &--title {
      font-size: 2rem;
      font-weight: bold;
      color: $c_darkgreen;
      margin-bottom: 3rem;

      span {
        font-size: 1.6rem;
        font-weight: normal;
        color: $c_block;
      }
    }

    &--content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .title {
        font-size: 1.8rem;
        font-weight: bold;
      }
      p:not([class]), > ul {
        margin-bottom: 1rem;
      }
      figure {
        margin: 1.5rem 0;
      }
    }
  }
}

.c-stepbox {
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @include mq-down() {
    flex-direction: column;
  }

  &__item {
    width: calc((100% - 4rem) / 2);
    padding: 4rem;
    background: $c_palegray;
    box-sizing: border-box;

    @include mq-down() {
      width: 100%;
    }

    &:first-child {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 36px solid $c_green;
        border-top: 64px solid transparent;
        border-bottom: 64px solid transparent;
        position: absolute;
        top: 50%;
        right: -36px;
        transform: translatey(-50%);
    
        @include mq-down() {
          border-top: 36px solid $c_green;
          border-left: 64px solid transparent;
          border-right: 64px solid transparent;
          top: auto;
          bottom: -102px;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.c-headerbox {
  &__item {
    &--header {
      // background: $c_green;
      background-color: $c_darkgray;
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
      }
    }

    &--body {
      padding: 4rem;
      background-color: $c_palegray;
    }

    &--title {
      color: $c_darkgreen;
      font-size: 2rem;
      font-weight: bold;
      margin: 3rem 0;
    }
  }
}

.u-flex__col3.c-cardbox.is-numbering > li:nth-child(n+4) {
  margin-top: 5rem;
}

.c-arrow-box {
  &__top {
    background: $c_palegray;
    padding: 2rem;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3rem 4rem 0 4rem;
      border-color: $c_palegray transparent transparent;
      position: absolute;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__bottom {
    padding-top: 5.5rem;
    text-align: left;
  }
}